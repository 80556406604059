import { IonIcon } from "@ionic/react";
import React from "react";
import { caretUp, caretDown, checkmark } from "ionicons/icons";

import { GearboxTemplate } from "../../state";

import styles from "./BoxTemplateSelector.module.scss";

export interface BoxTemplateSelectorProps {
  templates: GearboxTemplate[];
  defaultTemplateId: number | null;
  handleChangeTemplate: (template_id: number | null) => void;
}

const BoxTemplateSelector = ({
  templates,
  defaultTemplateId,
  handleChangeTemplate,
}: BoxTemplateSelectorProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const defaultOption =
    defaultTemplateId !== null ? `${defaultTemplateId}` : "";

  const [selectedOption, setSelectedOption] = React.useState(defaultOption);

  const selectedTemplate = templates?.find(
    (template: GearboxTemplate) => `${template.id}` === defaultOption,
  );

  const defaultText = selectedTemplate
    ? selectedTemplate.title
    : "テンプレートから選ぶ";

  const [selectedTitle, setSelectedTitle] = React.useState(defaultText);

  return (
    <div className={styles.selector}>
      <div
        className={`${styles.labelArea} ${isOpen && styles.isOpen}`}
        onClick={() => setIsOpen(!isOpen)}
        tabIndex={0}
      >
        <span className={styles.label}>{selectedTitle}</span>
        <IonIcon
          icon={isOpen ? caretUp : caretDown}
          className={styles.arrowIcon}
        />
      </div>
      {isOpen && (
        <ul className={styles.menu} tabIndex={0}>
          {templates.map(template => (
            <li
              key={template.id}
              className={styles.options}
              onClick={() => {
                // 現在選択されているテンプレートを再度選んだら選択解除する
                if (`${template.id}` === selectedOption) {
                  handleChangeTemplate(null);
                  setSelectedOption("");
                  setSelectedTitle("テンプレートから選ぶ");
                } else {
                  handleChangeTemplate(template.id);
                  setSelectedOption(`${template.id}`);
                  setSelectedTitle(template.title);
                }
                setIsOpen(false);
              }}
            >
              <span className={styles.optionLabel}>{template.title}</span>
              <p className={styles.optionDesc}>{template.description}</p>
              {`${template.id}` === selectedOption && (
                <IonIcon src={checkmark} className={styles.checkIcon} />
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default BoxTemplateSelector;
