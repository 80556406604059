import React from "react";
import clsx from "clsx";
import {
  IonContent,
  IonIcon,
  IonLabel,
  IonItem,
  IonItemGroup,
  IonAvatar,
  IonText,
} from "@ionic/react";
import {
  personCircle as personIcon,
  chevronForwardOutline as rightArrowIcon,
} from "ionicons/icons";
import { useHistory } from "react-router-dom";

import { ITeacher } from "../../../state";
import {
  editProfilePath,
  MAIL_PASSWORD_LIST_SUCCESS_MESSAGE,
} from "../../../store/teacher";
import ModalConfirm from "../../../molecules/ModalConfirm";
import Toast from "../../../molecules/Toast";

import styles from "./MypageMenu.module.scss";

export interface MypageMenuProps {
  calling: boolean;
  teacher: ITeacher;
  success_message: string;
  mailPasswordList: (class_id: number) => void;
}
const MypageMenu = (props: MypageMenuProps) => {
  const history = useHistory();
  const [state, setState] = React.useState({
    showConfirm: false,
    showToast: false,
    targetClassID: 0,
  });

  React.useEffect(() => {
    if (props.success_message === MAIL_PASSWORD_LIST_SUCCESS_MESSAGE) {
      setState(s => ({ ...s, showToast: true }));
    }
  }, [props.success_message]);

  return (
    <IonContent className={styles.wrapper}>
      <ModalConfirm
        loading={props.calling}
        isOpen={state.showConfirm}
        message={
          <>
            {props.teacher.email}宛に
            <br />
            Eメールで送信されます。
            <p style={{ color: "#eee", marginTop: "15px" }}>
              Eメールが届かない場合は、Gear for SのEメールアドレスが
              <br />
              許可リストに追加されていることを確認してください
            </p>
          </>
        }
        buttons={[
          {
            title: "キャンセル",
            type: "None",
            action: () =>
              setState({ ...state, showConfirm: false, targetClassID: 0 }),
          },
          {
            title: "取得する",
            type: "Success",
            action: () => {
              props.mailPasswordList(state.targetClassID);
              setState({ ...state, showConfirm: false });
            },
          },
        ]}
      />
      <Toast
        showToast={state.showToast}
        onClose={() => setState({ ...state, showToast: false })}
        message="メール送信依頼を実行しました"
      />
      <IonItemGroup>
        <IonItem
          lines="none"
          onClick={() => history.push(editProfilePath)}
          className={clsx(styles.field, styles.pointer)}
        >
          {props.teacher.photo?.url ? (
            <IonAvatar slot="start" color="light">
              <img alt="プロフィール画像" src={props.teacher.photo.url} />
            </IonAvatar>
          ) : (
            <IonIcon
              className={styles.avatar}
              icon={personIcon}
              slot="start"
              color="light"
            />
          )}
          <IonText color="light">
            <div
              className={styles.name}
            >{`${props.teacher.last_name} ${props.teacher.first_name}`}</div>
            <div className={styles.supplement}>
              プロフィール写真・パスワード変更
            </div>
          </IonText>
          <IonIcon
            color="light"
            icon={rightArrowIcon}
            slot="end"
            className={styles.arrowIcon}
          />
        </IonItem>
        <IonItem className={styles.field} lines="none">
          <div className={styles.explication}>
            担当クラスのID・パスワードリストを取得する
          </div>
        </IonItem>
        {props.teacher.classes.map(cls => (
          <IonItem
            lines="none"
            key={cls.id}
            className={clsx(styles.field, styles.pointer)}
            onClick={() => {
              setState({
                ...state,
                showConfirm: true,
                targetClassID: cls.id,
              });
            }}
          >
            <IonLabel className={styles.classField} color="light">
              {cls.full_name}
            </IonLabel>
            <IonIcon
              icon={rightArrowIcon}
              slot="end"
              className={styles.arrowIcon}
            />
          </IonItem>
        ))}
      </IonItemGroup>
    </IonContent>
  );
};

export default MypageMenu;
