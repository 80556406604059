import React from "react";
import { IonAvatar, IonCol, IonIcon, IonLabel, IonRow } from "@ionic/react";
import {
  checkmarkCircleOutline,
  personCircle,
  checkmarkCircle,
} from "ionicons/icons";
import clsx from "clsx";

import {
  IComment,
  IFeedback,
  IOnClickStudentAvatarParams,
  IRealization,
} from "../../../state";
import ImageDetailModal from "../../../molecules/ImageDetailModal";
import Toast from "../../../molecules/Toast";
import CommentFeedbackTabs from "../../../organisms/CommentFeedbackTabs";
import RealizationContent from "../../../molecules/RealizationContent";

import styles from "./DashboardSharedRealization.module.scss";

export interface SharedRealizationProps {
  calling: boolean;
  realization: IRealization;
  comments: IComment[];
  feedbacks: IFeedback[];
  current_id: number;
  hasTab?: boolean;
  createComment: (realization_id: number, content: string) => void;
  updateComment: (comment_id: number, content: string) => void;
  deleteComment: (comment_id: number) => void;
  createFeedback: (realization_id: number, content: string) => void;
  updateFeedback: (feedback_id: number, content: string) => void;
  deleteFeedback: (feedback_id: number) => void;
  onAvatarClick?: (params: IOnClickStudentAvatarParams) => void;
  onChecked: (id: number) => void;
  onUnchecked: (id: number) => void;
}

const DashboardSharedRealization = (props: SharedRealizationProps) => {
  const [values, updateValues] = React.useState({
    content: "",
    showToast: false,
    showImageDetail: false,
    editTargetID: 0,
  });

  const handleClickAvatar = React.useCallback(
    e => {
      if (!props.onAvatarClick || !props.realization.student) return;
      e.stopPropagation();

      const { id, photo, full_name, klass_id, klass_name } =
        props.realization.student;
      props.onAvatarClick({
        student_id: id,
        name: full_name,
        photo,
        class_id: klass_id,
        class_name: klass_name ?? "",
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onAvatarClick, props.realization.student],
  );

  const toggleCheckedFunc = React.useCallback(() => {
    if (props.calling) return;
    if (props.realization.is_checked) {
      props.onUnchecked(props.realization.id);
    } else {
      props.onChecked(props.realization.id);
    }
  }, [props]);

  return (
    <div className={styles.realizationContainer}>
      <Toast
        type="danger"
        showToast={values.showToast}
        onClose={() => updateValues({ ...values, showToast: false })}
        message="コメントが入力されていません。"
      />
      {props.realization.image && (
        <ImageDetailModal
          show={values.showImageDetail}
          imageUrl={props.realization.image.url}
          imageName={props.realization.image.name}
          onClose={() => updateValues(s => ({ ...s, showImageDetail: false }))}
        />
      )}
      <div className={styles.realizationItem}>
        <div
          className={clsx(
            styles.avatarArea,
            !!props.realization.student &&
              !!props.onAvatarClick &&
              styles.clicableAvatar,
          )}
          onClick={handleClickAvatar}
        >
          {props.realization.student?.photo?.url ? (
            <IonAvatar slot="start" color="light" className={styles.avatar}>
              <img
                alt="プロフィール画像"
                src={props.realization.student.photo.url}
              />
            </IonAvatar>
          ) : (
            <IonIcon
              className={styles.avatar}
              icon={personCircle}
              slot="start"
              color="light"
            />
          )}
        </div>
        <IonRow className={styles.row}>
          <IonCol size="12">
            <IonLabel color="light">
              <div className={styles.realizationHeader}>
                <span className={styles.name}>
                  {props.realization.student?.full_name ?? ""}
                </span>
                {props.realization.belong_to_current_teacher && (
                  <button
                    type="button"
                    className={styles.buttonCheck}
                    onClick={toggleCheckedFunc}
                  >
                    <IonIcon
                      icon={
                        props.realization.is_checked
                          ? checkmarkCircle
                          : checkmarkCircleOutline
                      }
                      className={clsx(
                        styles.checkIcon,
                        props.realization.is_checked && styles.checked,
                      )}
                    />
                    <span>
                      {props.realization.is_checked
                        ? "先生確認済"
                        : "確認済にする"}
                    </span>
                  </button>
                )}
              </div>
            </IonLabel>
          </IonCol>
          <IonCol size="12" className={styles.contentWrapper}>
            <RealizationContent
              realization={props.realization}
              commentCount={props.comments.length}
              isDetail
              color={"light"}
              onClickThumbnailImage={() => {
                updateValues(s => ({ ...s, showImageDetail: true }));
              }}
            />
          </IonCol>
        </IonRow>
      </div>
      <CommentFeedbackTabs
        calling={props.calling}
        current_teacher_id={props.current_id}
        realization={props.realization}
        comments={props.comments}
        feedbacks={props.feedbacks}
        createFeedback={props.createFeedback}
        updateFeedback={props.updateFeedback}
        deleteFeedback={props.deleteFeedback}
        createComment={props.createComment}
        updateComment={props.updateComment}
        deleteComment={props.deleteComment}
        onAvatarClick={props.onAvatarClick}
        isTeacher={true}
      />
    </div>
  );
};

export default DashboardSharedRealization;
