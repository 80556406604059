import "./App.css";
import React from "react";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { Redirect, Route, Switch } from "react-router-dom";
import { IonReactRouter } from "@ionic/react-router";
import { datadogRum } from "@datadog/browser-rum";
import { setupIonicReact } from "@ionic/react";
import "react-datepicker/dist/react-datepicker.css";

import "./App.css";
import cookie, { cookieNames } from "./libs/cookie";
import { useReducerWithEnhancedDispatch } from "./libs/enhancedDispatch";
import { diff } from "./libs/Util";
import {
  reducer,
  Context,
  fetchInitialData,
  InitialAppState,
  AppState,
  Actions,
  updateTeacherPasswordPath,
  homePath,
  mypagePath,
  manageBoxesPath,
  manageBoxDirectoriesPath,
  boxesPath,
  prefixPath,
  editProfilePath,
  notificationListPath,
  submittedRealizationListPath,
  sharedRealizationListPath,
  reportListPath,
  analyzePath,
  sharedRealizationPath,
  resetTeacherPasswordPath,
  sendEmailCompletePath,
  authenticationPath,
  updateStudentDetailModal,
  LOGOUT_SUCCESS_MESSAGE,
  gearthemesListPath,
} from "./store/teacher";
import SideMenu from "./molecules/teacher/SideMenu";
import Tab from "./organisms/teacher/Tab";
import TemplateModal from "./organisms/TemplateModal";
import Error503Page from "./templates/Error503Page";
import Login from "./pages/teacher/Login";
import MultiFactorAuthentication from "./pages/teacher/MultiFactorAuthentication";
import UpdateTeacherPassword from "./pages/teacher/UpdateTeacherPassword";
import EditProfile from "./pages/teacher/EditProfile";
import SharedRealizationListPage from "./pages/teacher/SharedRealizationListPage";
import Reports from "./pages/teacher/Reports";
import Notifications from "./pages/teacher/Notifications";
import { SharedRealizationPage } from "./pages/teacher/SharedRealizationPage";
import Mypage from "./pages/teacher/Mypage";
import ManageBoxesPage from "./pages/teacher/ManageBoxesPage";
import ManageBoxDirectoriesPage from "./pages/teacher/ManageBoxDirectoriesPage";
import BoxDashboardPage from "./pages/teacher/BoxDashboardPage";
import BoxDetailPage from "./pages/teacher/BoxDetailPage";
import AnalyzePage from "./pages/teacher/AnalyzePage";
import ResetPassword from "./pages/teacher/ResetPassword";
import SendEmailCompletePage from "./pages/teacher/SendEmailCompletePage";
import StudentDetailPage from "./pages/teacher/StudentDetailPage";
import TimelineThemePage from "./pages/teacher/TimelineTheme";

setupIonicReact({
  mode: "ios",
});

const reducerForApp =
  process.env.NODE_ENV === "production"
    ? reducer
    : (contextState: AppState, action: Actions) => {
        console.groupCollapsed(action.types);
        console.log("action", action);
        const result = reducer(contextState, action);
        console.log("diff", diff(contextState, result));
        console.log("prevState", contextState);
        console.log("currentState", result);
        console.groupEnd();
        return result;
      };

const SESSION_KEY = "gear_teacher_session_id";

function TeacherApp() {
  if (window.location.href.match(/session_id=/)) {
    const result = window.location.href.split("session_id=").pop() as string;
    const sessionID = result.replace(/&.*$/, "");
    sessionStorage.setItem(SESSION_KEY, sessionID);
  }
  const savedSessionId =
    sessionStorage.getItem(SESSION_KEY) ||
    cookie.getCookie(cookieNames.teacher_session_id) ||
    "";
  const [contextState, dispatch] = useReducerWithEnhancedDispatch({
    reducer: reducerForApp,
    initialAppState: {
      ...InitialAppState,
      session_id: savedSessionId,
    },
  });

  React.useEffect(() => {
    if (contextState.teacher.id) {
      datadogRum.setUser({
        id: contextState.teacher.id,
        type: "teacher",
        school_id: contextState.teacher.school_id,
      });
    }
  }, [contextState.teacher.id, contextState.teacher.school_id]);

  React.useEffect(() => {
    if (contextState.session_id) {
      if (window.location.href.match(/session_id=/)) {
        window.history.pushState(null, "", window.location.pathname);
      }
      dispatch(fetchInitialData());
    }
  }, [contextState.session_id, dispatch]);

  React.useEffect(() => {
    if (
      savedSessionId &&
      !sessionStorage.getItem(SESSION_KEY) &&
      !cookie.getCookie(cookieNames.teacher_session_id) &&
      !contextState.session_id
    ) {
      window.location.reload();
    } else if (!savedSessionId && contextState.session_id) {
      cookie.set(cookieNames.teacher_session_id, contextState.session_id, 365);
    } else if (savedSessionId && !contextState.session_id) {
      sessionStorage.removeItem(SESSION_KEY);
      cookie.deleteAll();
    }
  }, [savedSessionId, contextState.session_id]);

  React.useEffect(() => {
    if (
      contextState.success_message === LOGOUT_SUCCESS_MESSAGE ||
      contextState.expires < new Date()
    ) {
      window.location.reload();
    }
  }, [contextState]);

  const clearStudentDetail = React.useCallback(
    () => dispatch(updateStudentDetailModal(null)),
    [dispatch],
  );

  return contextState.isServiceUnavailable ? (
    <Error503Page />
  ) : (
    <IonApp>
      <Context.Provider value={{ contextState, dispatch }}>
        {contextState.session_id ? (
          <>
            <IonReactRouter basename={prefixPath}>
              <SideMenu />
              <Tab>
                <IonRouterOutlet mode="ios" id="sideMenuContainer">
                  <Route
                    exact
                    path={submittedRealizationListPath}
                    component={BoxDashboardPage}
                  />
                  <Route
                    path={`${sharedRealizationPath}:id`}
                    component={SharedRealizationPage}
                  />
                  <Route
                    exact
                    path={sharedRealizationListPath}
                    component={SharedRealizationListPage}
                  />
                  <Route exact path={reportListPath} component={Reports} />
                  <Route exact path={analyzePath} component={AnalyzePage} />
                  <Route exact path={editProfilePath} component={EditProfile} />
                  <Route exact path={mypagePath} component={Mypage} />
                  <Route
                    exact
                    path={manageBoxesPath}
                    component={ManageBoxesPage}
                  />
                  <Route
                    exact
                    path={manageBoxDirectoriesPath}
                    component={ManageBoxDirectoriesPage}
                  />
                  <Route
                    exact
                    path={`${boxesPath}/:id`}
                    component={BoxDetailPage}
                  />
                  <Route
                    exact
                    path={`${sharedRealizationListPath}${boxesPath}/:id`}
                    component={BoxDetailPage}
                  />
                  <Route
                    exact
                    path={notificationListPath}
                    component={Notifications}
                  />
                  <Route
                    exact
                    path={`${gearthemesListPath}/:id`}
                    component={TimelineThemePage}
                  />
                  <Redirect
                    exact
                    path={homePath}
                    to={submittedRealizationListPath}
                  />
                  <Redirect
                    exact
                    path={`${authenticationPath}/:ott`}
                    to={submittedRealizationListPath}
                  />
                </IonRouterOutlet>
              </Tab>
            </IonReactRouter>
          </>
        ) : (
          <IonReactRouter basename={prefixPath}>
            <Switch>
              <Route
                path={`${updateTeacherPasswordPath}/:uuid`}
                component={UpdateTeacherPassword}
              />
              <Route
                path={`${resetTeacherPasswordPath}`}
                component={ResetPassword}
              />
              <Route
                path={`${sendEmailCompletePath}`}
                component={SendEmailCompletePage}
              />
              <Route
                path={`${authenticationPath}/:ott`}
                component={MultiFactorAuthentication}
              />
              <Route exact path={homePath} component={Login} />
              <Redirect to={homePath} />
            </Switch>
          </IonReactRouter>
        )}
        <TemplateModal
          isOpen={contextState.student_detail_modal}
          onClose={clearStudentDetail}
          headerTitle="個人詳細"
          theme="light"
          headerColorVariant="analyze"
        >
          <StudentDetailPage />
        </TemplateModal>
      </Context.Provider>
    </IonApp>
  );
}

export default TeacherApp;
