import React from "react";
import {
  IonContent,
  IonPage,
  useIonViewWillLeave,
  useIonViewWillEnter,
} from "@ionic/react";
import { useParams } from "react-router-dom";

import {
  clearSuccessMessage,
  Context,
  CREATE_COMMENT_SUCCESS_MESSAGE,
  UPDATE_COMMENT_SUCCESS_MESSAGE,
  DELETE_COMMENT_SUCCESS_MESSAGE,
  fetchComments,
  createComment,
  updateComment,
  deleteComment,
  clearComments,
  clearFeedbacks,
  updateStudentDetailModal,
  fetchGearthemeRealization,
  clearGearthemePagination,
  clearRealizations,
} from "../../../store/teacher";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import ThemeHeader from "../../../molecules/ThemeHeader";
import NavHeader from "../../../organisms/NavHeader";
import TemplateModal from "../../../organisms/TemplateModal";
import TimelineTheme from "../../../templates/teacher/TimelineTheme";
import SharedRealization from "../../../templates/teacher/SharedRealization";
import styles from "../common.module.scss";

const TimelineThemePage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const params = useParams<{ id: string }>();
  const [values, updateValues] = React.useState({
    isDisplaying: false,
    realizationDetailId: 0,
  });
  const update = (args: { [key: string]: any }) => {
    updateValues(prevValues => ({ ...prevValues, ...args }));
  };

  const loadRealizations = React.useCallback(() => {
    dispatch(peep(clearSuccessMessage()));
    const list = contextState.realizations;
    dispatch(
      peep(
        fetchGearthemeRealization(Number(params.id), list[list.length - 1].id),
      ),
    );
  }, [dispatch, peep, contextState.realizations, params.id]);

  useIonViewWillEnter(() => {
    update({
      isDisplaying: true,
    });
    dispatch(peep(fetchGearthemeRealization(Number(params.id), null)));
  }, []);

  useIonViewWillLeave(() => {
    update({
      isDisplaying: false,
    });
    dispatch(updateStudentDetailModal(null));
    clearRealizationFunc();
    dispatch(clearGearthemePagination());
    dispatch(clearRealizations());
  });

  const realizationDetail = React.useMemo(
    () =>
      contextState.realizations.find(
        realization => realization.id === values.realizationDetailId,
      ),
    [contextState.realizations, values.realizationDetailId],
  );

  React.useEffect(() => {
    if (
      contextState.success_message === CREATE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === UPDATE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === DELETE_COMMENT_SUCCESS_MESSAGE
    ) {
      if (values.realizationDetailId !== 0) {
        dispatch(clearSuccessMessage());
        dispatch(fetchComments(values.realizationDetailId));
      }
    }
  }, [dispatch, contextState.success_message, values.realizationDetailId]);

  const createCommentFunc = React.useCallback(
    (realization_id: number, content: string) => {
      dispatch(peep(createComment(realization_id, content)));
    },
    [dispatch, peep],
  );

  const updateCommentFunc = React.useCallback(
    (comment_id: number, content: string) => {
      dispatch(peep(updateComment(comment_id, content)));
    },
    [dispatch, peep],
  );

  const deleteCommentFunc = React.useCallback(
    (comment_id: number) => {
      dispatch(peep(deleteComment(comment_id)));
    },
    [dispatch, peep],
  );

  const clearRealizationFunc = React.useCallback(() => {
    update({ realizationDetailId: 0 });
    dispatch(clearComments());
    dispatch(clearFeedbacks());
  }, [dispatch]);

  const showStudentDetailFunc = React.useCallback(
    args => {
      dispatch(updateStudentDetailModal(args));
    },
    [dispatch],
  );

  return (
    <IonPage>
      <NavHeader title="みんなの回答" goBack={() => window.history.back()} />
      {realizationDetail && (
        <TemplateModal
          isOpen={!!values.realizationDetailId}
          onClose={clearRealizationFunc}
          headerTitle="詳細"
          theme="light"
        >
          <SharedRealization
            calling={calling}
            realization={realizationDetail}
            comments={contextState.comments}
            current_id={contextState.teacher.id}
            hideReactions
            createComment={createCommentFunc}
            updateComment={updateCommentFunc}
            deleteComment={deleteCommentFunc}
            onAvatarClick={showStudentDetailFunc}
            hasTab
          />
        </TemplateModal>
      )}
      <IonContent className={`${styles.wrapper} ${styles.bgWhileBase}`}>
        {contextState.teacher.classes.length > 0 && (
          <div className={styles.container}>
            {contextState.geartheme && (
              <ThemeHeader
                geartheme={contextState.geartheme}
                hideList
                hideCreate
                is_teacher
              />
            )}
            <TimelineTheme
              isDisplaying={values.isDisplaying}
              calling={calling}
              realizations={contextState.realizations}
              geartheme_pagination={contextState.geartheme_pagination}
              fetchRealization={loadRealizations}
              onAction={realizationDetailId => update({ realizationDetailId })}
              showStudentDetail={showStudentDetailFunc}
            />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default TimelineThemePage;
