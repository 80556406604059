import * as React from "react";
import { addHours, format } from "date-fns";

import {
  IFile,
  IRealization,
  ITeacher,
  IRealizationKlass,
  INotification,
  IPagyInfo,
  ICSVData,
  IStudentAnalyze,
  IRealizationAnalyze,
  ICategory,
  IStudentForGearbox,
  UpdateGearboxParams,
  CreateGearboxParams,
  ILoginResponse,
  ILoginMFAResponse,
  IStudentDetailModal,
  IMyBox,
  ITimelinePagination,
  IGearTheme,
  CreateTeacherGearboxDirectoryParams,
  UpdateTeacherGearboxDirectoryParams,
  TeacherGearboxDirectory,
  GearboxTemplate,
  CreateGearboxTemplateParams,
  UpdateGearboxTemplateParams,
} from "../state";
import { EOrderBy } from "../constants/boxDashboard";
import { IBoxesDashboardParams } from "../templates/teacher/BoxDashboard";

import {
  IComment,
  IFeedback,
  IReportEntry,
  ISummaryPerClassStudents,
  ITeacherGearbox,
  IClass,
  IBoxDashboard,
} from "./../state.d";

export const CLEAR_SUCCESS_MESSAGE = "CLEAR_SUCCESS_MESSAGE";
export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";
export const CLEAR_REALIZATIONS = "CLEAR_REALIZATIONS";
export const CLEAR_SELECTED_GEARBOX = "CLEAR_SELECTED_GEARBOX";
export const CLEAR_READ_REALIZATIONS = "CLEAR_READ_REALIZATIONS";
export const CLEAR_ANALYZE_REALIZATIONS = "CLEAR_ANALYZE_REALIZATIONS";
export const CLEAR_ANALYZE_STUDENT = "CLEAR_ANALYZE_STUDENT";
export const CLEAR_REALIZATION_KLASSES = "CLEAR_REALIZATION_KLASSES";
export const CLEAR_PAGY_INFO = "CLEAR_PAGY_INFO";
export const CLEAR_REALIZATIONS_ANALYZE_PAGY_INFO =
  "CLEAR_REALIZATIONS_ANALYZE_PAGY_INFO";
export const CLEAR_OTT = "CLEAR_OTT";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const VERIFY_MFA = "VERIFY_MFA";
export const VERIFY_MFA_SUCCESS = "VERIFY_MFA_SUCCESS";
export const VERIFY_MFA_FAILURE = "VERIFY_MFA_FAILURE";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const FETCH_INITIAL_DATA = "FETCH_INITIAL_DATA";
export const FETCH_INITIAL_DATA_SUCCESS = "FETCH_INITIAL_DATA_SUCCESS";
export const FETCH_INITIAL_DATA_FAILURE = "FETCH_INITIAL_DATA_FAILURE";
export const UPDATE_TEACHER_PASSWORD = "UPDATE_TEACHER_PASSWORD";
export const UPDATE_TEACHER_PASSWORD_SUCCESS =
  "UPDATE_TEACHER_PASSWORD_SUCCESS";
export const UPDATE_TEACHER_PASSWORD_FAILURE =
  "UPDATE_TEACHER_PASSWORD_FAILURE";
export const RESET_TEACHER_PASSWORD = "RESET_TEACHER_PASSWORD";
export const RESET_TEACHER_PASSWORD_SUCCESS = "RESET_TEACHER_PASSWORD_SUCCESS";
export const RESET_TEACHER_PASSWORD_FAILURE = "RESET_TEACHER_PASSWORD_FAILURE";
export const UPDATE_TEACHER = "UPDATE_TEACHER";
export const UPDATE_TEACHER_SUCCESS = "UPDATE_TEACHER_SUCCESS";
export const UPDATE_TEACHER_FAILURE = "UPDATE_TEACHER_FAILURE";

export const FETCH_SUBMITTED_REALIZATIONS = "FETCH_SUBMITTED_REALIZATIONS";
export const FETCH_SUBMITTED_REALIZATIONS_SUCCESS =
  "FETCH_SUBMITTED_REALIZATIONS_SUCCESS";
export const FETCH_SUBMITTED_REALIZATIONS_FAILURE =
  "FETCH_SUBMITTED_REALIZATIONS_FAILURE";
export const FETCH_READ_REALIZATIONS = "FETCH_READ_REALIZATIONS";
export const FETCH_READ_REALIZATIONS_SUCCESS =
  "FETCH_READ_REALIZATIONS_SUCCESS";
export const FETCH_READ_REALIZATIONS_FAILURE =
  "FETCH_READ_REALIZATIONS_FAILURE";
export const FETCH_REALIZATION_KLASSES = "FETCH_REALIZATION_KLASSES";
export const FETCH_REALIZATION_KLASSES_SUCCESS =
  "FETCH_REALIZATION_KLASSES_SUCCESS";
export const FETCH_REALIZATION_KLASSES_FAILURE =
  "FETCH_REALIZATION_KLASSES_FAILURE";
export const FETCH_REALIZATION = "FETCH_REALIZATION";
export const FETCH_REALIZATION_SUCCESS = "FETCH_REALIZATION_SUCCESS";
export const FETCH_REALIZATION_FAILURE = "FETCH_REALIZATION_FAILURE";
export const BULK_CHECK_REALIZATIONS = "BULK_CHECK_REALIZATIONS";
export const BULK_CHECK_REALIZATIONS_SUCCESS =
  "BULK_CHECK_REALIZATIONS_SUCCESS";
export const BULK_CHECK_REALIZATIONS_FAILURE =
  "BULK_CHECK_REALIZATIONS_FAILURE";
export const CHECK_REALIZATION_KLASS = "CHECK_REALIZATION_KLASS";
export const CHECK_REALIZATION_KLASS_SUCCESS =
  "CHECK_REALIZATION_KLASS_SUCCESS";
export const CHECK_REALIZATION_KLASS_FAILURE =
  "CHECK_REALIZATION_KLASS_FAILURE";
export const UPDATE_READ_LAST_NOTIFICATION = "UPDATE_READ_LAST_NOTIFICATION";
export const UPDATE_READ_LAST_NOTIFICATION_SUCCESS =
  "UPDATE_READ_LAST_NOTIFICATION_SUCCESS";
export const UPDATE_READ_LAST_NOTIFICATION_FAILURE =
  "UPDATE_READ_LAST_NOTIFICATION_FAILURE";
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";
export const CREATE_COMMENT = "CREATE_COMMENT";
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS";
export const CREATE_COMMENT_FAILURE = "CREATE_COMMENT_FAILURE";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
export const UPDATE_COMMENT_FAILURE = "UPDATE_COMMENT_FAILURE";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE";
export const FETCH_COMMENTS = "FETCH_COMMENTS";
export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";
export const FETCH_COMMENTS_FAILURE = "FETCH_COMMENTS_FAILURE";
export const FETCH_STATUS_RENEWALED = "FETCH_STATUS_RENEWALED";
export const FETCH_STATUS_RENEWALED_SUCCESS = "FETCH_STATUS_RENEWALED_SUCCESS";
export const FETCH_STATUS_RENEWALED_FAILURE = "FETCH_STATUS_RENEWALED_FAILURE";
export const CREATE_FEEDBACK = "CREATE_FEEDBACK";
export const CREATE_FEEDBACK_SUCCESS = "CREATE_FEEDBACK_SUCCESS";
export const CREATE_FEEDBACK_FAILURE = "CREATE_FEEDBACK_FAILURE";
export const UPDATE_FEEDBACK = "UPDATE_FEEDBACK";
export const UPDATE_FEEDBACK_SUCCESS = "UPDATE_FEEDBACK_SUCCESS";
export const UPDATE_FEEDBACK_FAILURE = "UPDATE_FEEDBACK_FAILURE";
export const DELETE_FEEDBACK = "DELETE_FEEDBACK";
export const DELETE_FEEDBACK_SUCCESS = "DELETE_FEEDBACK_SUCCESS";
export const DELETE_FEEDBACK_FAILURE = "DELETE_FEEDBACK_FAILURE";
export const MAIL_PASSWORD_LIST = "MAIL_PASSWORD_LIST";
export const MAIL_PASSWORD_LIST_SUCCESS = "MAIL_PASSWORD_LIST_SUCCESS";
export const MAIL_PASSWORD_LIST_FAILURE = "MAIL_PASSWORD_LIST_FAILURE";
export const FETCH_FEEDBACKS = "FETCH_FEEDBACKS";
export const FETCH_FEEDBACKS_SUCCESS = "FETCH_FEEDBACKS_SUCCESS";
export const FETCH_FEEDBACKS_FAILURE = "FETCH_FEEDBACKS_FAILURE";
export const FETCH_REPORTS = "FETCH_REPORTS";
export const FETCH_REPORTS_SUCCESS = "FETCH_REPORTS_SUCCESS";
export const FETCH_REPORTS_FAILURE = "FETCH_REPORTS_FAILURE";
export const FETCH_SUMMARY_PER_CLASS_STUDENTS =
  "FETCH_SUMMARY_PER_CLASS_STUDENTS";
export const FETCH_SUMMARY_PER_CLASS_STUDENTS_SUCCESS =
  "FETCH_SUMMARY_PER_CLASS_STUDENTS_SUCCESS";
export const FETCH_SUMMARY_PER_CLASS_STUDENTS_FAILURE =
  "FETCH_SUMMARY_PER_CLASS_STUDENTS_FAILURE";
export const UPDATE_STUDENT_DETAIL_MODAL = "UPDATE_STUDENT_DETAIL_MODAL";
export const FETCH_REALIZATIONS_CSV_DATA = "FETCH_REALIZATIONS_CSV_DATA";
export const FETCH_REALIZATIONS_CSV_DATA_SUCCESS =
  "FETCH_REALIZATIONS_CSV_DATA_SUCCESS";
export const FETCH_REALIZATIONS_CSV_DATA_FAILURE =
  "FETCH_REALIZATIONS_CSV_DATA_FAILURE";
export const FETCH_GEARBOXES = "FETCH_GEARBOXES";
export const FETCH_GEARBOXES_SUCCESS = "FETCH_GEARBOXES_SUCCESS";
export const FETCH_TEACHER_GEARBOX_DIRECTORIES =
  "FETCH_TEACHER_GEARBOX_DIRECTORIES";
export const FETCH_TEACHER_GEARBOX_DIRECTORIES_SUCCESS =
  "FETCH_TEACHER_GEARBOX_DIRECTORIES_SUCCESS";
export const FETCH_GEARBOXES_BY_STUDENT = "FETCH_GEARBOXES_BY_STUDENT";
export const FETCH_GEARBOXES_BY_STUDENT_SUCCESS =
  "FETCH_GEARBOXES_BY_STUDENT_SUCCESS";
export const CREATE_GEARBOX = "CREATE_GEARBOX";
export const CREATE_GEARBOX_SUCCESS = "CREATE_GEARBOX_SUCCESS";
export const CREATE_GEARBOX_FAILURE = "CREATE_GEARBOX_FAILURE";
export const CREATE_TEACHER_GEARBOX_DIRECTORY =
  "CREATE_TEACHER_GEARBOX_DIRECTORY";
export const CREATE_TEACHER_GEARBOX_DIRECTORY_SUCCESS =
  "CREATE_TEACHER_GEARBOX_DIRECTORY_SUCCESS";
export const CREATE_TEACHER_GEARBOX_DIRECTORY_FAILURE =
  "CREATE_TEACHER_GEARBOX_DIRECTORY_FAILURE";
export const UPDATE_GEARBOX = "UPDATE_GEARBOX";
export const UPDATE_GEARBOX_SUCCESS = "UPDATE_GEARBOX_SUCCESS";
export const UPDATE_GEARBOX_FAILURE = "UPDATE_GEARBOX_FAILURE";
export const UPDATE_TEACHER_GEARBOX_DIRECTORY =
  "UPDATE_TEACHER_GEARBOX_DIRECTORY";
export const UPDATE_TEACHER_GEARBOX_DIRECTORY_SUCCESS =
  "UPDATE_TEACHER_GEARBOX_DIRECTORY_SUCCESS";
export const UPDATE_TEACHER_GEARBOX_DIRECTORY_FAILURE =
  "UPDATE_TEACHER_GEARBOX_DIRECTORY_FAILURE";
export const DELETE_TEACHER_GEARBOX_DIRECTORY =
  "DELETE_TEACHER_GEARBOX_DIRECTORY";
export const DELETE_TEACHER_GEARBOX_DIRECTORY_SUCCESS =
  "DELETE_TEACHER_GEARBOX_DIRECTORY_SUCCESS";
export const DELETE_TEACHER_GEARBOX_DIRECTORY_FAILURE =
  "DELETE_TEACHER_GEARBOX_DIRECTORY_FAILURE";
export const DELETE_GEARBOX = "DELETE_GEARBOX";
export const DELETE_GEARBOX_SUCCESS = "DELETE_GEARBOX_SUCCESS";
export const DELETE_GEARBOX_FAILURE = "DELETE_GEARBOX_FAILURE";
export const FETCH_SHARED_GEARBOX_TEMPLATES = "FETCH_SHARED_GEARBOX_TEMPLATES";
export const FETCH_SHARED_GEARBOX_TEMPLATES_SUCCESS =
  "FETCH_SHARED_GEARBOX_TEMPLATES_SUCCESS";
export const FETCH_MY_GEARBOX_TEMPLATES = "FETCH_MY_GEARBOX_TEMPLATES";
export const FETCH_MY_GEARBOX_TEMPLATES_SUCCESS =
  "FETCH_MY_GEARBOX_TEMPLATES_SUCCESS";
export const CREATE_GEARBOX_TEMPLATE = "CREATE_GEARBOX_TEMPLATE";
export const CREATE_GEARBOX_TEMPLATE_SUCCESS =
  "CREATE_GEARBOX_TEMPLATE_SUCCESS";
export const CREATE_GEARBOX_TEMPLATE_FAILURE =
  "CREATE_GEARBOX_TEMPLATE_FAILURE";
export const UPDATE_GEARBOX_TEMPLATE = "UPDATE_GEARBOX_TEMPLATE";
export const UPDATE_GEARBOX_TEMPLATE_SUCCESS =
  "UPDATE_GEARBOX_TEMPLATE_SUCCESS";
export const UPDATE_GEARBOX_TEMPLATE_FAILURE =
  "UPDATE_GEARBOX_TEMPLATE_FAILURE";
export const REORDER_GEARBOX_TEMPLATES = "REORDER_GEARBOX_TEMPLATES";
export const REORDER_GEARBOX_TEMPLATES_SUCCESS =
  "REORDER_GEARBOX_TEMPLATES_SUCCESS";
export const REORDER_GEARBOX_TEMPLATES_FAILURE =
  "REORDER_GEARBOX_TEMPLATES_FAILURE";
export const DELETE_GEARBOX_TEMPLATE = "DELETE_GEARBOX_TEMPLATE";
export const DELETE_GEARBOX_TEMPLATE_SUCCESS =
  "DELETE_GEARBOX_TEMPLATE_SUCCESS";
export const DELETE_GEARBOX_TEMPLATE_FAILURE =
  "DELETE_GEARBOX_TEMPLATE_FAILURE";
export const FETCH_GEARBOX_STUDENTS = "FETCH_GEARBOX_STUDENTS";
export const FETCH_GEARBOX_STUDENTS_SUCCESS = "FETCH_GEARBOX_STUDENTS_SUCCESS";
export const FETCH_GEARBOX_REALIZATIONS = "FETCH_GEARBOX_REALIZATIONS";
export const FETCH_GEARBOX_REALIZATIONS_SUCCESS =
  "FETCH_GEARBOX_REALIZATIONS_SUCCESS";
export const GEARBOX_HIDDEN = "GEARBOX_HIDDEN";
export const GEARBOX_HIDDEN_SUCCESS = "GEARBOX_HIDDEN_SUCCESS";
export const GEARBOX_HIDDEN_FAILURE = "GEARBOX_HIDDEN_FAILURE";
export const FETCH_MY_BOX_SUMMARY = "FETCH_MY_BOX_SUMMARY";
export const FETCH_MY_BOX_SUMMARY_SUCCESS = "FETCH_MY_BOX_SUMMARY_SUCCESS";
export const FETCH_MY_BOX_REALIZATIONS = "FETCH_MY_BOX_REALIZATIONS";
export const FETCH_MY_BOX_REALIZATIONS_SUCCESS =
  "FETCH_MY_BOX_REALIZATIONS_SUCCESS";
export const FETCH_CLASSES_INCLUDE_PAST = "FETCH_CLASSES_INCLUDE_PAST";
export const FETCH_CLASSES_INCLUDE_PAST_SUCCESS =
  "FETCH_CLASSES_INCLUDE_PAST_SUCCESS";
export const FETCH_BOXES_DASHBOARD = "FETCH_BOXES_DASHBOARD";
export const FETCH_BOXES_DASHBOARD_SUCCESS = "FETCH_BOXES_DASHBOARD_SUCCESS";
export const FETCH_BOXES_DASHBOARD_FAILURE = "FETCH_BOXES_DASHBOARD_FAILURE";
export const FETCH_TEACHERS = "FETCH_TEACHERS";
export const FETCH_TEACHERS_SUCCESS = "FETCH_TEACHERS_SUCCESS";
export const FETCH_TEACHERS_FAILURE = "FETCH_TEACHERS_FAILURE";
export const FETCH_DETAIL_STUDENT = "FETCH_DETAIL_STUDENT";
export const FETCH_DETAIL_STUDENT_SUCCESS = "FETCH_DETAIL_STUDENT_SUCCESS";
export const FETCH_DETAIL_STUDENT_FAILURE = "FETCH_DETAIL_STUDENT_FAILURE";
export const MASQUERADE_STUDENT = "MASQUERADE_STUDENT";
export const MASQUERADE_STUDENT_SUCCESS = "MASQUERADE_STUDENT_SUCCESS";
export const MASQUERADE_STUDENT_FAILURE = "MASQUERADE_STUDENT_FAILURE";
export const FETCH_ANALYZE_REALIZATIONS_STUDENT =
  "FETCH_ANALYZE_REALIZATIONS_STUDENT";
export const FETCH_ANALYZE_REALIZATIONS_STUDENT_SUCCESS =
  "FETCH_ANALYZE_REALIZATIONS_STUDENT_SUCCESS";
export const FETCH_ANALYZE_REALIZATIONS_STUDENT_FAILURE =
  "FETCH_ANALYZE_REALIZATIONS_STUDENT_FAILURE";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";
export const CLEAR_REALIZATION = "CLEAR_REALIZATION";
export const CLEAR_COMMENTS = "CLEAR_COMMENTS";
export const CLEAR_FEEDBACKS = "CLEAR_FEEDBACKS";
export const FETCH_GEAR_THEME_REALIZATION = "FETCH_GEAR_THEME_REALIZATION";
export const FETCH_GEAR_THEME_REALIZATION_SUCCESS =
  "FETCH_GEAR_THEME_REALIZATION_SUCCESS";
export const CLEAR_GEARTHEME_PAGINATION = "CLEAR_GEARTHEME_PAGINATION";
export const CHECKED_TEACHER = "CHECKED_TEACHER";
export const CHECKED_TEACHER_SUCCESS = "CHECKED_TEACHER_SUCCESS";
export const CHECKED_TEACHER_FAILURE = "CHECKED_TEACHER_FAILURE";
export const UNCHECKED_TEACHER = "UNCHECKED_TEACHER";
export const UNCHECKED_TEACHER_SUCCESS = "UNCHECKED_TEACHER_SUCCESS";
export const UNCHECKED_TEACHER_FAILURE = "UNCHECKED_TEACHER_FAILURE";

export const UPDATE_TEACHER_PASSWORD_SUCCESS_MESSAGE =
  "パスワードの登録に成功しました";
export const RESET_TEACHER_PASSWORD_SUCCESS_MESSAGE =
  "パスワードリセットメールの送信に成功しました";
export const UPDATE_TEACHER_SUCCESS_MESSAGE =
  "プロフィールの更新に成功しました";
export const UPDATE_TEACHER_FAILURE_MESSAGE =
  "プロフィールの更新に失敗しました";
export const BULK_CHECK_REALIZATIONS_SUCCESS_MESSAGE =
  "気づきの一括確認に成功しました";
export const READ_REALIZATION_SUCCESS_MESSAGE = "気づきの確認に成功しました";
export const FETCH_NOTIFICATIONS_SUCCESS_MESSAGE =
  "お知らせの取得に成功しました";
export const MASQUERADE_STUDENT_SUCCESS_MESSAGE =
  "生徒の代理ログイン用トークンの取得が完了しました";

export const CREATE_COMMENT_SUCCESS_MESSAGE = "コメントの返信に成功しました";
export const UPDATE_COMMENT_SUCCESS_MESSAGE = "コメントの更新に成功しました";
export const DELETE_COMMENT_SUCCESS_MESSAGE = "コメントの削除に成功しました";
export const CREATE_FEEDBACK_SUCCESS_MESSAGE =
  "フィードバックの返信に成功しました";
export const UPDATE_FEEDBACK_SUCCESS_MESSAGE =
  "フィードバックの更新に成功しました";
export const DELETE_FEEDBACK_SUCCESS_MESSAGE =
  "フィードバックの削除に成功しました";
export const MAIL_PASSWORD_LIST_SUCCESS_MESSAGE =
  "パスワードリストのメール送信を受け付けました";
export const FETCH_REALIZATION_KLASSES_SUCCESS_MESSAGE =
  "タイムラインの取得に成功しました";
export const FETCH_SUBMITTED_REALIZATIONS_SUCCESS_MESSAGE =
  "提出されたストックの取得に成功しました";
export const FETCH_READ_REALIZATIONS_SUCCESS_MESSAGE =
  "確認済みの気づきの取得に成功しました";
export const CREATE_GEARBOX_SUCCESS_MESSAGE = "BOXが正常に作成されました。";
export const UPDATE_GEARBOX_SUCCESS_MESSAGE = "BOXが正常に更新されました。";
export const DELETE_GEARBOX_SUCCESS_MESSAGE = "BOXは正常に削除されました。";
export const CREATE_TEACHER_GEARBOX_DIRECTORY_SUCCESS_MESSAGE =
  "フォルダが正常に作成されました。";
export const UPDATE_TEACHER_GEARBOX_DIRECTORY_SUCCESS_MESSAGE =
  "フォルダが正常に更新されました。";
export const DELETE_TEACHER_GEARBOX_DIRECTORY_SUCCESS_MESSAGE =
  "フォルダは正常に削除されました。";
export const CREATE_GEARBOX_TEMPLATE_SUCCESS_MESSAGE =
  "オリジナルテンプレートが正常に作成されました。";
export const UPDATE_GEARBOX_TEMPLATE_SUCCESS_MESSAGE =
  "オリジナルテンプレートが正常に更新されました。";
export const DELETE_GEARBOX_TEMPLATE_SUCCESS_MESSAGE =
  "オリジナルテンプレートが正常に削除されました。";
export const FETCH_BOXES_DASHBOARD_SUCCESS_MESSAGE =
  "BOXダッシュボードの取得に成功しました";
export const FETCH_GEARBOX_REALIZATIONS_SUCCESS_MESSAGE =
  "fetch_gearbox_realizations_success_message";
export const GEARBOX_HIDDEN_SUCCESS_MESSAGE = "BOXが非表示になりました。";
export const REORDER_BOXES = "REORDER_BOXES";
export const REORDER_BOXES_SUCCESS = "REORDER_BOXES_SUCCESS";
export const REORDER_BOXES_FAILURE = "REORDER_BOXES_FAILURE";
export const REORDER_TEACHER_GEARBOX_DIRECTORIES =
  "REORDER_TEACHER_GEARBOX_DIRECTORIES";
export const REORDER_TEACHER_GEARBOX_DIRECTORIES_SUCCESS =
  "REORDER_TEACHER_GEARBOX_DIRECTORIES_SUCCESS";
export const REORDER_TEACHER_GEARBOX_DIRECTORIES_FAILURE =
  "REORDER_TEACHER_GEARBOX_DIRECTORIES_FAILURE";

export const LOGOUT_SUCCESS_MESSAGE = "ログアウトしました。";

export const ACTION_503 = "ACTION_503";
export const ACTION_503_MESSAGE = "サービスが利用できません";

export const FETCH_GEAR_THEME_REALIZATION_SUCCESS_MESSAGE =
  "msg_fetch_gear_theme_realization_success";

export const TOGGLE_CHECKED_SUCCESS_MESSAGE = "TOGGLE_CHECKED_SUCCESS_MESSAGE";

export const API_URL = `${
  process.env.REACT_APP_API_URL ??
  window.location.protocol + "//" + window.location.host
}/api/v1/teacher`;

export const prefixPath = "/teacher";
export const homePath = `/`;
export const mypagePath = `/mypage`;
export const manageBoxesPath = `/manage_boxes`;
export const manageBoxDirectoriesPath = `/manage_box_directories`;
export const boxesPath = `/boxes`;
export const loginPath = `/login`;
export const updateTeacherPasswordPath = "/update_teacher_password";
export const authenticationPath = `/authentication`;
export const resetTeacherPasswordPath = "/reset_teacher_password";
export const sendEmailCompletePath = "/send_email_complete";
export const editProfilePath = "/edit_profile";
export const submittedRealizationListPath = "/realizations";
export const sharedRealizationListPath = "/shared";
export const sharedRealizationPath = "/share/";
export const reportListPath = "/reports";
export const analyzePath = "/analyze";
export const notificationListPath = "/notifications";
export const gearthemesListPath = "/gear_themes";

export const login = (email: string, password: string, remember: boolean) => ({
  types: LOGIN as typeof LOGIN,
  payload: {
    request: {
      data: { email, password, remember },
      method: "post",
      url: `${API_URL}/login`,
    },
  },
});

export const verifyMFA = (ott: string, mfa_code: string) => ({
  types: VERIFY_MFA as typeof VERIFY_MFA,
  payload: {
    request: {
      data: { ott, mfa_code },
      method: "post",
      url: `${API_URL}/teachers/verify_mfa`,
    },
  },
});

export const logout = () => ({
  types: LOGOUT as typeof LOGOUT,
  payload: {
    request: {
      method: "delete",
      url: `${API_URL}/logout`,
    },
  },
});

export const masqueradeStudent = () => {
  return {
    types: MASQUERADE_STUDENT as typeof MASQUERADE_STUDENT,
    payload: {
      request: {
        method: "post",
        url: `${API_URL}/students/masquerade`,
      },
    },
  };
};

export const fetchInitialData = () => ({
  types: FETCH_INITIAL_DATA as typeof FETCH_INITIAL_DATA,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/teachers/initial_data`,
    },
  },
});

export const updateTeacher = (
  id: number,
  password: string,
  photo: IFile | null,
) => {
  const args: { [key: string]: any } = { password };
  if (photo && photo.blob) {
    args["photo"] = photo.blob;
  }
  if (photo && photo.deleted && photo.id) {
    args["delete_photo"] = photo.id;
  }
  return {
    types: UPDATE_TEACHER as typeof UPDATE_TEACHER,
    payload: {
      request: {
        data: args,
        method: "put",
        url: `${API_URL}/teachers/${id}`,
      },
    },
  };
};

export const updateTeacherPassword = (uuid: string, password: string) => ({
  types: UPDATE_TEACHER_PASSWORD as typeof UPDATE_TEACHER_PASSWORD,
  payload: {
    request: {
      data: { uuid, password },
      method: "post",
      url: `${API_URL}/teachers/update_password`,
    },
  },
});

export const resetTeacherPassword = (email: string) => ({
  types: RESET_TEACHER_PASSWORD as typeof RESET_TEACHER_PASSWORD,
  payload: {
    request: {
      data: { email, type: "gear" },
      method: "post",
      url: `${API_URL}/teachers/reset_password`,
    },
  },
});

export const updateReadLastNotification = (notification_id: number) => ({
  types: UPDATE_READ_LAST_NOTIFICATION as typeof UPDATE_READ_LAST_NOTIFICATION,
  payload: {
    request: {
      data: { notification_id },
      method: "post",
      url: `${API_URL}/teachers/update_read_last_notification`,
    },
  },
});

export const fetchNotifications = () => ({
  types: FETCH_NOTIFICATIONS as typeof FETCH_NOTIFICATIONS,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/notifications`,
    },
  },
});

export const clearSuccessMessage = () => ({
  types: CLEAR_SUCCESS_MESSAGE as typeof CLEAR_SUCCESS_MESSAGE,
  payload: {},
});

export const clearErrorMessage = () => ({
  types: CLEAR_ERROR_MESSAGE as typeof CLEAR_ERROR_MESSAGE,
  payload: {},
});
export const clearRealizations = () => ({
  types: CLEAR_REALIZATIONS as typeof CLEAR_REALIZATIONS,
  payload: {},
});

export const clearSelectedGearbox = () => ({
  types: CLEAR_SELECTED_GEARBOX as typeof CLEAR_SELECTED_GEARBOX,
  payload: {},
});

export const clearReadRealizations = () => ({
  types: CLEAR_READ_REALIZATIONS as typeof CLEAR_READ_REALIZATIONS,
  payload: {},
});

export const clearAnalyzeRealizations = () => ({
  types: CLEAR_ANALYZE_REALIZATIONS as typeof CLEAR_ANALYZE_REALIZATIONS,
  payload: {},
});

export const clearAnalyzeStudent = () => ({
  types: CLEAR_ANALYZE_STUDENT as typeof CLEAR_ANALYZE_STUDENT,
  payload: {},
});

export const clearRealizationKlasses = () => ({
  types: CLEAR_REALIZATION_KLASSES as typeof CLEAR_REALIZATION_KLASSES,
  payload: {},
});

export const clearPagyInfo = () => ({
  types: CLEAR_PAGY_INFO as typeof CLEAR_PAGY_INFO,
  payload: {},
});

export const clearRealizationsAnalyzePagyInfo = () => ({
  types:
    CLEAR_REALIZATIONS_ANALYZE_PAGY_INFO as typeof CLEAR_REALIZATIONS_ANALYZE_PAGY_INFO,
  payload: {},
});

export const clearGearthemePagination = () => ({
  types: CLEAR_GEARTHEME_PAGINATION as typeof CLEAR_GEARTHEME_PAGINATION,
  payload: {},
});

export const clearOTT = () => ({
  types: CLEAR_OTT as typeof CLEAR_OTT,
  payload: {},
});

export const clearRealization = () => ({
  types: CLEAR_REALIZATION as typeof CLEAR_REALIZATION,
  payload: {},
});

export const fetchSubmittedRealizations = (class_id: number, page: number) => ({
  types: FETCH_SUBMITTED_REALIZATIONS as typeof FETCH_SUBMITTED_REALIZATIONS,
  payload: {
    request: {
      data: { class_id, page },
      method: "get",
      url: `${API_URL}/realizations`,
    },
  },
});

export const fetchReadRealizations = (class_id: number, page: number) => ({
  types: FETCH_READ_REALIZATIONS as typeof FETCH_READ_REALIZATIONS,
  payload: {
    request: {
      data: { class_id, page, read: true },
      method: "get",
      url: `${API_URL}/realizations`,
    },
  },
});

export const fetchRealizationKlasses = (class_id: number, page: number) => ({
  types: FETCH_REALIZATION_KLASSES as typeof FETCH_REALIZATION_KLASSES,
  payload: {
    request: {
      data: { class_id, page },
      method: "get",
      url: `${API_URL}/realization_klasses`,
    },
  },
});

export const fetchRealization = (realization_id: number) => ({
  types: FETCH_REALIZATION as typeof FETCH_REALIZATION,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/realizations/${realization_id}`,
    },
  },
});

export const bulkCheckRealizations = (gear_box_id: number) => ({
  types: BULK_CHECK_REALIZATIONS as typeof BULK_CHECK_REALIZATIONS,
  payload: {
    request: {
      data: { gear_box_id },
      method: "POST",
      url: `${API_URL}/gearboxes/${gear_box_id}/read_realizations`,
    },
  },
});

export const checkRealizationKlass = (class_id: number) => ({
  types: CHECK_REALIZATION_KLASS as typeof CHECK_REALIZATION_KLASS,
  payload: {
    request: {
      data: { class_id },
      method: "post",
      url: `${API_URL}/realization_klasses/check`,
    },
  },
});

export const createComment = (realization_id: number, content: string) => ({
  types: CREATE_COMMENT as typeof CREATE_COMMENT,
  payload: {
    request: {
      data: { realization_id, content },
      method: "post",
      url: `${API_URL}/comments`,
    },
  },
});

export const updateComment = (comment_id: number, content: string) => ({
  types: UPDATE_COMMENT as typeof UPDATE_COMMENT,
  payload: {
    request: {
      data: { content },
      method: "put",
      url: `${API_URL}/comments/${comment_id}`,
    },
  },
});

export const deleteComment = (comment_id: number) => ({
  types: DELETE_COMMENT as typeof DELETE_COMMENT,
  payload: {
    request: {
      data: {},
      method: "delete",
      url: `${API_URL}/comments/${comment_id}`,
    },
  },
});

export const mailPasswordList = (class_id: number) => ({
  types: MAIL_PASSWORD_LIST as typeof MAIL_PASSWORD_LIST,
  payload: {
    request: {
      data: {},
      method: "post",
      url: `${API_URL}/classes/${class_id}/mail_password_list`,
    },
  },
});

export const fetchComments = (realization_id: number) => ({
  types: FETCH_COMMENTS as typeof FETCH_COMMENTS,
  payload: {
    request: {
      data: { realization_id },
      method: "get",
      url: `${API_URL}/comments`,
    },
  },
});

export const fetchStatusRenewaled = () => ({
  types: FETCH_STATUS_RENEWALED as typeof FETCH_STATUS_RENEWALED,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/teachers/status_renewaled`,
    },
  },
});

export const createFeedback = (realization_id: number, content: string) => ({
  types: CREATE_FEEDBACK as typeof CREATE_FEEDBACK,
  payload: {
    request: {
      data: { realization_id, content },
      method: "post",
      url: `${API_URL}/feedbacks`,
    },
  },
});

export const updateFeedback = (feedback_id: number, content: string) => ({
  types: UPDATE_FEEDBACK as typeof UPDATE_FEEDBACK,
  payload: {
    request: {
      data: { content },
      method: "put",
      url: `${API_URL}/feedbacks/${feedback_id}`,
    },
  },
});

export const deleteFeedback = (feedback_id: number) => ({
  types: DELETE_FEEDBACK as typeof DELETE_FEEDBACK,
  payload: {
    request: {
      data: {},
      method: "delete",
      url: `${API_URL}/feedbacks/${feedback_id}`,
    },
  },
});

export const fetchFeedbacks = (realization_id: number) => ({
  types: FETCH_FEEDBACKS as typeof FETCH_FEEDBACKS,
  payload: {
    request: {
      data: { realization_id },
      method: "get",
      url: `${API_URL}/feedbacks`,
    },
  },
});

export const fetchReports = () => ({
  types: FETCH_REPORTS as typeof FETCH_REPORTS,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/reports`,
    },
  },
});

export const fetchSummaryPerClassStudents = (
  class_id: number,
  span: string,
) => ({
  types:
    FETCH_SUMMARY_PER_CLASS_STUDENTS as typeof FETCH_SUMMARY_PER_CLASS_STUDENTS,
  payload: {
    request: {
      data: { class_id, span },
      method: "get",
      url: `${API_URL}/realizations/summary_per_class_students`,
    },
  },
});

export const updateStudentDetailModal = (
  student_detail_modal: IStudentDetailModal | null,
) => ({
  types: UPDATE_STUDENT_DETAIL_MODAL as typeof UPDATE_STUDENT_DETAIL_MODAL,
  payload: {
    data: {
      student_detail_modal,
    },
  },
});

export const fetchRealizationsCSVData = (class_id: number) => ({
  types: FETCH_REALIZATIONS_CSV_DATA as typeof FETCH_REALIZATIONS_CSV_DATA,
  payload: {
    request: {
      data: { class_id },
      method: "get",
      url: `${API_URL}/realizations/download_csv`,
    },
  },
});

export const fetchGearboxes = () => ({
  types: FETCH_GEARBOXES as typeof FETCH_GEARBOXES,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/gearboxes`,
    },
  },
});

export const fetchGearboxesByStudent = (id: string | number) => ({
  types: FETCH_GEARBOXES_BY_STUDENT as typeof FETCH_GEARBOXES_BY_STUDENT,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/analyze/gearboxes/by_student?student_id=${id}`,
    },
  },
});

export const createGearbox = (args: CreateGearboxParams) => ({
  types: CREATE_GEARBOX as typeof CREATE_GEARBOX,
  payload: {
    request: {
      data: {
        ...args,
      },
      method: "post",
      url: `${API_URL}/gearboxes`,
    },
  },
});

export const gearboxHidden = (id: number) => ({
  types: GEARBOX_HIDDEN as typeof GEARBOX_HIDDEN,
  payload: {
    request: {
      method: "put",
      url: `${API_URL}/gearboxes/${id}/hidden`,
    },
  },
});

export const updateGearbox = ({ id, ...rest }: UpdateGearboxParams) => ({
  types: UPDATE_GEARBOX as typeof UPDATE_GEARBOX,
  payload: {
    request: {
      data: {
        ...rest,
      },
      method: "put",
      url: `${API_URL}/gearboxes/${id}`,
    },
  },
});

export const deleteGearbox = (id: number) => ({
  types: DELETE_GEARBOX as typeof DELETE_GEARBOX,
  payload: {
    request: {
      data: {},
      method: "delete",
      url: `${API_URL}/gearboxes/${id}`,
    },
  },
});

export const fetchTeacherGearboxDirectories = () => ({
  types:
    FETCH_TEACHER_GEARBOX_DIRECTORIES as typeof FETCH_TEACHER_GEARBOX_DIRECTORIES,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/teacher_gearbox_directories`,
    },
  },
});

export const createTeacherGearboxDirectory = (
  args: CreateTeacherGearboxDirectoryParams,
) => ({
  types:
    CREATE_TEACHER_GEARBOX_DIRECTORY as typeof CREATE_TEACHER_GEARBOX_DIRECTORY,
  payload: {
    request: {
      data: {
        ...args,
      },
      method: "post",
      url: `${API_URL}/teacher_gearbox_directories`,
    },
  },
});

export const updateTeacherGearboxDirectory = ({
  id,
  ...rest
}: UpdateTeacherGearboxDirectoryParams) => ({
  types:
    UPDATE_TEACHER_GEARBOX_DIRECTORY as typeof UPDATE_TEACHER_GEARBOX_DIRECTORY,
  payload: {
    request: {
      data: {
        ...rest,
      },
      method: "put",
      url: `${API_URL}/teacher_gearbox_directories/${id}`,
    },
  },
});

export const deleteTeacherGearboxDirectory = (id: number) => ({
  types:
    DELETE_TEACHER_GEARBOX_DIRECTORY as typeof DELETE_TEACHER_GEARBOX_DIRECTORY,
  payload: {
    request: {
      data: {},
      method: "delete",
      url: `${API_URL}/teacher_gearbox_directories/${id}`,
    },
  },
});

export const fetchGearboxTemplates = () => ({
  types:
    FETCH_SHARED_GEARBOX_TEMPLATES as typeof FETCH_SHARED_GEARBOX_TEMPLATES,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/shared_gearbox_templates`,
    },
  },
});

export const fetchMyGearboxTemplates = () => ({
  types: FETCH_MY_GEARBOX_TEMPLATES as typeof FETCH_MY_GEARBOX_TEMPLATES,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/gearbox_templates`,
    },
  },
});

export const createGearboxTemplate = (args: CreateGearboxTemplateParams) => ({
  types: CREATE_GEARBOX_TEMPLATE as typeof CREATE_GEARBOX_TEMPLATE,
  payload: {
    request: {
      data: {
        ...args,
      },
      method: "post",
      url: `${API_URL}/gearbox_templates`,
    },
  },
});

export const updateGearboxTemplate = ({
  id,
  ...rest
}: UpdateGearboxTemplateParams) => ({
  types: UPDATE_GEARBOX_TEMPLATE as typeof UPDATE_GEARBOX_TEMPLATE,
  payload: {
    request: {
      data: {
        ...rest,
      },
      method: "put",
      url: `${API_URL}/gearbox_templates/${id}`,
    },
  },
});

export const reorderGearboxTemplates = (gearbox_template_ids: number[]) => ({
  types: REORDER_GEARBOX_TEMPLATES as typeof REORDER_GEARBOX_TEMPLATES,
  payload: {
    request: {
      data: { gearbox_template_ids },
      method: "PUT",
      url: `${API_URL}/gearbox_templates/reorder`,
    },
  },
});

export const deleteGearboxTemplate = (id: number) => ({
  types: DELETE_GEARBOX_TEMPLATE as typeof DELETE_GEARBOX_TEMPLATE,
  payload: {
    request: {
      data: {},
      method: "delete",
      url: `${API_URL}/gearbox_templates/${id}`,
    },
  },
});

export const fetchGearboxStudents = (gearbox_id: number) => ({
  types: FETCH_GEARBOX_STUDENTS as typeof FETCH_GEARBOX_STUDENTS,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/gearboxes/${gearbox_id}/students`,
    },
  },
});

export const fetchGearboxRealizations = (gearbox_id: number, page: number) => ({
  types: FETCH_GEARBOX_REALIZATIONS as typeof FETCH_GEARBOX_REALIZATIONS,
  payload: {
    request: {
      data: { gearbox_id, page },
      method: "get",
      url: `${API_URL}/realizations`,
    },
  },
});

export const fetchMyBoxSummary = () => ({
  types: FETCH_MY_BOX_SUMMARY as typeof FETCH_MY_BOX_SUMMARY,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/realizations/summary_submitted_without_box`,
    },
  },
});

export const fetchMyBoxRealizations = (page: number) => ({
  types: FETCH_MY_BOX_REALIZATIONS as typeof FETCH_MY_BOX_REALIZATIONS,
  payload: {
    request: {
      data: { page },
      method: "get",
      url: `${API_URL}/realizations/submitted_without_box`,
    },
  },
});

export const fetchClassesIncludePast = () => ({
  types: FETCH_CLASSES_INCLUDE_PAST as typeof FETCH_CLASSES_INCLUDE_PAST,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/classes/include_past`,
    },
  },
});

export const fetchTeachers = () => ({
  types: FETCH_TEACHERS as typeof FETCH_TEACHERS,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/teachers`,
    },
  },
});

export const fetchCategories = (student_id: number | string) => ({
  types: FETCH_CATEGORIES as typeof FETCH_CATEGORIES,
  payload: {
    request: {
      data: { student_id },
      method: "get",
      url: `${API_URL}/categories`,
    },
  },
});

export const fetchDetailStudent = (
  id: string | number,
  start_date?: string | null,
  end_date?: string | null,
) => {
  let data: { start_date: Date | string; end_date: Date | string } | null =
    null;
  if (start_date && end_date) data = { start_date, end_date };
  return {
    types: FETCH_DETAIL_STUDENT as typeof FETCH_DETAIL_STUDENT,
    payload: {
      request: {
        data,
        method: "get",
        url: `${API_URL}/analyze/students/${id}`,
      },
    },
  };
};

export const fetchAnalyzeRealizationsStudent = (
  id: string | number,
  gearbox_id?: string | number | null,
  category_id?: string | number | null,
  start_date?: string | null,
  end_date?: string | null,
  page?: number,
) => {
  const data: any = {};
  if (gearbox_id) data.gearbox_id = gearbox_id;
  if (category_id) data.category_id = category_id;
  if (start_date && end_date) {
    data.start_date = start_date;
    data.end_date = end_date;
  }
  data.page = page ?? 1;
  return {
    types:
      FETCH_ANALYZE_REALIZATIONS_STUDENT as typeof FETCH_ANALYZE_REALIZATIONS_STUDENT,
    payload: {
      request: {
        data,
        method: "get",
        url: `${API_URL}/analyze/students/${id}/realizations`,
      },
    },
  };
};

export const fetchBoxesDashboard = ({
  title = "",
  class_id = 0,
  start_date = null,
  end_date = null,
  order_by = EOrderBy.LATEST_REALIZATION_CREATED_AT,
}: IBoxesDashboardParams) => {
  const formattedStartDate = start_date
    ? format(start_date, "yyyy-MM-dd")
    : null;
  const formattedEndDate = end_date ? format(end_date, "yyyy-MM-dd") : null;
  return {
    types: FETCH_BOXES_DASHBOARD as typeof FETCH_BOXES_DASHBOARD,
    payload: {
      request: {
        data: {
          ...(!!title && { title }),
          ...(!!class_id && { class_id }),
          ...(!!formattedStartDate && { start_date: formattedStartDate }),
          ...(!!formattedEndDate && { end_date: formattedEndDate }),
          order_by,
        },
        method: "get",
        url: `${API_URL}/gearboxes/dashboard`,
      },
    },
  };
};

export const reorderBoxes = (gearbox_ids: number[]) => ({
  types: REORDER_BOXES as typeof REORDER_BOXES,
  payload: {
    request: {
      data: { gearbox_ids },
      method: "PUT",
      url: `${API_URL}/gearboxes/reorder`,
    },
  },
});

export const reorderTeacherGearboxDirectories = (
  teacher_gearbox_directory_ids: number[],
) => ({
  types:
    REORDER_TEACHER_GEARBOX_DIRECTORIES as typeof REORDER_TEACHER_GEARBOX_DIRECTORIES,
  payload: {
    request: {
      data: { teacher_gearbox_directory_ids },
      method: "PUT",
      url: `${API_URL}/teacher_gearbox_directories/reorder`,
    },
  },
});

export const fetchGearthemeRealization = (
  geartheme_id: number,
  prev_id: number | null,
) => {
  return {
    types: FETCH_GEAR_THEME_REALIZATION as typeof FETCH_GEAR_THEME_REALIZATION,
    payload: {
      request: {
        method: "get",
        data: {
          geartheme_id,
          ...(prev_id && { prev_id }),
        },
        url: `${API_URL}/realizations/geartheme`,
      },
    },
  };
};

export const checkedTeacher = (realization_id: number) => ({
  types: CHECKED_TEACHER as typeof CHECKED_TEACHER,
  payload: {
    request: {
      data: { realization_id },
      method: "POST",
      url: `${API_URL}/teacher_check_realizations`,
    },
  },
});

export const uncheckedTeacher = (realization_id: number) => ({
  types: UNCHECKED_TEACHER as typeof UNCHECKED_TEACHER,
  payload: {
    request: {
      data: { realization_id },
      method: "delete",
      url: `${API_URL}/teacher_check_realizations`,
    },
  },
});

export const clearComments = () => ({
  types: CLEAR_COMMENTS as typeof CLEAR_COMMENTS,
  payload: {},
});

export const clearFeedbacks = () => ({
  types: CLEAR_FEEDBACKS as typeof CLEAR_FEEDBACKS,
  payload: {},
});

type loginSuccess = {
  types: typeof LOGIN_SUCCESS;
  payload: {
    data: ILoginResponse | ILoginMFAResponse;
  };
};

type loginFailure = {
  types: typeof LOGIN_FAILURE;
  payload: {
    error: {
      message: string;
      field: string;
      status: number;
    };
  };
};

type verifyMFASuccess = {
  types: typeof VERIFY_MFA_SUCCESS;
  payload: {
    data: ILoginResponse;
  };
};

type verifyMFAFailure = {
  types: typeof VERIFY_MFA_FAILURE;
  payload: {
    error: {
      message: string;
      field: string;
      status: number;
    };
  };
};

type logoutSuccess = {
  types: typeof LOGOUT_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type fetchInitialDataSuccess = {
  types: typeof FETCH_INITIAL_DATA_SUCCESS;
  payload: {
    data: {
      has_student_account: boolean;
      teacher: ITeacher;
      school_name: string;
      unread_shared_per_class: { [key: string]: number };
      unread_notification_count: number;
      geartheme: IGearTheme;
    };
  };
};

type masqueradeStudentSuccess = {
  types: typeof MASQUERADE_STUDENT_SUCCESS;
  payload: {
    data: { session_id: string };
  };
};

type masqueradeStudentFailure = {
  types: typeof MASQUERADE_STUDENT_FAILURE;
  payload: {
    error: {
      message: string;
      field: string;
      status: number;
    };
  };
};

type fetchStatusRenewaledSuccess = {
  types: typeof FETCH_STATUS_RENEWALED_SUCCESS;
  payload: {
    data: {
      unread_shared_per_class: { [key: string]: number };
      unread_notification_count: number;
    };
  };
};

type updateReadLastNotificationSuccess = {
  types: typeof UPDATE_READ_LAST_NOTIFICATION_SUCCESS;
  payload: {
    data: { teacher: ITeacher };
  };
};

type fetchNotificationsSuccess = {
  types: typeof FETCH_NOTIFICATIONS_SUCCESS;
  payload: {
    data: {
      notifications: INotification[];
    };
  };
};

type updateTeacherSuccess = {
  types: typeof UPDATE_TEACHER_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type updateTeacherFailure = {
  types: typeof UPDATE_TEACHER_FAILURE;
};

type updateTeacherPasswordSuccess = {
  types: typeof UPDATE_TEACHER_PASSWORD_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type resetTeacherPasswordSuccess = {
  types: typeof RESET_TEACHER_PASSWORD_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type resetTeacherPasswordFailure = {
  types: typeof RESET_TEACHER_PASSWORD_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchInitialDataFailure = {
  types: typeof FETCH_INITIAL_DATA_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchSubmittedRealizationsSuccess = {
  types: typeof FETCH_SUBMITTED_REALIZATIONS_SUCCESS;
  payload: {
    params: { url: string };
    data: {
      realizations: IRealization[];
      read_realization_ids: number[];
      meta: { pagy_info: IPagyInfo };
    };
  };
};

type fetchSubmittedRealizationsFailure = {
  types: typeof FETCH_SUBMITTED_REALIZATIONS_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchReadRealizationsSuccess = {
  types: typeof FETCH_READ_REALIZATIONS_SUCCESS;
  payload: {
    data: {
      realizations: IRealization[];
      meta: { pagy_info: IPagyInfo };
    };
  };
};

type fetchReadRealizationsFailure = {
  types: typeof FETCH_READ_REALIZATIONS_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchRealizationKlassesSuccess = {
  types: typeof FETCH_REALIZATION_KLASSES_SUCCESS;
  payload: {
    data: {
      realization_klasses: IRealizationKlass[];
      meta: {
        pagy_info: IPagyInfo;
      };
    };
  };
};

type fetchRealizationKlassesFailure = {
  types: typeof FETCH_REALIZATION_KLASSES_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchRealizationSuccess = {
  types: typeof FETCH_REALIZATION_SUCCESS;
  payload: {
    data: { realization: IRealization };
  };
};
type fetchRealizationFailure = {
  types: typeof FETCH_REALIZATION_FAILURE;
  payload: {
    error: { error: string; field: string };
  };
};

type bulkCheckRealizationsSuccess = {
  types: typeof BULK_CHECK_REALIZATIONS_SUCCESS;
  payload: {
    data: Record<string, unknown>;
    params: {
      gear_box_id: number;
    };
  };
};

type checkRealizationKlassSuccess = {
  types: typeof CHECK_REALIZATION_KLASS_SUCCESS;
  payload: {
    data: { class_id: number };
  };
};

type fetchReportsSuccess = {
  types: typeof FETCH_REPORTS_SUCCESS;
  payload: {
    data: { reports: IReportEntry[] };
  };
};

type createCommentSuccess = {
  types: typeof CREATE_COMMENT_SUCCESS;
  payload: {
    data: { realization_id: number };
  };
};

type createCommentFailure = {
  types: typeof CREATE_COMMENT_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type updateCommentSuccess = {
  types: typeof UPDATE_COMMENT_SUCCESS;
  payload: {
    data: { realization_id: number };
  };
};

type updateCommentFailure = {
  types: typeof UPDATE_COMMENT_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type deleteCommentSuccess = {
  types: typeof DELETE_COMMENT_SUCCESS;
  payload: {
    data: { realization_id: number; has_my_comment: boolean };
  };
};

type deleteCommentFailure = {
  types: typeof DELETE_COMMENT_FAILURE;
  payload: {
    error: { message: string };
  };
};

type mailPasswordListSuccess = {
  types: typeof MAIL_PASSWORD_LIST_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type createFeedbackSuccess = {
  types: typeof CREATE_FEEDBACK_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type createFeedbackFailure = {
  types: typeof CREATE_FEEDBACK_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type updateFeedbackSuccess = {
  types: typeof UPDATE_FEEDBACK_SUCCESS;
  payload: {
    data: { feedback: IFeedback };
  };
};

type updateFeedbackFailure = {
  types: typeof UPDATE_FEEDBACK_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type deleteFeedbackSuccess = {
  types: typeof DELETE_FEEDBACK_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type deleteFeedbackFailure = {
  types: typeof DELETE_FEEDBACK_FAILURE;
  payload: {
    error: { message: string };
  };
};

type fetchCommentsSuccess = {
  types: typeof FETCH_COMMENTS_SUCCESS;
  payload: {
    data: { comments: IComment[] };
  };
};

type fetchCommentsFailure = {
  types: typeof FETCH_COMMENTS_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchSummaryPerClassStudentsSuccess = {
  types: typeof FETCH_SUMMARY_PER_CLASS_STUDENTS_SUCCESS;
  payload: {
    data: { class_students_summaries: ISummaryPerClassStudents[] };
  };
};

type fetchSummaryPerClassStudentsFailure = {
  types: typeof FETCH_SUMMARY_PER_CLASS_STUDENTS_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchRealizationsCSVDataSuccess = {
  types: typeof FETCH_REALIZATIONS_CSV_DATA_SUCCESS;
  payload: {
    data: ICSVData;
  };
};

type fetchRealizationsCSVDataFailure = {
  types: typeof FETCH_REALIZATIONS_CSV_DATA_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchFeedbacksSuccess = {
  types: typeof FETCH_FEEDBACKS_SUCCESS;
  payload: {
    data: { feedbacks: IFeedback[] };
  };
};

type fetchFeedbacksFailure = {
  types: typeof FETCH_FEEDBACKS_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchGearboxesSuccess = {
  types: typeof FETCH_GEARBOXES_SUCCESS;
  payload: {
    data: { gearboxes: ITeacherGearbox[] };
  };
};

type fetchGearboxesByStudentSuccess = {
  types: typeof FETCH_GEARBOXES_BY_STUDENT_SUCCESS;
  payload: {
    data: { gearboxes: ITeacherGearbox[] };
  };
};

type gearboxHiddenSuccess = {
  types: typeof GEARBOX_HIDDEN_SUCCESS;
  payload: {
    data: {
      id: number;
      is_hidden: boolean;
    };
  };
};

type gearboxHiddenFailure = {
  types: typeof GEARBOX_HIDDEN_FAILURE;
  payload: {
    error: {
      message: string;
      field: string;
    };
  };
};

type createGearboxSuccess = {
  types: typeof CREATE_GEARBOX_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type createGearboxFailure = {
  types: typeof CREATE_GEARBOX_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type updateGearboxSuccess = {
  types: typeof UPDATE_GEARBOX_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type updateGearboxFailure = {
  types: typeof UPDATE_GEARBOX_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type deleteGearboxSuccess = {
  types: typeof DELETE_GEARBOX_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type deleteGearboxFailure = {
  types: typeof DELETE_GEARBOX_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchTeacherGearboxDirectoriesSuccess = {
  types: typeof FETCH_TEACHER_GEARBOX_DIRECTORIES_SUCCESS;
  payload: {
    data: { teacher_gearbox_directories: TeacherGearboxDirectory[] };
  };
};

type createTeacherGearboxDirectorySuccess = {
  types: typeof CREATE_TEACHER_GEARBOX_DIRECTORY_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type createTeacherGearboxDirectoryFailure = {
  types: typeof CREATE_TEACHER_GEARBOX_DIRECTORY_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type updateTeacherGearboxDirectorySuccess = {
  types: typeof UPDATE_TEACHER_GEARBOX_DIRECTORY_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type updateTeacherGearboxDirectoryFailure = {
  types: typeof UPDATE_TEACHER_GEARBOX_DIRECTORY_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type deleteTeacherGearboxDirectorySuccess = {
  types: typeof DELETE_TEACHER_GEARBOX_DIRECTORY_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type deleteTeacherGearboxDirectoryFailure = {
  types: typeof DELETE_TEACHER_GEARBOX_DIRECTORY_FAILURE;
  payload: {
    error: { message: string };
  };
};

type fetchSharedGearboxTemplatesSuccess = {
  types: typeof FETCH_SHARED_GEARBOX_TEMPLATES_SUCCESS;
  payload: {
    data: { gearbox_templates: GearboxTemplate[] };
  };
};

type fetchMyGearboxTemplatesSuccess = {
  types: typeof FETCH_MY_GEARBOX_TEMPLATES_SUCCESS;
  payload: {
    data: { gearbox_templates: GearboxTemplate[] };
  };
};

type createGearboxTemplateSuccess = {
  types: typeof CREATE_GEARBOX_TEMPLATE_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type createGearboxTemplateFailure = {
  types: typeof CREATE_GEARBOX_TEMPLATE_FAILURE;

  payload: {
    error: { message: string; field: string };
  };
};

type updateGearboxTemplateSuccess = {
  types: typeof UPDATE_GEARBOX_TEMPLATE_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type updateGearboxTemplateFailure = {
  types: typeof UPDATE_GEARBOX_TEMPLATE_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type reorderGearboxTemplatesSuccess = {
  types: typeof REORDER_GEARBOX_TEMPLATES_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type reorderGearboxTemplatesFailure = {
  types: typeof REORDER_GEARBOX_TEMPLATES_FAILURE;
  payload: {
    error: { message: string };
  };
};

type deleteGearboxTemplateSuccess = {
  types: typeof DELETE_GEARBOX_TEMPLATE_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type deleteGearboxTemplateFailure = {
  types: typeof DELETE_GEARBOX_TEMPLATE_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchGearboxRealizationsSuccess = {
  types: typeof FETCH_GEARBOX_REALIZATIONS_SUCCESS;
  payload: {
    data: {
      realizations: IRealization[];
      gearbox: ITeacherGearbox;
      meta: { pagy_info: IPagyInfo };
    };
  };
};

type fetchMyBoxSummarySuccess = {
  types: typeof FETCH_MY_BOX_SUMMARY_SUCCESS;
  payload: {
    data: {
      summary_submitted: IMyBox;
    };
  };
};

type fetchMyBoxRealizationsSuccess = {
  types: typeof FETCH_MY_BOX_REALIZATIONS_SUCCESS;
  payload: {
    data: {
      realizations: IRealization[];
      meta: { pagy_info: IPagyInfo };
    };
  };
};

type fetchGearboxStudentsSuccess = {
  types: typeof FETCH_GEARBOX_STUDENTS_SUCCESS;
  payload: {
    data: {
      realizations_count_of_left_students: number;
      students: IStudentForGearbox[];
    };
  };
};

type fetchClassesIncludePastSuccess = {
  types: typeof FETCH_CLASSES_INCLUDE_PAST_SUCCESS;
  payload: {
    data: { classes: IClass[] };
  };
};

type fetchTeachersSuccess = {
  types: typeof FETCH_TEACHERS_SUCCESS;
  payload: {
    data: { teachers: ITeacher[] };
  };
};

type fetchCategoriesSuccess = {
  types: typeof FETCH_CATEGORIES_SUCCESS;
  payload: {
    data: { categories: ICategory[] };
  };
};

type fetchDetailStudentSuccess = {
  types: typeof FETCH_DETAIL_STUDENT_SUCCESS;
  payload: {
    data: { student_analyze: IStudentAnalyze };
  };
};

type fetchAnalyzeRealizationsStudentSuccess = {
  types: typeof FETCH_ANALYZE_REALIZATIONS_STUDENT_SUCCESS;
  payload: {
    data: {
      realizations: IRealizationAnalyze[];
      meta: { pagy_info: IPagyInfo };
    };
  };
};

type fetchBoxesDashboardSuccess = {
  types: typeof FETCH_BOXES_DASHBOARD_SUCCESS;
  payload: {
    data: { gearboxes: IBoxDashboard[] };
  };
};

type fetchBoxesDashboardFailure = {
  types: typeof FETCH_BOXES_DASHBOARD_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type reorderBoxesSuccess = {
  types: typeof REORDER_BOXES_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type reorderBoxesFailure = {
  types: typeof REORDER_BOXES_FAILURE;
  payload: {
    error: { message: string };
  };
};

type reorderTeacherGearboxDirectoriesSuccess = {
  types: typeof REORDER_TEACHER_GEARBOX_DIRECTORIES_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type reorderTeacherGearboxDirectoriesFailure = {
  types: typeof REORDER_TEACHER_GEARBOX_DIRECTORIES_FAILURE;
  payload: {
    error: { message: string };
  };
};

type action503 = {
  types: typeof ACTION_503;
};

type fetchGearthemeRealizationSuccess = {
  types: typeof FETCH_GEAR_THEME_REALIZATION_SUCCESS;
  payload: {
    params: {
      prev_id?: number;
    };
    data: {
      geartheme: {
        id: number;
        realizations: IRealization[];
        title: string;
      };
      has_next: boolean;
    };
  };
};

type checkedTeacherSuccess = {
  types: typeof CHECKED_TEACHER_SUCCESS;
  payload: {
    data: {
      realization_id: number;
    };
  };
};

type uncheckedTeacherSuccess = {
  types: typeof UNCHECKED_TEACHER_SUCCESS;
  payload: {
    data: {
      realization_id: number;
    };
  };
};

export type Actions =
  | ReturnType<typeof login>
  | ReturnType<typeof verifyMFA>
  | ReturnType<typeof logout>
  | ReturnType<typeof fetchInitialData>
  | ReturnType<typeof updateTeacherPassword>
  | ReturnType<typeof resetTeacherPassword>
  | ReturnType<typeof updateTeacher>
  | ReturnType<typeof clearSuccessMessage>
  | ReturnType<typeof clearErrorMessage>
  | ReturnType<typeof clearRealizations>
  | ReturnType<typeof clearSelectedGearbox>
  | ReturnType<typeof clearReadRealizations>
  | ReturnType<typeof clearAnalyzeRealizations>
  | ReturnType<typeof clearAnalyzeStudent>
  | ReturnType<typeof clearRealizationKlasses>
  | ReturnType<typeof clearPagyInfo>
  | ReturnType<typeof clearRealizationsAnalyzePagyInfo>
  | ReturnType<typeof clearOTT>
  | ReturnType<typeof fetchSubmittedRealizations>
  | ReturnType<typeof fetchReadRealizations>
  | ReturnType<typeof fetchRealizationKlasses>
  | ReturnType<typeof fetchRealization>
  | ReturnType<typeof bulkCheckRealizations>
  | ReturnType<typeof checkRealizationKlass>
  | ReturnType<typeof updateReadLastNotification>
  | ReturnType<typeof fetchNotifications>
  | ReturnType<typeof fetchReports>
  | ReturnType<typeof createComment>
  | ReturnType<typeof updateComment>
  | ReturnType<typeof deleteComment>
  | ReturnType<typeof createFeedback>
  | ReturnType<typeof updateFeedback>
  | ReturnType<typeof deleteFeedback>
  | ReturnType<typeof mailPasswordList>
  | ReturnType<typeof fetchComments>
  | ReturnType<typeof fetchSummaryPerClassStudents>
  | ReturnType<typeof updateStudentDetailModal>
  | ReturnType<typeof fetchRealizationsCSVData>
  | ReturnType<typeof fetchFeedbacks>
  | ReturnType<typeof fetchStatusRenewaled>
  | ReturnType<typeof fetchGearboxes>
  | ReturnType<typeof createGearbox>
  | ReturnType<typeof updateGearbox>
  | ReturnType<typeof deleteGearbox>
  | ReturnType<typeof fetchTeacherGearboxDirectories>
  | ReturnType<typeof createTeacherGearboxDirectory>
  | ReturnType<typeof updateTeacherGearboxDirectory>
  | ReturnType<typeof deleteTeacherGearboxDirectory>
  | ReturnType<typeof fetchGearboxTemplates>
  | ReturnType<typeof fetchMyGearboxTemplates>
  | ReturnType<typeof createGearboxTemplate>
  | ReturnType<typeof updateGearboxTemplate>
  | ReturnType<typeof reorderGearboxTemplates>
  | ReturnType<typeof deleteGearboxTemplate>
  | ReturnType<typeof fetchGearboxStudents>
  | ReturnType<typeof fetchGearboxRealizations>
  | ReturnType<typeof fetchMyBoxSummary>
  | ReturnType<typeof fetchMyBoxRealizations>
  | ReturnType<typeof fetchClassesIncludePast>
  | ReturnType<typeof fetchTeachers>
  | ReturnType<typeof fetchDetailStudent>
  | ReturnType<typeof masqueradeStudent>
  | ReturnType<typeof fetchAnalyzeRealizationsStudent>
  | ReturnType<typeof fetchCategories>
  | ReturnType<typeof fetchBoxesDashboard>
  | ReturnType<typeof fetchGearboxesByStudent>
  | ReturnType<typeof clearRealization>
  | ReturnType<typeof checkedTeacher>
  | ReturnType<typeof uncheckedTeacher>
  | ReturnType<typeof clearComments>
  | ReturnType<typeof clearFeedbacks>
  | ReturnType<typeof fetchGearthemeRealization>
  | ReturnType<typeof clearGearthemePagination>
  | loginSuccess
  | loginFailure
  | verifyMFASuccess
  | verifyMFAFailure
  | fetchInitialDataSuccess
  | fetchInitialDataFailure
  | updateTeacherPasswordSuccess
  | resetTeacherPasswordSuccess
  | resetTeacherPasswordFailure
  | updateTeacherSuccess
  | updateTeacherFailure
  | updateReadLastNotificationSuccess
  | fetchStatusRenewaledSuccess
  | fetchNotificationsSuccess
  | fetchReportsSuccess
  | bulkCheckRealizationsSuccess
  | checkRealizationKlassSuccess
  | fetchSubmittedRealizationsSuccess
  | fetchSubmittedRealizationsFailure
  | masqueradeStudentSuccess
  | fetchReadRealizationsSuccess
  | fetchReadRealizationsFailure
  | masqueradeStudentFailure
  | fetchRealizationKlassesSuccess
  | fetchRealizationKlassesFailure
  | fetchRealizationSuccess
  | fetchRealizationFailure
  | createCommentSuccess
  | createCommentFailure
  | updateCommentSuccess
  | updateCommentFailure
  | deleteCommentSuccess
  | deleteCommentFailure
  | fetchCommentsSuccess
  | fetchCommentsFailure
  | fetchSummaryPerClassStudentsSuccess
  | fetchSummaryPerClassStudentsFailure
  | fetchRealizationsCSVDataSuccess
  | fetchRealizationsCSVDataFailure
  | mailPasswordListSuccess
  | createFeedbackSuccess
  | createFeedbackFailure
  | updateFeedbackSuccess
  | updateFeedbackFailure
  | deleteFeedbackSuccess
  | deleteFeedbackFailure
  | fetchFeedbacksSuccess
  | fetchFeedbacksFailure
  | logoutSuccess
  | fetchGearboxesSuccess
  | createGearboxSuccess
  | createGearboxFailure
  | updateGearboxSuccess
  | updateGearboxFailure
  | deleteGearboxSuccess
  | deleteGearboxFailure
  | fetchTeacherGearboxDirectoriesSuccess
  | createTeacherGearboxDirectorySuccess
  | createTeacherGearboxDirectoryFailure
  | updateTeacherGearboxDirectorySuccess
  | updateTeacherGearboxDirectoryFailure
  | deleteTeacherGearboxDirectorySuccess
  | deleteTeacherGearboxDirectoryFailure
  | fetchSharedGearboxTemplatesSuccess
  | fetchMyGearboxTemplatesSuccess
  | createGearboxTemplateSuccess
  | createGearboxTemplateFailure
  | updateGearboxTemplateSuccess
  | updateGearboxTemplateFailure
  | reorderGearboxTemplatesSuccess
  | reorderGearboxTemplatesFailure
  | deleteGearboxTemplateSuccess
  | deleteGearboxTemplateFailure
  | fetchGearboxStudentsSuccess
  | fetchGearboxRealizationsSuccess
  | fetchMyBoxSummarySuccess
  | fetchMyBoxRealizationsSuccess
  | fetchClassesIncludePastSuccess
  | fetchTeachersSuccess
  | fetchBoxesDashboardSuccess
  | fetchDetailStudentSuccess
  | fetchAnalyzeRealizationsStudentSuccess
  | fetchCategoriesSuccess
  | fetchGearboxesByStudentSuccess
  | gearboxHiddenSuccess
  | gearboxHiddenFailure
  | fetchBoxesDashboardFailure
  | reorderBoxesSuccess
  | reorderBoxesFailure
  | reorderTeacherGearboxDirectoriesSuccess
  | reorderTeacherGearboxDirectoriesFailure
  | fetchGearthemeRealizationSuccess
  | checkedTeacherSuccess
  | uncheckedTeacherSuccess
  | action503;

export type AppState = {
  session_id: string;
  error: string;
  error_field: string;
  success_message: string;
  isServiceUnavailable: boolean;
  isLoaderVisible: boolean;
  teacher: ITeacher;
  school_name: string;
  realizations: IRealization[];
  read_realizations: IRealization[];
  read_realization_ids: number[];
  realization: IRealization;
  classes: IClass[];
  teachers: ITeacher[];
  boxesDashboard: IBoxDashboard[];
  comments: IComment[];
  realization_klasses: IRealizationKlass[];
  unread_notification_count: number;
  notifications: INotification[];
  unread_shared_per_class: { [class_id: string]: number };
  report_entries: IReportEntry[];
  class_students_summaries: ISummaryPerClassStudents[];
  feedbacks: IFeedback[];
  gearboxes: ITeacherGearbox[];
  shared_gearbox_templates: GearboxTemplate[];
  my_gearbox_templates: GearboxTemplate[];
  gearboxesByStudent: ITeacherGearbox[];
  teacherGearboxDirectories: TeacherGearboxDirectory[];
  selected_gearbox: ITeacherGearbox | null;
  my_box: IMyBox | null;
  realizations_csv_data: ICSVData | null;
  pagy_info: IPagyInfo;
  realizations_analyze_pagy_info: IPagyInfo;
  lastAction: string;
  student_analyze: IStudentAnalyze | null;
  realizations_analyze: IRealizationAnalyze[];
  categories: ICategory[];
  realizations_count_of_left_students: number;
  gearboxStudents: IStudentForGearbox[];
  ott: string;
  student_detail_modal: IStudentDetailModal | null;
  has_student_account: boolean;
  temporary_session_id: string;
  geartheme_pagination: ITimelinePagination;
  geartheme: IGearTheme | null;
  expires: Date;
};

export const InitialAppState: AppState = {
  session_id: "",
  error: "",
  error_field: "",
  success_message: "",
  isServiceUnavailable: false,
  isLoaderVisible: false,
  teacher: {
    id: 0,
    serviced: false,
    school_id: 0,
    photo: null,
    first_name: "",
    last_name: "",
    first_name_kana: "",
    last_name_kana: "",
    email: "",
    read_last_notification_id: 0,
    classes: [],
  },
  school_name: "",
  realizations: [],
  read_realizations: [],
  read_realization_ids: [],
  classes: [],
  teachers: [],
  boxesDashboard: [],
  report_entries: [],
  feedbacks: [],
  realization: {
    id: 0,
    student_id: 0,
    categories: [],
    content: "",
    submitted: false,
    kind: "roots",
    shared: false,
    executed_at: new Date(),
    created_at: new Date(),
    read_last_comment_id: 0,
    read_last_feedback_id: 0,
    gearbox_id: null,
    image: null,
    gearbox: null,
    geartheme: null,
  },
  comments: [],
  realization_klasses: [],
  unread_notification_count: 0,
  unread_shared_per_class: {},
  notifications: [],
  class_students_summaries: [],
  gearboxes: [],
  teacherGearboxDirectories: [],
  shared_gearbox_templates: [],
  my_gearbox_templates: [],
  selected_gearbox: null,
  my_box: null,
  realizations_csv_data: null,
  pagy_info: {
    count: 0,
    items: 0,
    page: 0,
    pages: 0,
  },
  realizations_analyze_pagy_info: {
    count: 0,
    items: 0,
    page: 0,
    pages: 0,
  },
  lastAction: "",
  student_analyze: null,
  realizations_analyze: [],
  categories: [],
  gearboxesByStudent: [],
  realizations_count_of_left_students: 0,
  gearboxStudents: [],
  ott: "",
  student_detail_modal: null,
  has_student_account: false,
  temporary_session_id: "",
  geartheme_pagination: {
    has_next: false,
  },
  geartheme: null,
  expires: addHours(new Date(), 6),
};

function isLoginMFA(
  data: ILoginResponse | ILoginMFAResponse,
): data is ILoginMFAResponse {
  return (data as ILoginMFAResponse).ott !== undefined;
}

export const reducer = (state: AppState, action: Actions): AppState => {
  const newState = { ...state, lastAction: action.types };
  switch (action.types) {
    case LOGIN_SUCCESS: {
      if (isLoginMFA(action.payload.data)) {
        const { ott } = action.payload.data;
        return {
          ...newState,
          error: "",
          error_field: "",
          ott,
        };
      }
      const { data } = action.payload;
      return {
        ...newState,
        error: "",
        error_field: "",
        session_id: data.session_id,
        school_name: data.school_name,
        teacher: data.teacher,
      };
    }
    case VERIFY_MFA_SUCCESS: {
      const { session_id, school_name, teacher } = action.payload.data;
      return {
        ...newState,
        error: "",
        error_field: "",
        session_id,
        school_name,
        teacher,
      };
    }
    case VERIFY_MFA_FAILURE: {
      const { message, field } = action.payload.error;
      return {
        ...newState,
        error: message,
        error_field: field,
      };
    }
    case FETCH_INITIAL_DATA_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        error: "",
        error_field: "",
        has_student_account: data.has_student_account,
        teacher: data.teacher,
        school_name: data.school_name,
        unread_notification_count: data.unread_notification_count,
        unread_shared_per_class: action.payload.data.unread_shared_per_class,
        session_id: data.teacher.id ? state.session_id : "",
        geartheme: data.geartheme,
      };
    }
    case MASQUERADE_STUDENT_SUCCESS: {
      return {
        ...newState,
        success_message: MASQUERADE_STUDENT_SUCCESS_MESSAGE,
        temporary_session_id: action.payload.data.session_id,
      };
    }
    case MASQUERADE_STUDENT_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
        error_field: action.payload.error.field,
      };
    }
    case FETCH_CATEGORIES_SUCCESS: {
      const { categories } = action.payload.data;
      return {
        ...newState,
        categories,
      };
    }
    case FETCH_DETAIL_STUDENT_SUCCESS: {
      const { student_analyze } = action.payload.data;
      return {
        ...newState,
        student_analyze,
      };
    }
    case FETCH_ANALYZE_REALIZATIONS_STUDENT_SUCCESS: {
      const { realizations, meta } = action.payload.data;
      const { pagy_info } = meta;
      const realizationsData =
        pagy_info.page === 1
          ? realizations
          : [...newState.realizations_analyze, ...realizations];
      return {
        ...newState,
        realizations_analyze_pagy_info: pagy_info,
        realizations_analyze: realizationsData,
      };
    }
    case UPDATE_READ_LAST_NOTIFICATION_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        error: "",
        error_field: "",
        teacher: {
          ...state.teacher,
          read_last_notification_id: data.teacher.read_last_notification_id,
        },
      };
    }
    case FETCH_SUBMITTED_REALIZATIONS: {
      return {
        ...newState,
        error: "",
        error_field: "",
      };
    }
    case FETCH_NOTIFICATIONS_SUCCESS: {
      return {
        ...newState,
        notifications: action.payload.data.notifications.map(n => ({
          ...n,
          created_at: new Date(n.created_at),
          updated_at: new Date(n.updated_at),
        })),
        unread_notification_count: 0,
        success_message: FETCH_NOTIFICATIONS_SUCCESS_MESSAGE,
      };
    }
    case FETCH_INITIAL_DATA_FAILURE: {
      return { ...InitialAppState };
    }
    case LOGIN:
      return {
        ...newState,
        error: "",
        error_field: "",
      };
    case LOGIN_FAILURE:
      return {
        ...newState,
        error: action.payload.error.message,
        error_field: action.payload.error.field,
      };
    case BULK_CHECK_REALIZATIONS_SUCCESS: {
      const gear_box_id = action.payload.params.gear_box_id;
      const newboxesDashboard = [...newState.boxesDashboard].map(box => {
        if (box.id === gear_box_id) {
          return {
            ...box,
            has_unread_realization: false,
          };
        }
        return box;
      });
      return gear_box_id === 0
        ? {
            ...newState,
            error: "",
            error_field: "",
            my_box: {
              ...newState.my_box,
              has_unread_realization: false,
            } as IMyBox,
          }
        : { ...newState, boxesDashboard: newboxesDashboard };
    }
    case CHECK_REALIZATION_KLASS_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        unread_shared_per_class: {
          ...state.unread_shared_per_class,
          [`${action.payload.data.class_id}`]: 0,
        },
      };
    }
    case UPDATE_TEACHER_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: UPDATE_TEACHER_SUCCESS_MESSAGE,
      };
    }
    case UPDATE_TEACHER_FAILURE: {
      return {
        ...newState,
        error: UPDATE_TEACHER_FAILURE_MESSAGE,
        error_field: UPDATE_TEACHER_FAILURE_MESSAGE,
      };
    }
    case UPDATE_TEACHER_PASSWORD_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: UPDATE_TEACHER_PASSWORD_SUCCESS_MESSAGE,
      };
    }
    case RESET_TEACHER_PASSWORD_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: RESET_TEACHER_PASSWORD_SUCCESS_MESSAGE,
      };
    }
    case RESET_TEACHER_PASSWORD_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
        error_field: action.payload.error.field,
      };
    }
    case FETCH_STATUS_RENEWALED_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        unread_notification_count:
          action.payload.data.unread_notification_count,
        unread_shared_per_class: action.payload.data.unread_shared_per_class,
      };
    }
    case FETCH_SUBMITTED_REALIZATIONS_SUCCESS: {
      const {
        realizations,
        meta: { pagy_info },
      } = action.payload.data;
      return {
        ...newState,
        pagy_info,
        realizations: [
          ...(pagy_info.page > 1 ? state.realizations : []),
          ...realizations.map(r => ({
            ...r,
            created_at: new Date(r.created_at),
          })),
        ],
        read_realization_ids: [
          ...(pagy_info.page > 1 ? state.read_realization_ids : []),
          ...realizations.map(r => r.id),
        ],
        success_message: FETCH_SUBMITTED_REALIZATIONS_SUCCESS_MESSAGE,
      };
    }
    case FETCH_SUBMITTED_REALIZATIONS_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
        error_field: action.payload.error.field,
      };
    }
    case GEARBOX_HIDDEN_SUCCESS: {
      const { id, is_hidden } = action.payload.data;
      return {
        ...newState,
        boxesDashboard: newState.boxesDashboard.map(box =>
          box.id === id ? { ...box, is_hidden } : box,
        ),
        gearboxes: newState.gearboxes.map(box =>
          box.id === id ? { ...box, is_hidden } : box,
        ),
        success_message: GEARBOX_HIDDEN_SUCCESS_MESSAGE,
      };
    }
    case GEARBOX_HIDDEN_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
        error_field: action.payload.error.field,
      };
    }

    case UPDATE_TEACHER:
    case RESET_TEACHER_PASSWORD:
    case UPDATE_TEACHER_PASSWORD:
    case CREATE_FEEDBACK:
    case UPDATE_FEEDBACK:
    case DELETE_FEEDBACK:
    case CREATE_COMMENT:
    case UPDATE_COMMENT:
    case DELETE_COMMENT:
    case MAIL_PASSWORD_LIST:
    case CREATE_GEARBOX:
    case UPDATE_GEARBOX:
    case DELETE_GEARBOX:
    case FETCH_READ_REALIZATIONS:
    case BULK_CHECK_REALIZATIONS: {
      return {
        ...newState,
      };
    }

    case FETCH_READ_REALIZATIONS_SUCCESS: {
      const {
        realizations,
        meta: { pagy_info },
      } = action.payload.data;
      return {
        ...newState,
        pagy_info,
        read_realizations: [
          ...(pagy_info.page > 1 ? state.read_realizations : []),
          ...realizations.map(r => ({
            ...r,
            created_at: new Date(r.created_at),
          })),
        ],
        read_realization_ids: [],
        success_message: FETCH_READ_REALIZATIONS_SUCCESS_MESSAGE,
      };
    }

    case FETCH_READ_REALIZATIONS_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
        error_field: action.payload.error.field,
      };
    }
    case FETCH_GEARBOXES_SUCCESS: {
      const data = action.payload.data;
      return {
        ...newState,
        gearboxes: data.gearboxes,
      };
    }
    case FETCH_GEARBOXES_BY_STUDENT_SUCCESS: {
      const { gearboxes } = action.payload.data;
      return {
        ...newState,
        gearboxesByStudent: gearboxes,
      };
    }
    case CREATE_GEARBOX_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: CREATE_GEARBOX_SUCCESS_MESSAGE,
      };
    }
    case UPDATE_GEARBOX_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: UPDATE_GEARBOX_SUCCESS_MESSAGE,
      };
    }
    case DELETE_GEARBOX_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: DELETE_GEARBOX_SUCCESS_MESSAGE,
      };
    }
    case CREATE_TEACHER_GEARBOX_DIRECTORY_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: CREATE_TEACHER_GEARBOX_DIRECTORY_SUCCESS_MESSAGE,
      };
    }
    case UPDATE_TEACHER_GEARBOX_DIRECTORY_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: UPDATE_TEACHER_GEARBOX_DIRECTORY_SUCCESS_MESSAGE,
      };
    }
    case DELETE_TEACHER_GEARBOX_DIRECTORY_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: DELETE_TEACHER_GEARBOX_DIRECTORY_SUCCESS_MESSAGE,
      };
    }
    case FETCH_TEACHER_GEARBOX_DIRECTORIES_SUCCESS: {
      const data = action.payload.data;
      return {
        ...newState,
        teacherGearboxDirectories: data.teacher_gearbox_directories,
      };
    }
    case FETCH_SHARED_GEARBOX_TEMPLATES_SUCCESS: {
      const data = action.payload.data;
      return {
        ...newState,
        shared_gearbox_templates: data.gearbox_templates,
      };
    }
    case FETCH_MY_GEARBOX_TEMPLATES_SUCCESS: {
      const data = action.payload.data;
      return {
        ...newState,
        my_gearbox_templates: data.gearbox_templates,
      };
    }
    case CREATE_GEARBOX_TEMPLATE_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: CREATE_GEARBOX_TEMPLATE_SUCCESS_MESSAGE,
      };
    }
    case UPDATE_GEARBOX_TEMPLATE_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: UPDATE_GEARBOX_TEMPLATE_SUCCESS_MESSAGE,
      };
    }
    case REORDER_GEARBOX_TEMPLATES_SUCCESS: {
      return { ...newState, error: "", error_field: "" };
    }
    case REORDER_GEARBOX_TEMPLATES_FAILURE: {
      return { ...newState, error: action.payload.error.message };
    }
    case DELETE_GEARBOX_TEMPLATE_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: DELETE_GEARBOX_TEMPLATE_SUCCESS_MESSAGE,
      };
    }
    case DELETE_GEARBOX_TEMPLATE_FAILURE: {
      return { ...newState, error: action.payload.error.message };
    }
    case FETCH_GEARBOX_STUDENTS: {
      return {
        ...newState,
        realizations_count_of_left_students: 0,
        gearboxStudents: [],
      };
    }
    case FETCH_GEARBOX_STUDENTS_SUCCESS: {
      const { realizations_count_of_left_students, students } =
        action.payload.data;
      return {
        ...newState,
        realizations_count_of_left_students,
        gearboxStudents: students,
      };
    }
    case FETCH_GEARBOX_REALIZATIONS: {
      const { page } = action.payload.request.data;
      if (page >= 2) {
        return {
          ...newState,
        };
      }
      return {
        ...newState,
        pagy_info: {
          count: 0,
          items: 0,
          page: 0,
          pages: 0,
        },
        selected_gearbox: null,
        realizations: [],
      };
    }
    case FETCH_GEARBOX_REALIZATIONS_SUCCESS: {
      const {
        gearbox,
        realizations,
        meta: { pagy_info },
      } = action.payload.data;
      return {
        ...newState,
        pagy_info,
        success_message: FETCH_GEARBOX_REALIZATIONS_SUCCESS_MESSAGE,
        selected_gearbox: { ...gearbox, realizations_count: pagy_info.count },
        realizations: [
          ...(pagy_info.page > 1 ? state.realizations : []),
          ...realizations.map(r => ({
            ...r,
            created_at: new Date(r.created_at),
          })),
        ],
      };
    }
    case FETCH_MY_BOX_SUMMARY: {
      return {
        ...newState,
        my_box: null,
      };
    }
    case FETCH_MY_BOX_SUMMARY_SUCCESS: {
      const { summary_submitted } = action.payload.data;
      return {
        ...newState,
        my_box: {
          ...summary_submitted,
          latest_realization_created_at:
            summary_submitted.latest_realization_created_at
              ? new Date(summary_submitted.latest_realization_created_at)
              : null,
        },
      };
    }
    case FETCH_MY_BOX_REALIZATIONS: {
      const { page } = action.payload.request.data;
      if (page >= 2) {
        return {
          ...newState,
        };
      }
      return {
        ...newState,
        pagy_info: {
          count: 0,
          items: 0,
          page: 0,
          pages: 0,
        },
        realizations: [],
      };
    }
    case FETCH_MY_BOX_REALIZATIONS_SUCCESS: {
      const {
        realizations,
        meta: { pagy_info },
      } = action.payload.data;
      return {
        ...newState,
        pagy_info,
        realizations: [
          ...(pagy_info.page > 1 ? state.realizations : []),
          ...realizations.map(r => ({
            ...r,
            created_at: new Date(r.created_at),
          })),
        ],
      };
    }
    case FETCH_CLASSES_INCLUDE_PAST_SUCCESS: {
      return { ...newState, classes: action.payload.data.classes };
    }
    case FETCH_TEACHERS_SUCCESS: {
      return { ...newState, teachers: action.payload.data.teachers };
    }
    case FETCH_BOXES_DASHBOARD: {
      return { ...newState };
    }
    case FETCH_BOXES_DASHBOARD_SUCCESS: {
      return {
        ...newState,
        boxesDashboard: action.payload.data.gearboxes.map(
          ({ latest_realization_created_at, ...rest }) => ({
            ...rest,
            latest_realization_created_at: latest_realization_created_at
              ? new Date(latest_realization_created_at)
              : null,
          }),
        ),
        success_message: FETCH_BOXES_DASHBOARD_SUCCESS_MESSAGE,
      };
    }
    case FETCH_BOXES_DASHBOARD_FAILURE:
    case CREATE_GEARBOX_FAILURE:
    case UPDATE_GEARBOX_FAILURE:
    case DELETE_GEARBOX_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
        error_field: action.payload.error.field,
      };
    }
    case FETCH_REALIZATION_KLASSES: {
      return { ...newState };
    }
    case FETCH_REALIZATION_KLASSES_SUCCESS: {
      const {
        realization_klasses,
        meta: { pagy_info },
      } = action.payload.data;
      return {
        ...newState,
        pagy_info,
        realization_klasses: [
          ...(pagy_info.page > 1 ? state.realization_klasses : []),
          ...realization_klasses.map(e => ({
            ...e,
            created_at: new Date(e.created_at),
          })),
        ],
        success_message: FETCH_REALIZATION_KLASSES_SUCCESS_MESSAGE,
      };
    }

    case FETCH_REALIZATION_KLASSES_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
        error_field: action.payload.error.field,
      };
    }
    case FETCH_REALIZATION_SUCCESS: {
      const realization = action.payload.data.realization;
      return {
        ...newState,
        realization: {
          ...realization,
          created_at: new Date(realization.created_at),
        },
        success_message: READ_REALIZATION_SUCCESS_MESSAGE,
      };
    }
    case FETCH_GEAR_THEME_REALIZATION_SUCCESS: {
      const { geartheme, has_next } = action.payload.data;
      const { prev_id } = action.payload.params;
      return {
        ...newState,
        realizations: !prev_id
          ? geartheme.realizations
          : [
              ...newState.realizations,
              ...geartheme.realizations.map(e => ({
                ...e,
                created_at: new Date(e.created_at),
              })),
            ],
        geartheme_pagination: {
          has_next,
        },
        success_message: FETCH_GEAR_THEME_REALIZATION_SUCCESS_MESSAGE,
      };
    }
    case FETCH_REPORTS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        report_entries: data.reports.map(r => ({
          ...r,
          created_at: new Date(r.created_at),
          last_reported_at: new Date(r.last_reported_at),
        })),
      };
    }
    case CREATE_COMMENT_SUCCESS: {
      const realizationId = action.payload.data.realization_id;
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: CREATE_COMMENT_SUCCESS_MESSAGE,
        realization_klasses: newState.realization_klasses.map(
          realization_klass => ({
            ...realization_klass,
            comment_count:
              realization_klass.realization.id === realizationId
                ? realization_klass.comment_count + 1
                : realization_klass.comment_count,
            has_comment:
              realization_klass.realization.id === realizationId
                ? true
                : realization_klass.has_comment,
            realization: {
              ...realization_klass.realization,
              has_my_comment:
                realization_klass.realization.has_my_comment ||
                realization_klass.realization.id === realizationId,
            },
          }),
        ),
        realizations: state.realizations.map(realization => ({
          ...realization,
          comment_count:
            realization.id === realizationId
              ? (realization.comment_count || 0) + 1
              : realization.comment_count,
          has_comment:
            realization.id === realizationId ? true : realization.has_comment,
          has_my_comment:
            realization.has_my_comment || realization.id === realizationId,
        })),
        realization: {
          ...newState.realization,
          has_my_comment:
            newState.realization.has_my_comment ||
            newState.realization.id === realizationId,
        },
      };
    }
    case CREATE_COMMENT_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
        error_field: action.payload.error.field,
      };
    }
    case UPDATE_COMMENT_SUCCESS: {
      return {
        ...newState,
        success_message: UPDATE_COMMENT_SUCCESS_MESSAGE,
      };
    }
    case UPDATE_COMMENT_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
      };
    }
    case DELETE_COMMENT_SUCCESS: {
      const { realization_id, has_my_comment } = action.payload.data;
      return {
        ...newState,
        realization_klasses: newState.realization_klasses.map(
          realization_klass => ({
            ...realization_klass,
            comment_count:
              realization_klass.realization.id === realization_id
                ? realization_klass.comment_count - 1
                : realization_klass.comment_count,
            has_comment:
              realization_klass.realization.id === realization_id
                ? realization_klass.comment_count - 1 === 0
                  ? false
                  : true
                : realization_klass.has_comment,
            realization: {
              ...realization_klass.realization,
              has_my_comment:
                realization_klass.realization.id === realization_id
                  ? has_my_comment
                  : realization_klass.realization.has_my_comment,
            },
          }),
        ),
        realizations: state.realizations.map(realization => ({
          ...realization,
          comment_count:
            realization.id === realization_id
              ? realization.comment_count! - 1
              : realization.comment_count,
          has_comment:
            realization.id === realization_id
              ? realization.comment_count! - 1 === 0
                ? false
                : true
              : realization.has_comment,
          has_my_comment:
            realization.id === realization_id
              ? has_my_comment
              : realization.has_my_comment,
        })),
        realization: {
          ...newState.realization,
          has_my_comment:
            newState.realization.id === realization_id
              ? has_my_comment
              : newState.realization.has_my_comment,
        },
        error: "",
        error_field: "",
        success_message: DELETE_COMMENT_SUCCESS_MESSAGE,
      };
    }
    case DELETE_COMMENT_FAILURE: {
      return { ...newState, error: action.payload.error.message };
    }
    case MAIL_PASSWORD_LIST_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: MAIL_PASSWORD_LIST_SUCCESS_MESSAGE,
      };
    }
    case CREATE_FEEDBACK_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: CREATE_FEEDBACK_SUCCESS_MESSAGE,
      };
    }
    case CREATE_FEEDBACK_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
        error_field: action.payload.error.field,
      };
    }
    case UPDATE_FEEDBACK_SUCCESS: {
      return {
        ...newState,
        success_message: UPDATE_FEEDBACK_SUCCESS_MESSAGE,
      };
    }
    case UPDATE_FEEDBACK_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
      };
    }
    case DELETE_FEEDBACK_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: DELETE_FEEDBACK_SUCCESS_MESSAGE,
      };
    }
    case DELETE_FEEDBACK_FAILURE: {
      return { ...newState, error: action.payload.error.message };
    }
    case FETCH_COMMENTS_SUCCESS: {
      return {
        ...newState,
        comments: action.payload.data.comments.map(c => ({
          ...c,
          created_at: new Date(c.created_at),
          updated_at: new Date(c.updated_at),
        })),
      };
    }
    case FETCH_SUMMARY_PER_CLASS_STUDENTS_SUCCESS: {
      return {
        ...newState,
        class_students_summaries:
          action.payload.data.class_students_summaries.map(s => ({
            ...s,
            frequency_date_time_unix: new Date(s.frequency_date_time).getTime(),
          })),
      };
    }
    case UPDATE_STUDENT_DETAIL_MODAL: {
      const { student_detail_modal } = action.payload.data;
      return {
        ...newState,
        student_detail_modal,
      };
    }
    case FETCH_REALIZATIONS_CSV_DATA: {
      return {
        ...newState,
        realizations_csv_data: null,
      };
    }
    case FETCH_REALIZATIONS_CSV_DATA_SUCCESS: {
      const { school, klass, realizations } = action.payload.data;
      return {
        ...newState,
        realizations_csv_data: {
          school,
          klass,
          realizations: realizations.map(r => ({
            ...r,
            roots_created_at: r.roots_created_at
              ? new Date(r.roots_created_at)
              : null,
            executed_at: r.executed_at ? new Date(r.executed_at) : null,
            origin_realization: r.origin_realization
              ? {
                  ...r.origin_realization,
                  created_at: new Date(r.origin_realization.created_at),
                }
              : null,
          })),
        },
      };
    }
    case FETCH_REALIZATIONS_CSV_DATA_FAILURE:
    case FETCH_SUMMARY_PER_CLASS_STUDENTS_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
        error_field: action.payload.error.field,
      };
    }
    case FETCH_FEEDBACKS_SUCCESS: {
      return {
        ...newState,
        feedbacks: action.payload.data.feedbacks.map(c => ({
          ...c,
          created_at: new Date(c.created_at),
          updated_at: new Date(c.updated_at),
        })),
      };
    }
    case CHECKED_TEACHER_SUCCESS: {
      const realizationID = action.payload.data.realization_id;
      return {
        ...newState,
        success_message: TOGGLE_CHECKED_SUCCESS_MESSAGE,
        realizations: state.realizations.map(realization =>
          realization.id === realizationID
            ? { ...realization, is_checked: true }
            : realization,
        ),
        realization:
          state.realization.id === realizationID
            ? { ...state.realization, is_checked: true }
            : state.realization,
      };
    }
    case UNCHECKED_TEACHER_SUCCESS: {
      const realizationID = action.payload.data.realization_id;
      return {
        ...newState,
        success_message: TOGGLE_CHECKED_SUCCESS_MESSAGE,
        realizations: state.realizations.map(realization =>
          realization.id === realizationID
            ? { ...realization, is_checked: false }
            : realization,
        ),
        realization:
          state.realization.id === realizationID
            ? { ...state.realization, is_checked: false }
            : state.realization,
      };
    }
    case LOGOUT:
      return { ...InitialAppState };
    case LOGOUT_SUCCESS:
      return { ...InitialAppState, success_message: LOGOUT_SUCCESS_MESSAGE };
    case CLEAR_SUCCESS_MESSAGE:
      return { ...newState, success_message: "" };
    case CLEAR_ERROR_MESSAGE:
      return { ...newState, error: "", error_field: "" };
    case CLEAR_REALIZATIONS:
      return { ...newState, realizations: [] };
    case CLEAR_SELECTED_GEARBOX:
      return { ...newState, selected_gearbox: null };
    case CLEAR_READ_REALIZATIONS:
      return {
        ...newState,
        read_realizations: [],
      };
    case CLEAR_ANALYZE_REALIZATIONS:
      return {
        ...newState,
        realizations_analyze: [],
      };
    case CLEAR_ANALYZE_STUDENT:
      return {
        ...newState,
        student_analyze: null,
      };
    case CLEAR_GEARTHEME_PAGINATION:
      return {
        ...newState,
        geartheme_pagination: {
          has_next: false,
        },
      };
    case CLEAR_REALIZATION: {
      return {
        ...newState,
        realization: {
          id: 0,
          student_id: 0,
          categories: [],
          content: "",
          kind: "roots",
          submitted: false,
          shared: false,
          executed_at: new Date(),
          created_at: new Date(),
          read_last_comment_id: 0,
          read_last_feedback_id: 0,
          gearbox_id: null,
          image: null,
          gearbox: null,
          geartheme: null,
        },
      };
    }
    case CLEAR_COMMENTS:
      return { ...newState, comments: [] };
    case CLEAR_FEEDBACKS:
      return { ...newState, feedbacks: [] };
    case CLEAR_REALIZATION_KLASSES:
      return {
        ...newState,
        realization_klasses: [],
      };
    case CLEAR_PAGY_INFO:
      return {
        ...newState,
        pagy_info: {
          count: 0,
          items: 0,
          page: 0,
          pages: 0,
        },
      };
    case CLEAR_REALIZATIONS_ANALYZE_PAGY_INFO:
      return {
        ...newState,
        realizations_analyze_pagy_info: {
          count: 0,
          items: 0,
          page: 0,
          pages: 0,
        },
      };
    case CLEAR_OTT: {
      return { ...newState, ott: "" };
    }
    case REORDER_BOXES_SUCCESS: {
      return { ...newState, error: "", error_field: "" };
    }
    case REORDER_BOXES_FAILURE: {
      return { ...newState, error: action.payload.error.message };
    }
    case REORDER_TEACHER_GEARBOX_DIRECTORIES_SUCCESS: {
      return { ...newState, error: "", error_field: "" };
    }
    case REORDER_TEACHER_GEARBOX_DIRECTORIES_FAILURE: {
      return { ...newState, error: action.payload.error.message };
    }
    case ACTION_503:
      return {
        ...newState,
        isServiceUnavailable: true,
        success_message: ACTION_503_MESSAGE,
      };
    default:
      return newState;
  }
};

type ContextState = {
  contextState: AppState;
  dispatch(action: Actions): void;
};

export const Context = React.createContext<ContextState>({
  contextState: InitialAppState,
  dispatch(_) {
    console.warn("Context.Provider外からの呼び出し");
  },
});
