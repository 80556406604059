import React from "react";
import clsx from "clsx";
import {
  IonContent,
  IonIcon,
  IonLabel,
  IonItem,
  IonReorderGroup,
  IonReorder,
  IonButton,
} from "@ionic/react";
import {
  addCircleOutline as addIcon,
  removeCircleOutline as removeIcon,
  eyeOutline as visibleIcon,
  eyeOffOutline as hiddenIcon,
} from "ionicons/icons";

import {
  ITeacherGearbox,
  ITeacher,
  CreateGearboxParams,
  UpdateGearboxParams,
  TNameStatus,
  TeacherGearboxDirectory,
  GearboxTemplate,
} from "../../../state";
import boxIcon from "../../../assets/icons/box.svg";
import BoxTitle from "../../../atoms/BoxTitle";
import BoxForm from "../../../molecules/BoxForm";
import ModalConfirm from "../../../molecules/ModalConfirm";

import styles from "./ManageBoxesMenu.module.scss";

type ManageBoxesMenuProps = {
  calling: boolean;
  teacher: ITeacher;
  teacherGearboxDirectories: TeacherGearboxDirectory[];
  gearboxes: ITeacherGearbox[];
  templates: GearboxTemplate[];
  editable: boolean;
  gearboxHidden: (id: number) => void;
  createGearbox: (args: CreateGearboxParams) => void;
  updateGearbox: (args: UpdateGearboxParams) => void;
  deleteGearbox: (id: number) => void;
  createTemplate: () => void;
  update: (args: { [key: string]: any }) => void;
};

const ManageBoxesMenu: React.FC<ManageBoxesMenuProps> = ({
  calling,
  teacher,
  gearboxes,
  teacherGearboxDirectories,
  editable,
  gearboxHidden,
  templates,
  createGearbox,
  updateGearbox,
  deleteGearbox,
  createTemplate,
  update,
}) => {
  const [state, setState] = React.useState<{
    teacherGearboxDirectories: TeacherGearboxDirectory[];
    showBoxForm: boolean;
    showDeleteBoxConfirm: boolean;
    gearboxID: number;
    gearboxTitle: string;
    gearboxTemplateId: number | null;
    classID: number;
    teacherGearboxDirectoryID: number;
    nameStatus: TNameStatus;
    is_archived: boolean;
  }>({
    showBoxForm: false,
    showDeleteBoxConfirm: false,
    gearboxID: 0,
    teacherGearboxDirectoryID: 0,
    gearboxTitle: "",
    gearboxTemplateId: null,
    classID: 0,
    nameStatus: "publish",
    is_archived: false,
    teacherGearboxDirectories: [],
  });

  function doReorder(event: CustomEvent) {
    const boxes = event.detail.complete(gearboxes);
    update({
      boxes,
    });
  }

  return (
    <IonContent className={styles.wrapper}>
      <ModalConfirm
        loading={calling}
        isOpen={state.showDeleteBoxConfirm}
        message={
          <>
            「{state.gearboxTitle}」を削除しますか？
            <br />
            BOXに提出されたストックは削除されません。
            <br />
            また、この操作は取り消しができません。
          </>
        }
        buttons={[
          {
            title: "キャンセル",
            type: "None",
            action: () =>
              setState(s => ({ ...s, showDeleteBoxConfirm: false })),
          },
          {
            title: "削除する",
            type: "Danger",
            action: () => {
              deleteGearbox(state.gearboxID);
              setState(s => ({ ...s, showDeleteBoxConfirm: false }));
            },
          },
        ]}
      />
      <BoxForm
        calling={calling}
        gearboxes={gearboxes}
        isOpen={state.showBoxForm}
        classes={teacher.classes}
        teacherGearboxDirectories={teacherGearboxDirectories}
        defaultTitle={state.gearboxTitle}
        defaultTemplateId={state.gearboxTemplateId}
        id={state.gearboxID}
        classID={state.classID}
        teacherGearboxDirectoryID={state.teacherGearboxDirectoryID}
        nameStatus={state.nameStatus}
        is_archived={state.is_archived}
        templates={templates}
        createTemplate={() => {
          setState(s => ({
            ...s,
            showBoxForm: false,
          }));
          // BoxFormが閉じるのを待つ
          setTimeout(() => {
            createTemplate();
          }, 200);
        }}
        ok={gearbox => {
          if (state.gearboxID) {
            updateGearbox({
              id: gearbox.id,
              title: gearbox.title,
              teacher_gearbox_directory_id:
                gearbox.teacher_gearbox_directory_id,
              name_status: gearbox.name_status,
              is_archived: gearbox.is_archived,
              gearbox_template_id: gearbox.gearbox_template_id,
            });
          } else {
            createGearbox({
              title: gearbox.title,
              class_ids: [gearbox.class_id],
              teacher_gearbox_directory_id:
                gearbox.teacher_gearbox_directory_id,
              name_status: gearbox.name_status,
              is_archived: gearbox.is_archived,
              gearbox_template_id: gearbox.gearbox_template_id,
            });
          }
          setState(s => ({
            ...s,
            showBoxForm: false,
          }));
        }}
        close={() => {
          /*
            setStateの多重実行防止
            別の箇所からshowBoxForm: falseにしたタイミングで動的に実行されてしまうため
            BoxForm内で利用しているIonModalのonDidDismissからcloseを参照しているのが原因っぽい
          */
          if (state.showBoxForm) {
            setState({ ...state, showBoxForm: false });
          }
        }}
      />
      <IonItem lines="full" className={styles.boxesBar}>
        <IonIcon src={boxIcon} slot="start" className={styles.boxIcon} />
        <IonLabel className={styles.boxesLabel} color="light">
          BOXを新規作成
        </IonLabel>
        <IonButton
          className={styles.addButton}
          disabled={calling}
          slot="end"
          fill="clear"
          size="large"
          onClick={() => {
            setState(s => ({
              ...s,
              gearboxID: 0,
              gearboxTitle: "",
              gearboxPreset: "",
              classID: 0,
              teacherGearboxDirectoriesID: 0,
              nameStatus: "publish",
              is_archived: false,
              showBoxForm: true,
            }));
            update({
              editable: false,
            });
          }}
        >
          <IonIcon icon={addIcon} />
        </IonButton>
      </IonItem>
      <IonReorderGroup
        disabled={!editable || calling}
        onIonItemReorder={doReorder}
      >
        {gearboxes &&
          gearboxes.map((b, i) => {
            const classLabel = b.klasses?.map(cls => cls.full_name).join(", ");
            return (
              <IonItem
                key={b.id}
                className={clsx(
                  styles.boxItem,
                  b.is_hidden && styles.hiddenBoxItem,
                )}
                lines={i + 1 === gearboxes.length ? "none" : "inset"}
              >
                {editable && (
                  <IonButton
                    className={styles.removeButton}
                    disabled={calling}
                    slot="start"
                    fill="clear"
                    size="large"
                    onClick={() => {
                      setState(s => ({
                        ...s,
                        gearboxID: b.id,
                        gearboxTitle: b.title,
                        gearboxTemplateId: b.gearbox_template_id,
                        classID: b.class_id,
                        teacherGearboxDirectoriesID:
                          b.teacher_gearbox_directory_id,
                        nameStatus: b.name_status ?? "unpublish",
                        is_archived: b.is_archived,
                        showDeleteBoxConfirm: true,
                      }));
                    }}
                  >
                    <IonIcon icon={removeIcon} />
                  </IonButton>
                )}
                <IonLabel
                  className={clsx(
                    styles.boxItemLabel,
                    editable && styles.pointer,
                  )}
                  onClick={() => {
                    if (editable) {
                      setState(s => ({
                        ...s,
                        gearboxID: b.id,
                        gearboxTitle: b.title,
                        gearboxTemplateId: b.gearbox_template_id,
                        classID: b.class_id,
                        teacherGearboxDirectoriesID:
                          b.teacher_gearbox_directory_id,
                        nameStatus: b.name_status ?? "unpublish",
                        is_archived: b.is_archived,
                        showBoxForm: true,
                      }));
                    }
                  }}
                >
                  <div className={styles.boxTitle}>
                    <BoxTitle
                      isArchived={b.is_archived}
                      nameStatus={b.name_status}
                      title={b.title}
                      gearboxDirectoryId={b.teacher_gearbox_directory_id}
                      teacherGearboxDirectories={teacherGearboxDirectories}
                    />
                  </div>
                  {classLabel && (
                    <div className={styles.classLabel}>{classLabel}</div>
                  )}
                </IonLabel>
                {editable && (
                  <IonButton fill="clear" onClick={() => gearboxHidden(b.id)}>
                    <IonIcon icon={b.is_hidden ? hiddenIcon : visibleIcon} />
                  </IonButton>
                )}
                <IonReorder slot="end" className={styles.reorderIcon} />
              </IonItem>
            );
          })}
      </IonReorderGroup>
    </IonContent>
  );
};

export default ManageBoxesMenu;
