import { IonButton, IonInput, IonLabel } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router-dom";

import { homePath } from "../../../store/teacher";
import { emailRegex } from "../../../constants/regex";
import Toast from "../../../molecules/Toast";

import styles from "./ResetPasswordForm.module.scss";

export interface ResetPasswordFormProps {
  calling: boolean;
  error: string;
  resetTeacherPassword: (email: string) => void;
}

const validates: { [key: string]: (value: any) => string } = {};

validates["email"] = val => {
  return val.email.match(emailRegex)
    ? ""
    : "正しいメールアドレスを入力してください。";
};

export const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const [state, setState] = React.useState({
    email: "",
    isSubmitted: false,
    error: props.error,
    errorMessages: {} as { [key: string]: string },
  });
  const history = useHistory();

  React.useEffect(() => {
    setState(s => ({ ...s, error: props.error }));
  }, [props.error]);

  const onSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const errorMessages = {} as { [key: string]: string };
      Object.keys(validates).forEach(key => {
        const ret = validates[key](state);
        if (ret) {
          errorMessages[key] = ret;
        }
      });
      if (Object.keys(errorMessages).length > 0) {
        setState(s => ({ ...s, errorMessages }));
        return;
      }
      props.resetTeacherPassword(state.email);
    },
    [state, props],
  );

  const onChange = React.useCallback((ev: any) => {
    const e = ev as React.SyntheticEvent<HTMLInputElement>;
    e.preventDefault();
    const val = e.currentTarget.value;
    const key = e.currentTarget.name;
    const args = { [key]: val };
    const ret = validates[key](args);
    setState(prevState => ({
      ...prevState,
      [key]: val,
      errorMessages: { ...prevState.errorMessages, [key]: ret },
    }));
  }, []);

  return (
    <div className={styles.wrapper}>
      <Toast
        type="danger"
        showToast={!!state.error}
        onClose={() => setState({ ...state, error: "" })}
        message={state.error}
      />
      <div className={styles.title}>パスワードリセット</div>
      <div className={styles.description}>
        以下のフォームにご登録のメールアドレスを入力してください。
      </div>
      <div className={styles.description}>
        入力したメールアドレス宛にパスワードリセット用のメールが送信されます。
      </div>
      <form className="ion-padding" onSubmit={onSubmit}>
        <div className={styles.inputArea}>
          <IonLabel color="light">Email</IonLabel>
          <IonInput
            className={styles.input}
            name="email"
            type="text"
            onIonChange={onChange}
          />
        </div>
        <div className={styles.invalidFeedback}>
          {state.errorMessages["email"]}
        </div>
        <IonButton
          className={styles.sendButton}
          expand="block"
          onClick={onSubmit}
          disabled={
            Object.keys(state.errorMessages).filter(k => state.errorMessages[k])
              .length > 0 || props.calling
          }
        >
          送信
        </IonButton>
        <div className={styles.backToLogin}>
          <span
            className={styles.text}
            onClick={() => {
              history.push(`${homePath}`);
            }}
          >
            ログイン画面へ戻る
          </span>
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
