import { IonButton } from "@ionic/react";
import React, { Dispatch, SetStateAction } from "react";

import { IComment, IFeedback } from "../../state";

import styles from "./CommentConfig.module.scss";

export interface CommentConfigProps {
  calling?: boolean;
  comment: IComment | IFeedback;
  delete: (comment_id: number) => void;
  update: (args: { [key: string]: any }) => void;
  updateShowCommentConfigID: Dispatch<SetStateAction<number>>;
}

const CommentConfig = (props: CommentConfigProps) => {
  return (
    <div className={styles.commentConfigArea}>
      <IonButton
        className={styles.button}
        onClick={() => navigator.clipboard.writeText(props.comment.content)}
      >
        コピー
      </IonButton>
      <IonButton
        className={`${styles.button} ${styles.border}`}
        disabled={props.calling}
        onClick={() => {
          props.delete(props.comment.id);
        }}
      >
        削除
      </IonButton>
      <IonButton
        className={styles.button}
        disabled={props.calling}
        onClick={() => {
          props.update({
            editTargetID: props.comment.id,
            content: props.comment.content,
          });
          props.updateShowCommentConfigID(0);
        }}
      >
        編集
      </IonButton>
    </div>
  );
};

export default CommentConfig;
