export const BOX_SORT_DATA = [
  {
    label: "提出日が新しい順",
    value: "latest_realization_created_at",
  },
  {
    label: "ストックが多い順",
    value: "realizations_count",
  },
  {
    label: "いいねが多い順",
    value: "favorites_count",
  },
  {
    label: "コメントが多い順",
    value: "comments_count",
  },
];

export enum EOrderBy {
  LATEST_REALIZATION_CREATED_AT = "latest_realization_created_at",
  REALIZATION_COUNT = "realizations_count",
  FAVORITE_COUNT = "favorites_count",
  COMMENTS_COUNT = "comments_count",
}
