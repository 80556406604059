import React, { useRef } from "react";

import RealizationItem from "../../../molecules/teacher/RealizationItem";
import {
  IOnClickStudentAvatarParams,
  IRealization,
  ITimelinePagination,
} from "../../../state";
import useIntersection from "../../../hooks/useIntersection";

import styles from "./TimelineTheme.module.scss";

export interface TimelineThemeProps {
  isDisplaying: boolean;
  calling: boolean;
  realizations: IRealization[];
  geartheme_pagination: ITimelinePagination;
  fetchRealization: () => void;
  onAction: (realizationId: number) => void;
  showStudentDetail: (params: IOnClickStudentAvatarParams) => void;
}

const TimelineTheme = ({
  isDisplaying,
  calling,
  realizations,
  geartheme_pagination,
  fetchRealization,
  onAction,
  showStudentDetail,
}: TimelineThemeProps) => {
  const loadMoreRef = useRef<HTMLDivElement>(null);

  useIntersection({
    target: loadMoreRef,
    enabled:
      isDisplaying &&
      !calling &&
      !!realizations.length &&
      geartheme_pagination.has_next,
    onIntersect: () => fetchRealization(),
  });

  return (
    <div className={styles.wrapper}>
      {realizations.map(realization => (
        <RealizationItem
          key={realization.id}
          realization={realization}
          hideReactions
          onClick={() => onAction(realization.id)}
          onAvatarClick={showStudentDetail}
        />
      ))}
      {realizations.length > 0 && (
        <div className={styles.loadMore} ref={loadMoreRef}>
          {calling ? "読み込み中" : ""}
        </div>
      )}
    </div>
  );
};

export default TimelineTheme;
