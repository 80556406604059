import {
  IonContent,
  IonPage,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import React from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import MenuHeader from "../../../organisms/teacher/Header/MenuHeader";
import BoxDashboard, {
  IBoxesDashboardParams,
} from "../../../templates/teacher/BoxDashboard";
import {
  Context,
  sharedRealizationPath,
  FETCH_BOXES_DASHBOARD_SUCCESS_MESSAGE,
  clearSuccessMessage,
  fetchGearboxRealizations,
  fetchGearboxStudents,
  updateStudentDetailModal,
  fetchMyBoxRealizations,
  fetchMyBoxSummary,
  bulkCheckRealizations,
  gearboxHidden,
  fetchTeacherGearboxDirectories,
} from "../../../store/teacher";
import {
  fetchClassesIncludePast,
  fetchTeachers,
  fetchBoxesDashboard,
} from "../../../store/teacher";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import useDebounceCallback from "../../../hooks/useDebounceCallback";
import { EOrderBy } from "../../../constants/boxDashboard";
import styles from "../common.module.scss";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

const FILTERING_KEY = "filtering";
const DETAIL_KEY = "show_detail";

const BoxDashboardPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const history = useHistory();
  const [calling, peep] = useWaitApiCall(contextState);
  const queryString = React.useMemo(
    () => history.location.search,
    [history.location.search],
  );
  const [isDisplaying, setIsDisplaying] = React.useState(false);
  const [state, setState] = React.useState({
    filtering: queryString.includes(FILTERING_KEY),
    showDetail: queryString.includes(DETAIL_KEY),
  });

  useIonViewWillEnter(() => {
    setIsDisplaying(true);
    dispatch(fetchClassesIncludePast());
    dispatch(fetchTeachers());
    dispatch(fetchMyBoxSummary());
    setState(s => ({
      ...s,
      filtering: queryString.includes(FILTERING_KEY),
      showDetail: queryString.includes(DETAIL_KEY),
    }));
  }, [queryString]);

  useIonViewWillLeave(() => {
    dispatch(updateStudentDetailModal(null));
    setIsDisplaying(false);
  });

  React.useEffect(() => {
    if (
      contextState.success_message === FETCH_BOXES_DASHBOARD_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
    }
    dispatch(fetchTeacherGearboxDirectories());
  }, [dispatch, contextState.success_message]);

  useDidMountEffect(() => {
    if (state.showDetail) {
      history.replace({
        search: state.filtering ? `${FILTERING_KEY}&${DETAIL_KEY}` : DETAIL_KEY,
      });
    } else {
      history.replace({
        search: state.filtering ? FILTERING_KEY : "",
      });
    }
  }, [history, state.showDetail]);

  const [fetchBoxesDashboardFunc] = useDebounceCallback(
    (params: IBoxesDashboardParams) => {
      dispatch(peep(fetchBoxesDashboard(params)));
      if (
        params.title ||
        params.class_id ||
        params.start_date ||
        params.end_date ||
        params.order_by !== EOrderBy.LATEST_REALIZATION_CREATED_AT
      ) {
        history.replace({
          search: state.showDetail
            ? `${FILTERING_KEY}&${DETAIL_KEY}`
            : FILTERING_KEY,
        });
        setState(s => ({ ...s, filtering: true }));
      } else {
        history.replace({
          search: state.showDetail ? DETAIL_KEY : "",
        });
        setState(s => ({ ...s, filtering: false }));
      }
    },
    500,
  );

  const update = React.useCallback((args: { [key: string]: any }) => {
    setState(prevState => ({ ...prevState, ...args }));
  }, []);

  const fetchGearboxStudentsFunc = React.useCallback(
    (id: number) => {
      dispatch(peep(fetchGearboxStudents(id)));
    },
    [dispatch, peep],
  );

  const fetchRealizationsFunc = React.useCallback(
    (id: number, page?: number) => {
      dispatch(clearSuccessMessage());
      dispatch(peep(fetchGearboxRealizations(id, page ?? 1)));
    },
    [dispatch, peep],
  );

  const fetchMyBoxRealizationsFunc = React.useCallback(
    (page?: number) => {
      dispatch(clearSuccessMessage());
      dispatch(peep(fetchMyBoxRealizations(page ?? 1)));
    },
    [dispatch, peep],
  );

  const toRealizationDetail = React.useCallback(
    realization_id => history.push(`${sharedRealizationPath}${realization_id}`),
    [history],
  );

  const handleBulkCheckRealizations = React.useCallback(
    (gear_box_id: number) => {
      dispatch(peep(bulkCheckRealizations(gear_box_id)));
    },
    [dispatch, peep],
  );

  const showStudentDetailFunc = React.useCallback(
    args => {
      dispatch(updateStudentDetailModal(args));
    },
    [dispatch],
  );

  const gearboxHiddenFunc = React.useCallback(
    (id: number) => {
      dispatch(peep(gearboxHidden(id)));
    },
    [dispatch, peep],
  );

  return (
    <IonPage>
      <MenuHeader
        className={styles.boxDashboardHeader}
        title="提出されたストック"
      />
      <IonContent className={`${styles.wrapper} ${styles.bgWhileBase}`}>
        {contextState.teacher.classes.length > 0 && (
          <div className={clsx(styles.container, styles.transparent)}>
            <BoxDashboard
              isDisplaying={isDisplaying}
              calling={calling}
              filtering={state.filtering}
              showDetail={state.showDetail}
              classes={contextState.classes}
              teachers={contextState.teachers}
              boxList={contextState.boxesDashboard}
              realizations_count_of_left_students={
                contextState.realizations_count_of_left_students
              }
              myBox={contextState.my_box}
              gearboxStudents={contextState.gearboxStudents}
              realizations={contextState.realizations}
              pagy_info={contextState.pagy_info}
              update={update}
              fetchBoxDashboard={fetchBoxesDashboardFunc}
              fetchStudents={fetchGearboxStudentsFunc}
              fetchRealizations={fetchRealizationsFunc}
              fetchMyBoxRealizations={fetchMyBoxRealizationsFunc}
              toRealizationDetail={toRealizationDetail}
              showStudentDetail={showStudentDetailFunc}
              bulkCheckRealizations={handleBulkCheckRealizations}
              gearboxHiddenFunc={gearboxHiddenFunc}
              teacherId={contextState.teacher.id}
              teacherGearboxDirectories={contextState.teacherGearboxDirectories}
            />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default BoxDashboardPage;
