import React from "react";
import clsx from "clsx";
import {
  IonContent,
  IonIcon,
  IonLabel,
  IonItem,
  IonReorderGroup,
  IonReorder,
  IonButton,
} from "@ionic/react";
import {
  addCircleOutline as addIcon,
  removeCircleOutline as removeIcon,
} from "ionicons/icons";

import {
  TeacherGearboxDirectory,
  CreateTeacherGearboxDirectoryParams,
  UpdateTeacherGearboxDirectoryParams,
} from "../../../state";
import folderIcon from "../../../assets/icons/folder.svg";
import TeacherBoxDirectoryForm from "../../../molecules/TeacherBoxDirectoryForm";
import ModalConfirm from "../../../molecules/ModalConfirm";

import styles from "./ManageBoxDirectoriesMenu.module.scss";

type ManageBoxDirectoriesMenuProps = {
  calling: boolean;
  teacherGearboxDirectories: TeacherGearboxDirectory[];
  editable: boolean;
  createTeacherGearboxDirectory: (
    args: CreateTeacherGearboxDirectoryParams,
  ) => void;
  updateTeacherGearboxDirectory: (
    args: UpdateTeacherGearboxDirectoryParams,
  ) => void;
  deleteTeacherGearboxDirectory: (id: number) => void;
  update: (args: { [key: string]: any }) => void;
};

const ManageBoxDirectoriesMenu: React.FC<ManageBoxDirectoriesMenuProps> = ({
  calling,
  teacherGearboxDirectories,
  editable,
  createTeacherGearboxDirectory,
  updateTeacherGearboxDirectory,
  deleteTeacherGearboxDirectory,
  update,
}) => {
  const [state, setState] = React.useState<{
    showTeacherGearboxDirectoryForm: boolean;
    showDeleteTeacherGearboxDirectoryConfirm: boolean;
    teacherGearboxDirectoryID: number;
    teacherGearboxDirectoryName: string;
  }>({
    showTeacherGearboxDirectoryForm: false,
    showDeleteTeacherGearboxDirectoryConfirm: false,
    teacherGearboxDirectoryID: 0,
    teacherGearboxDirectoryName: "",
  });

  function doReorder(event: CustomEvent) {
    const directories = event.detail.complete(teacherGearboxDirectories);
    update({
      directories,
    });
  }

  return (
    <IonContent className={styles.wrapper}>
      <ModalConfirm
        loading={calling}
        isOpen={state.showDeleteTeacherGearboxDirectoryConfirm}
        message={
          <>
            「{state.teacherGearboxDirectoryName}」を削除しますか？
            <br />
            また、この操作は取り消しができません。
          </>
        }
        buttons={[
          {
            title: "キャンセル",
            type: "None",
            action: () =>
              setState(s => ({
                ...s,
                showDeleteTeacherGearboxDirectoryConfirm: false,
              })),
          },
          {
            title: "削除する",
            type: "Danger",
            action: () => {
              deleteTeacherGearboxDirectory(state.teacherGearboxDirectoryID);
              setState(s => ({
                ...s,
                showDeleteTeacherGearboxDirectoryConfirm: false,
              }));
            },
          },
        ]}
      />
      <TeacherBoxDirectoryForm
        calling={calling}
        isOpen={state.showTeacherGearboxDirectoryForm}
        defaultName={state.teacherGearboxDirectoryName}
        id={state.teacherGearboxDirectoryID}
        ok={teacherGearboxDirectory => {
          if (state.teacherGearboxDirectoryID) {
            updateTeacherGearboxDirectory({
              id: teacherGearboxDirectory.id,
              name: teacherGearboxDirectory.name,
            });
          } else {
            createTeacherGearboxDirectory({
              name: teacherGearboxDirectory.name,
            });
          }
          setState(s => ({
            ...s,
            showTeacherGearboxDirectoryForm: false,
          }));
        }}
        close={() =>
          setState({ ...state, showTeacherGearboxDirectoryForm: false })
        }
      />
      <IonItem lines="full" className={styles.boxesBar}>
        <IonIcon src={folderIcon} slot="start" className={styles.boxIcon} />
        <IonLabel className={styles.boxesLabel} color="light">
          フォルダを新規作成
        </IonLabel>
        <IonButton
          className={styles.addButton}
          disabled={calling}
          slot="end"
          fill="clear"
          size="large"
          onClick={() => {
            setState(s => ({
              ...s,
              teacherGearboxDirectoryID: 0,
              teacherGearboxDirectoryName: "",
              showTeacherGearboxDirectoryForm: true,
            }));
            update({
              editable: false,
            });
          }}
        >
          <IonIcon icon={addIcon} />
        </IonButton>
      </IonItem>
      <IonReorderGroup
        disabled={!editable || calling}
        onIonItemReorder={doReorder}
      >
        {teacherGearboxDirectories &&
          teacherGearboxDirectories.map((c, i) => {
            return (
              <IonItem
                key={c.id}
                className={styles.boxItem}
                lines={
                  i + 1 === teacherGearboxDirectories.length ? "none" : "inset"
                }
              >
                {editable && (
                  <IonButton
                    className={styles.removeButton}
                    disabled={calling}
                    slot="start"
                    fill="clear"
                    size="large"
                    onClick={() => {
                      setState(s => ({
                        ...s,
                        teacherGearboxDirectoryID: c.id,
                        teacherGearboxDirectoryName: c.name,
                        showDeleteTeacherGearboxDirectoryConfirm: true,
                      }));
                    }}
                  >
                    <IonIcon icon={removeIcon} />
                  </IonButton>
                )}
                <IonLabel
                  className={clsx(
                    styles.boxItemLabel,
                    editable && styles.pointer,
                  )}
                  onClick={() => {
                    if (editable) {
                      setState(s => ({
                        ...s,
                        teacherGearboxDirectoryID: c.id,
                        teacherGearboxDirectoryName: c.name,
                        showTeacherGearboxDirectoryForm: true,
                      }));
                    }
                  }}
                >
                  {c.name}
                </IonLabel>
                <IonReorder slot="end" className={styles.reorderIcon} />
              </IonItem>
            );
          })}
      </IonReorderGroup>
    </IonContent>
  );
};

export default ManageBoxDirectoriesMenu;
