import React, { useRef } from "react";
import { IonList } from "@ionic/react";

import {
  IRealization,
  ITeacherGearbox,
  IPagyInfo,
  IOnClickStudentAvatarParams,
} from "../../../state";
import RealizationItem from "../../../molecules/teacher/RealizationItem";
import BoxItem from "../../../atoms/BoxItem";
import useIntersection from "../../../hooks/useIntersection";

import styles from "./BoxDetail.module.scss";

export interface BoxDetailProps {
  isDisplaying: boolean;
  calling: boolean;
  gearbox: ITeacherGearbox | null;
  realizations: IRealization[];
  pagy_info: IPagyInfo;
  fetchRealizations: (page?: number) => void;
  onAction: (realizationId: number) => void;
  showStudentDetail: (params: IOnClickStudentAvatarParams) => void;
}

const BoxDetail: React.FC<BoxDetailProps> = ({
  isDisplaying,
  calling,
  gearbox,
  realizations,
  pagy_info,
  fetchRealizations,
  onAction,
  showStudentDetail,
}) => {
  const loadMoreRef = useRef<HTMLDivElement>(null);

  useIntersection({
    target: loadMoreRef,
    enabled: isDisplaying && !calling && pagy_info.page < pagy_info.pages,
    onIntersect: () => fetchRealizations(pagy_info.page + 1),
  });

  return (
    <div className={styles.wrapper}>
      <BoxItem
        className={styles.boxInfo}
        realizationsCount={gearbox?.realizations_count ?? 0}
        isArchived={!!gearbox?.is_archived}
        nameStatus={gearbox?.name_status ?? "unpublish"}
        title={gearbox?.title ?? "ー"}
        boxCreatorName={gearbox?.teacher_name ?? "ー"}
      />
      <IonList className={styles.itemList}>
        {realizations.map(realization => (
          <RealizationItem
            key={realization.id}
            realization={realization}
            hideCheckedStatus
            onClick={() => onAction(realization.id)}
            onAvatarClick={showStudentDetail}
          />
        ))}
        <div className={styles.loadMore} ref={loadMoreRef}>
          {calling ? "読み込み中" : ""}
        </div>
      </IonList>
    </div>
  );
};

export default BoxDetail;
