import React from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { IonIcon, IonItemGroup, useIonViewWillEnter } from "@ionic/react";

import { convertDateToDateString } from "../../../libs/Util";
import Dropdown from "../../../atoms/Dropdown";
import BoxAnalysisItem from "../../../atoms/BoxAnalysisItem";
import DatePicker from "../../../atoms/DatePicker";
import SearchField from "../../../atoms/SearchField";
import BoxDetailModal from "../../../molecules/BoxDetailModal";
import mailIcon from "../../../assets/icons/mail.svg";
import { BOX_SORT_DATA, EOrderBy } from "../../../constants/boxDashboard";
import {
  IClass,
  ITeacher,
  IBoxDashboard,
  IRealization,
  IStudentForGearbox,
  IPagyInfo,
  IOnClickStudentAvatarParams,
  IMyBox,
  TeacherGearboxDirectory,
} from "../../../state";
import { manageBoxesPath } from "../../../store/teacher";
import BoxDirectoryList from "../../../organisms/teacher/BoxDirectoryList";
import { ReactComponent as GoodIcon } from "../../../assets/icons/box_good.svg";
import { ReactComponent as CommentIcon } from "../../../assets/icons/box_comment.svg";
import { ReactComponent as AddIcon } from "../../../assets/icons/box_add.svg";

import styles from "./BoxDashboard.module.scss";

export interface IBoxesDashboardParams {
  title?: string;
  class_id?: number;
  start_date?: Date | null;
  end_date?: Date | null;
  order_by?: EOrderBy;
}

export interface BoxDashboardProps {
  isDisplaying: boolean;
  calling: boolean;
  filtering: boolean;
  showDetail: boolean;
  classes: IClass[];
  teachers: ITeacher[];
  boxList: IBoxDashboard[];
  realizations_count_of_left_students: number;
  myBox: IMyBox | null;
  gearboxStudents: IStudentForGearbox[];
  realizations: IRealization[];
  pagy_info: IPagyInfo;
  teacherId: number;
  teacherGearboxDirectories: TeacherGearboxDirectory[];
  update: (args: { [key: string]: any }) => void;
  fetchBoxDashboard: (params: IBoxesDashboardParams) => void;
  fetchStudents: (box_id: number) => void;
  fetchRealizations: (box_id: number, page?: number) => void;
  fetchMyBoxRealizations: (page?: number) => void;
  toRealizationDetail: (realization_id: number) => void;
  showStudentDetail: (params: IOnClickStudentAvatarParams) => void;
  bulkCheckRealizations: (gear_box_id: number) => void;
  gearboxHiddenFunc: (id: number) => void;
}

const INITIAL_SEARCH = {
  searchText: "",
  selectedClassId: 0,
  selectedTeacherId: 0,
  startDate: null,
  endDate: null,
};

const BoxDashboard: React.FC<BoxDashboardProps> = ({
  isDisplaying,
  calling,
  showDetail,
  classes,
  teachers,
  boxList,
  realizations_count_of_left_students,
  myBox,
  gearboxStudents,
  realizations,
  pagy_info,
  teacherId,
  teacherGearboxDirectories,
  update,
  fetchBoxDashboard,
  fetchStudents,
  fetchRealizations,
  fetchMyBoxRealizations,
  toRealizationDetail,
  showStudentDetail,
  bulkCheckRealizations,
  gearboxHiddenFunc,
}) => {
  const history = useHistory();

  const [state, setState] = React.useState<{
    searchText: string;
    selectedClassId: number;
    selectedTeacherId: number;
    startDate: Date | null;
    endDate: Date | null;
    orderBy: EOrderBy;
    boxId: number | null;
    isMyBox: boolean;
    selectedDirectory: string;
    boxList: IBoxDashboard[];
  }>({
    ...INITIAL_SEARCH,
    boxId: null,
    orderBy: EOrderBy.LATEST_REALIZATION_CREATED_AT,
    isMyBox: false,
    selectedDirectory: "全て",
    boxList: [],
  });

  const classesData = React.useMemo(
    () =>
      classes.map(cls => ({
        value: cls.id,
        label: `${cls.full_name} (${cls.year})`,
      })),
    [classes],
  );

  const onInputChange = React.useCallback(
    e => setState(s => ({ ...s, searchText: e.target.value })),
    [],
  );

  const onDropdownSelect = React.useCallback(
    ({ name, value }) =>
      setState(s => ({
        ...s,
        [name]: value,
      })),
    [],
  );

  const onDatePickerChange = React.useCallback(([start, end]) => {
    setState(s => ({
      ...s,
      startDate: start,
      endDate: end,
    }));
  }, []);

  const isDatePickerInvalid = React.useMemo(
    () =>
      (!!state.startDate && !state.endDate) ||
      (!state.startDate && !!state.endDate),
    [state.startDate, state.endDate],
  );

  React.useEffect(() => {
    !showDetail && setState(prevState => ({ ...prevState, boxId: null }));
  }, [showDetail]);

  const getBoxDashboard = React.useCallback(() => {
    fetchBoxDashboard({
      title: state.searchText,
      class_id: state.selectedClassId,
      start_date: state.startDate,
      end_date: state.endDate,
    });
  }, [
    fetchBoxDashboard,
    state.searchText,
    state.selectedClassId,
    state.startDate,
    state.endDate,
  ]);

  useIonViewWillEnter(() => {
    getBoxDashboard();
  });

  React.useEffect(() => {
    if (!isDatePickerInvalid || (!state.startDate && !state.endDate))
      getBoxDashboard();
  }, [state.startDate, state.endDate, isDatePickerInvalid, getBoxDashboard]);

  React.useEffect(() => {
    if (typeof state.boxId === "number") {
      if (!state.isMyBox) {
        const id = state.boxId;
        fetchStudents(id);
        fetchRealizations(id);
      } else {
        fetchMyBoxRealizations();
      }
      update({ showDetail: true });
    } else {
      update({ showDetail: false });
    }
  }, [
    fetchRealizations,
    fetchStudents,
    update,
    state.boxId,
    state.isMyBox,
    fetchMyBoxRealizations,
  ]);

  const sortFilteredBoxList = React.useCallback(
    (orderBy: EOrderBy, list: IBoxDashboard[]) => {
      const sortBy = {
        // 提出日が新しい順でソート
        [EOrderBy.LATEST_REALIZATION_CREATED_AT]: (
          a: IBoxDashboard,
          b: IBoxDashboard,
        ) =>
          new Date(b.latest_realization_created_at ?? 0).getTime() -
          new Date(a.latest_realization_created_at ?? 0).getTime(),
        // ストック数でソート
        [EOrderBy.REALIZATION_COUNT]: (a: IBoxDashboard, b: IBoxDashboard) =>
          b.realizations_count - a.realizations_count,
        // いいね数でソート
        [EOrderBy.FAVORITE_COUNT]: (a: IBoxDashboard, b: IBoxDashboard) =>
          (b.favorites_count ?? 0) - (a.favorites_count ?? 0),
        // コメント数でソート
        [EOrderBy.COMMENTS_COUNT]: (a: IBoxDashboard, b: IBoxDashboard) =>
          (b.comments_count ?? 0) - (a.comments_count ?? 0),
      } as Record<EOrderBy, (a: IBoxDashboard, b: IBoxDashboard) => number>;

      // 指定されたorderByに基づいてリストをソート
      return [...list].sort(sortBy[orderBy] || (() => 0));
    },
    [],
  );

  const visibleBoxes = React.useMemo(
    () => boxList.filter(box => !box.is_hidden),
    [boxList],
  );

  const getFilteredList = React.useCallback(
    (directoryName: string, selectedTeacherId?: number) => {
      // 全てのディレクトリが選択された場合
      if (directoryName === "全て") {
        return visibleBoxes.filter(box => box.teacher.id === teacherId);
      }
      // 未分類のディレクトリが選択された場合
      if (directoryName === "未分類") {
        return visibleBoxes.filter(
          box => !box.directory_name && box.teacher.id === teacherId,
        );
      }
      // 現在の先生と異なる場合、選択された先生のBOXを表示
      if (selectedTeacherId && selectedTeacherId !== teacherId) {
        return visibleBoxes.filter(box => box.teacher.id === selectedTeacherId);
      }
      // 選択されたディレクトリに属するBOXを表示
      return visibleBoxes.filter(
        box =>
          box.directory_name === directoryName && box.teacher.id === teacherId,
      );
    },
    [visibleBoxes, teacherId],
  );

  React.useEffect(() => {
    if (teachers.length === 0) return;
    const teacherBoxList =
      state.selectedTeacherId || state.selectedDirectory !== "全て"
        ? getFilteredList(state.selectedDirectory, state.selectedTeacherId)
        : boxList.filter(box => box.teacher.id === teacherId);
    setState(prevState => ({
      ...prevState,
      boxList: sortFilteredBoxList(state.orderBy, teacherBoxList),
    }));
  }, [
    boxList,
    getFilteredList,
    sortFilteredBoxList,
    state.orderBy,
    state.selectedDirectory,
    state.selectedTeacherId,
    teacherId,
    teachers.length,
  ]);

  const handleDirectoryChange = React.useCallback(
    (directoryName: string, selectedTeacherId?: number) => {
      const filteredList = getFilteredList(directoryName, selectedTeacherId);
      setState(s => ({
        ...s,
        selectedDirectory: directoryName,
        boxList: filteredList,
        orderBy: EOrderBy.LATEST_REALIZATION_CREATED_AT,
        selectedTeacherId: selectedTeacherId ? selectedTeacherId : 0,
      }));
    },
    [getFilteredList],
  );

  const handleBoxSelection = (boxId: number) => {
    setState(prevState => ({
      ...prevState,
      isMyBox: false,
      boxId,
    }));
    bulkCheckRealizations(boxId);
  };

  const handleOrderByChange = (orderBy: EOrderBy) => {
    setState(s => ({
      ...s,
      orderBy,
      boxList: sortFilteredBoxList(orderBy, s.boxList),
    }));
  };

  return (
    <div className={styles.wrapper}>
      <BoxDirectoryList
        boxList={boxList}
        onDirectorySelect={handleDirectoryChange}
        teacherId={teacherId}
        teacherGearboxDirectories={teacherGearboxDirectories}
      />
      <div className={styles.mainContent}>
        <div className={styles.actionButtons}>
          <div className={styles.selectedDirectory}>
            {state.selectedDirectory}
          </div>
          <div className={styles.boxInfo}>
            {myBox && (
              <div className={styles.read}>
                <IonIcon
                  className={styles.readIcon}
                  icon={mailIcon}
                  onClick={() => {
                    setState(prevState => ({
                      ...prevState,
                      isMyBox: true,
                      boxId: 0,
                    }));
                    bulkCheckRealizations(0);
                  }}
                />
                {myBox.has_unread_realization && (
                  <span className={styles.badge} />
                )}
              </div>
            )}
            <div
              className={styles.btnAddBox}
              onClick={() => history.push(manageBoxesPath)}
            >
              <AddIcon className={styles.icon} />
              BOXをつくる
            </div>
          </div>
        </div>
        <div className={styles.searchBox}>
          <div className={styles.searchOptions}>
            <SearchField
              className={styles.searchField}
              searchText={state.searchText}
              onChange={onInputChange}
            />
            <Dropdown
              variant={"secondary"}
              className={styles.dropdown}
              classNameLabelArea={styles.labelArea}
              name="selectedClassId"
              data={classesData}
              defaultLabel="クラス/グループ"
              defaultValue={0}
              selectedValue={state.selectedClassId}
              onSelect={onDropdownSelect}
            />
            <DatePicker
              variant={"secondary"}
              className={styles.datePicker}
              classNameDatePickerInput={styles.datePickerInput}
              error={isDatePickerInvalid ? "開始日と終了日が必要です" : ""}
              label={
                state.startDate || state.endDate
                  ? `${
                      state.startDate
                        ? convertDateToDateString(state.startDate)
                        : ""
                    }→${
                      state.endDate
                        ? convertDateToDateString(state.endDate)
                        : ""
                    }`
                  : "BOXへの提出日"
              }
              dateFormat="yyyy/MM/dd"
              locale="ja"
              selected={state.startDate}
              onChange={onDatePickerChange}
              startDate={state.startDate}
              endDate={state.endDate}
              selectsRange
            />
          </div>
        </div>
        <div className={styles.sortTabs}>
          {BOX_SORT_DATA.map(tab => (
            <div
              key={tab.value}
              className={clsx(styles.tab, {
                [styles.selected]: tab.value === state.orderBy,
              })}
              onClick={() => handleOrderByChange(tab.value as EOrderBy)}
            >
              {tab.label}
            </div>
          ))}
        </div>
        <div className={styles.boxLabels}>
          <div className={clsx(styles.label, styles.boxName)}>BOX名</div>
          <div className={clsx(styles.label, styles.stock)}>ストック</div>
          <div className={clsx(styles.label, styles.good)}>
            <GoodIcon className={styles.icon} />
            いいね
          </div>
          <div className={clsx(styles.label, styles.comment)}>
            <CommentIcon className={styles.icon} />
            コメント
          </div>
          <div className={clsx(styles.label, styles.status)}>ステータス</div>
          <div className={clsx(styles.label, styles.teacherName)}>作成者</div>
        </div>
        <IonItemGroup className={styles.boxList}>
          {state.boxList.length === 0 ? (
            <div className={styles.emptyData}>該当するデータがありません。</div>
          ) : (
            state.boxList
              .filter(box => !box.is_hidden)
              .map(box => (
                <BoxAnalysisItem
                  key={box.id}
                  boxData={box}
                  onClick={() => handleBoxSelection(box.id)}
                  gearboxHiddenFunc={gearboxHiddenFunc}
                  teacherId={teacherId}
                  calling={calling}
                />
              ))
          )}
        </IonItemGroup>
        <BoxDetailModal
          isMyBox={state.isMyBox}
          isDisplaying={isDisplaying}
          calling={calling}
          box={
            state.boxId === 0 ? myBox : boxList.find(b => b.id === state.boxId)!
          }
          realizations_count_of_left_students={
            realizations_count_of_left_students
          }
          gearboxStudents={gearboxStudents}
          realizations={realizations}
          pagy_info={pagy_info}
          fetchRealizations={fetchRealizations}
          fetchMyBoxRealizations={fetchMyBoxRealizations}
          onClose={() => {
            const filteredList = getFilteredList(
              state.selectedDirectory,
              state.selectedTeacherId,
            );
            setState(prevState => ({
              ...prevState,
              boxId: null,
              boxList: sortFilteredBoxList(state.orderBy, filteredList),
            }));
          }}
          toRealizationDetail={toRealizationDetail}
          showStudentDetail={showStudentDetail}
        />
      </div>
    </div>
  );
};

export default BoxDashboard;
