import { IonContent, IonPage, useIonViewWillEnter } from "@ionic/react";
import React from "react";
import clsx from "clsx";

import LoginHeader from "../../../organisms/LoginHeader";
import {
  clearSuccessMessage,
  Context,
  fetchNotifications,
  FETCH_NOTIFICATIONS_SUCCESS_MESSAGE,
  updateReadLastNotification,
} from "../../../store/teacher";
import NotificationList from "../../../templates/NotificationList";
import styles from "../common.module.scss";

const Notifications = () => {
  const { dispatch, contextState } = React.useContext(Context);

  useIonViewWillEnter(() => {
    dispatch(fetchNotifications());
  });

  React.useEffect(() => {
    const latestNotification = contextState.notifications[0];
    if (
      latestNotification &&
      contextState.success_message === FETCH_NOTIFICATIONS_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      dispatch(updateReadLastNotification(latestNotification.id));
    }
  }, [dispatch, contextState.success_message, contextState.notifications]);

  return (
    <IonPage>
      <LoginHeader
        title="お知らせ"
        unreadNotiCount={contextState.unread_notification_count}
      />
      <IonContent className={clsx(styles.wrapper, styles.bgWhileBase)}>
        <div className={styles.container}>
          <NotificationList notifications={contextState.notifications} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Notifications;
