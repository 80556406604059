import {
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButton,
} from "@ionic/react";
import React from "react";

import AcumulateChart from "../../../molecules/AcumulateChart";
import ClassSelector from "../../../molecules/ClassSelector";
import ModalConfirm from "../../../molecules/ModalConfirm";
import { IClass, IFile, ISummaryPerClassStudents } from "../../../state";
import { ECond, ESpan } from "../../../pages/teacher/AnalyzePage";

import styles from "./AnalyzeClassDetail.module.scss";

export interface AnalyzeClassDetailProps {
  calling: boolean;
  values: { [key: string]: any };
  classes: IClass[];
  class_students_summaries: ISummaryPerClassStudents[];
  update: (args: { [key: string]: any }) => void;
  getRealizations4CSV: () => void;
  onClickStudent: (
    student_id: number,
    name: string,
    photo: IFile | null,
  ) => void;
}

const AnalyzeClassDetail = ({
  calling,
  values,
  classes,
  class_students_summaries,
  update,
  getRealizations4CSV,
  onClickStudent,
}: AnalyzeClassDetailProps) => {
  const [containerWidth, setContainerWidth] = React.useState(0);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const students = React.useMemo(
    () =>
      class_students_summaries.map(data => {
        const { name, student_id } = data;
        return {
          name,
          student_id,
          photo: (data || { photo: null }).photo,
        };
      }),
    [class_students_summaries],
  );
  const dataSet = React.useMemo(
    () =>
      [...class_students_summaries]
        .sort((a, b) => {
          const cond: string = values.cond;
          let a_count: number;
          let b_count: number;
          if (cond === ECond.TOTAL) {
            a_count =
              a.realization_count +
              a.converted_realization_count +
              a.executed_realization_count;
            b_count =
              b.realization_count +
              b.converted_realization_count +
              b.executed_realization_count;
          } else {
            a_count = a[cond];
            b_count = b[cond];
          }
          if (a_count > b_count) return values.isDesc ? -1 : 1;
          if (a_count < b_count) return values.isDesc ? 1 : -1;
          return 0;
        })
        .map(r => ({
          name: r.name,
          photo: r.photo,
          student_id: r.student_id,
          summaries: [
            { color: "#3e9eff", value: r.realization_count },
            { color: "#195cfb", value: r.converted_realization_count },
            { color: "#e7c06c", value: r.executed_realization_count },
          ],
        })),
    [class_students_summaries, values.cond, values.isDesc],
  );
  const g4sEnabledCount = React.useMemo(
    () => class_students_summaries.filter(data => data.g4s_enabled).length,
    [class_students_summaries],
  );

  const updateWidth = React.useCallback(
    () =>
      setContainerWidth(window.innerWidth < 1000 ? window.innerWidth : 1000),
    [],
  );

  React.useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [updateWidth]);

  return (
    <div className={styles.analyzeClassDetailContainer}>
      <div className={styles.text}>担当クラスを選択</div>
      <div className={styles.topActions}>
        <ClassSelector
          className={styles.classSelector}
          classes={classes}
          selectedClassID={values.selectedClassID}
          selectedClassName={values.selectedClassName}
          update={update}
          isTeacher={true}
          isAnalyzeClass={true}
        />
        <IonButton
          className={styles.downloadButton}
          size="small"
          expand="full"
          disabled={calling}
          onClick={() => setShowConfirm(true)}
        >
          CSVダウンロード
        </IonButton>
      </div>
      <IonSegment
        className={styles.segment}
        mode="ios"
        scrollable
        value={values.span}
        onIonChange={e => {
          update({
            span: e.detail.value as ESpan,
          });
        }}
      >
        <IonSegmentButton className={styles.segmentButton} value={ESpan.YEAR}>
          <IonLabel
            className={values.span !== ESpan.YEAR ? styles.inactive : ""}
          >
            今年度
          </IonLabel>
        </IonSegmentButton>
        <IonSegmentButton className={styles.segmentButton} value={ESpan.MONTH}>
          <IonLabel
            className={values.span !== ESpan.MONTH ? styles.inactive : ""}
          >
            過去30日
          </IonLabel>
        </IonSegmentButton>
        <IonSegmentButton className={styles.segmentButton} value={ESpan.WEEK}>
          <IonLabel
            className={values.span !== ESpan.WEEK ? styles.inactive : ""}
          >
            過去7日
          </IonLabel>
        </IonSegmentButton>
      </IonSegment>

      <IonSegment
        className={styles.sortSegment}
        mode="md"
        value={values.cond}
        onIonChange={e => {
          update({
            cond: e.detail.value as ECond,
            isDesc: true,
          });
        }}
      >
        <IonSegmentButton
          type="reset"
          className={styles.sortSegmentButton}
          value={ECond.TOTAL}
          onClick={() => {
            if (values.prevCond === ECond.TOTAL) {
              update({ isDesc: !values.isDesc });
            } else {
              update({ prevCond: ECond.TOTAL });
            }
          }}
        >
          <div>
            投稿総数
            {values.cond === ECond.TOTAL ? (
              <span className={styles.active}>{values.isDesc ? "↓" : "↑"}</span>
            ) : (
              <span>↑</span>
            )}
          </div>
        </IonSegmentButton>
        <IonSegmentButton
          type="reset"
          className={styles.sortSegmentButton}
          value={ECond.REALIZATION_COUNT}
          onClick={() => {
            if (values.prevCond === ECond.REALIZATION_COUNT) {
              update({ isDesc: !values.isDesc });
            } else {
              update({ prevCond: ECond.REALIZATION_COUNT });
            }
          }}
        >
          <div>
            ROOTS
            {values.cond === ECond.REALIZATION_COUNT ? (
              <span className={styles.active}>{values.isDesc ? "↓" : "↑"}</span>
            ) : (
              <span>↑</span>
            )}
          </div>
        </IonSegmentButton>
        <IonSegmentButton
          type="reset"
          className={styles.sortSegmentButton}
          value={ECond.CONVERTED_REALIZATION_COUNT}
          onClick={() => {
            if (values.prevCond === ECond.CONVERTED_REALIZATION_COUNT) {
              update({ isDesc: !values.isDesc });
            } else {
              update({
                prevCond: ECond.CONVERTED_REALIZATION_COUNT,
              });
            }
          }}
        >
          <div>
            WILL
            {values.cond === ECond.CONVERTED_REALIZATION_COUNT ? (
              <span className={styles.active}>{values.isDesc ? "↓" : "↑"}</span>
            ) : (
              <span>↑</span>
            )}
          </div>
        </IonSegmentButton>
        <IonSegmentButton
          type="reset"
          className={styles.sortSegmentButton}
          value={ECond.EXECUTE_REALIZATION_COUNT}
          onClick={() => {
            if (values.prevCond === ECond.EXECUTE_REALIZATION_COUNT) {
              update({ isDesc: !values.isDesc });
            } else {
              update({
                prevCond: ECond.EXECUTE_REALIZATION_COUNT,
              });
            }
          }}
        >
          <div>
            EXP
            {values.cond === ECond.EXECUTE_REALIZATION_COUNT ? (
              <span className={styles.active}>{values.isDesc ? "↓" : "↑"}</span>
            ) : (
              <span>↑</span>
            )}
          </div>
        </IonSegmentButton>
        <IonSegmentButton
          type="reset"
          className={styles.sortSegmentButton}
          value={ECond.FREQUENCY_DATE_TIME_UNIX}
          onClick={() => {
            if (values.prevCond === ECond.FREQUENCY_DATE_TIME_UNIX) {
              update({ isDesc: !values.isDesc });
            } else {
              update({
                prevCond: ECond.FREQUENCY_DATE_TIME_UNIX,
              });
            }
          }}
        >
          <div>
            更新日
            {values.cond === ECond.FREQUENCY_DATE_TIME_UNIX ? (
              <span className={styles.active}>{values.isDesc ? "↓" : "↑"}</span>
            ) : (
              <span>↑</span>
            )}
          </div>
        </IonSegmentButton>
      </IonSegment>
      <div className={styles.colorGuide}>
        <span className={styles.realization}>■ROOTS</span>
        <span className={styles.conversion}>■WILL</span>
        <span className={styles.execution}>■EXP</span>
      </div>

      <AcumulateChart
        options={{ width: containerWidth - 40, height: 15 }}
        classId={values.selectedClassID}
        dataSet={dataSet}
        students={students}
        onClickStudent={onClickStudent}
      />

      <ModalConfirm
        isOpen={showConfirm}
        message={
          <div style={{ textAlign: "left" }}>
            <div>
              以下の条件で生徒が入力した
              <br />
              データを抽出します。
            </div>
            <div style={{ fontSize: "14px", marginTop: "12px" }}>
              <div>担当クラス：{values.selectedClassName}</div>
              <div>対象生徒(GEAR拡張ON)：{g4sEnabledCount}名</div>
              <div>抽出期間：すべて</div>
              <div>共有/提出：あり</div>
            </div>
          </div>
        }
        buttons={[
          {
            title: "キャンセル",
            type: "None",
            action: () => setShowConfirm(false),
          },
          {
            title: "OK",
            type: "Success",
            action: () => {
              getRealizations4CSV();
              setShowConfirm(false);
            },
          },
        ]}
      />
    </div>
  );
};

export default AnalyzeClassDetail;
