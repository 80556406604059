import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonReorder,
  IonReorderGroup,
} from "@ionic/react";
import React from "react";
import {
  addCircleOutline as addIcon,
  removeCircleOutline as removeIcon,
} from "ionicons/icons";
import clsx from "clsx";

import TemplateForm from "../../../molecules/teacher/TemplateForm";
import {
  CreateGearboxTemplateParams,
  ITeacher,
  GearboxTemplate,
  UpdateGearboxTemplateParams,
} from "../../../state";
import SecondaryButton from "../../../atoms/button/SecondaryButton";
import templateIcon from "../../../assets/icons/template.svg";
import ModalConfirm from "../../../molecules/ModalConfirm";
import type { Menu } from "../../../pages/teacher/ManageBoxesPage";

import styles from "./ManageTemplatesMenu.module.scss";

type Props = {
  calling: boolean;
  teacher: ITeacher;
  templates: GearboxTemplate[];
  editable: boolean;
  changeMenu: (menu: Menu) => void;
  createTemplate: (args: CreateGearboxTemplateParams) => void;
  updateTemplate: (args: UpdateGearboxTemplateParams) => void;
  deleteTemplate: (id: number) => void;
  update: (args: { [key: string]: any }) => void;
};

export default function ManageTemplatesMenu({
  calling,
  editable,
  templates,
  changeMenu,
  createTemplate,
  updateTemplate,
  deleteTemplate,
  update,
}: Props) {
  const [state, setState] = React.useState<{
    showTemplateForm: boolean;
    showDeleteTemplateConfirm: boolean;
    templateId: number | null;
    templateTitle: string;
    templateDescription: string;
    templateContent: string;
  }>({
    showTemplateForm: false,
    showDeleteTemplateConfirm: false,
    templateId: null,
    templateTitle: "",
    templateDescription: "",
    templateContent: "",
  });

  const doReorder = (event: CustomEvent) => {
    const sorted = event.detail.complete(templates);
    update({ templates: sorted });
  };

  const handleClickRemoveButton = (template: GearboxTemplate) => {
    if (!editable) {
      return;
    }
    setState(prev => ({
      ...prev,
      templateId: template.id,
      templateTitle: template.title,
      showDeleteTemplateConfirm: true,
    }));
  };

  const handleSaveTemplate = (
    template: CreateGearboxTemplateParams | UpdateGearboxTemplateParams,
  ) => {
    if ("id" in template && template.id !== 0) {
      updateTemplate(template);
    } else {
      createTemplate(template);
    }
    setState(s => ({
      ...s,
      showTemplateForm: false,
    }));
  };

  return (
    <IonContent className={styles.wrapper}>
      <ModalConfirm
        loading={calling}
        isOpen={state.showDeleteTemplateConfirm}
        message={
          <>
            「{state.templateTitle}」を削除しますか？
            <br />
            このテンプレートと紐づくBOXがある場合、
            <br />
            削除以降テンプレートは反映されなくなります。
            <br />
            この操作は取り消しができません。
          </>
        }
        buttons={[
          {
            title: "キャンセル",
            type: "None",
            action: () =>
              setState(s => ({ ...s, showDeleteTemplateConfirm: false })),
          },
          {
            title: "削除する",
            type: "Danger",
            action: () => {
              // showDeleteBoxConfirm = true とともに templateId をセットしているので null でないはず
              deleteTemplate(state.templateId!);
              setState(s => ({ ...s, showDeleteTemplateConfirm: false }));
            },
          },
        ]}
      />
      <TemplateForm
        calling={calling}
        isOpen={state.showTemplateForm}
        id={state.templateId}
        defaultTitle={state.templateTitle}
        defaultDescription={state.templateDescription}
        defaultContent={state.templateContent}
        ok={template => handleSaveTemplate(template)}
        close={() => setState({ ...state, showTemplateForm: false })}
      />
      <IonItem lines="none" className={styles.createTemplateButtonArea}>
        <SecondaryButton
          slot="end"
          disabled={calling}
          onClick={() => changeMenu("box")}
        >
          BOXをつくる
        </SecondaryButton>
      </IonItem>
      <IonItem lines="full" className={styles.templatesBar}>
        <IonIcon
          src={templateIcon}
          slot="start"
          className={styles.templateIcon}
        />
        <IonLabel className={styles.templatesLabel} color="light">
          オリジナルテンプレートを新規作成
        </IonLabel>
        <IonButton
          className={styles.addButton}
          disabled={calling}
          slot="end"
          fill="clear"
          size="large"
          onClick={() => {
            setState(s => ({
              ...s,
              templateId: 0,
              templateTitle: "",
              classID: 0,
              nameStatus: "publish",
              is_archived: false,
              showTemplateForm: true,
            }));
            update({
              editable: false,
            });
          }}
        >
          <IonIcon icon={addIcon} />
        </IonButton>
      </IonItem>
      <IonReorderGroup
        disabled={!editable || calling}
        onIonItemReorder={doReorder}
      >
        {templates &&
          templates.map((template, i) => {
            return (
              <IonItem
                key={template.id}
                className={styles.templateItem}
                lines={i + 1 === templates.length ? "none" : "inset"}
              >
                {editable && (
                  <IonButton
                    className={styles.removeButton}
                    disabled={calling}
                    slot="start"
                    fill="clear"
                    size="large"
                    onClick={() => handleClickRemoveButton(template)}
                  >
                    <IonIcon icon={removeIcon} />
                  </IonButton>
                )}
                <IonLabel
                  id={`template-title-${template.id}`}
                  className={clsx(
                    styles.templateItemLabel,
                    editable && styles.pointer,
                  )}
                  onClick={() => {
                    if (editable) {
                      setState(s => ({
                        ...s,
                        templateId: template.id,
                        templateTitle: template.title,
                        templateDescription: template.description,
                        templateContent: template.content,
                        showTemplateForm: true,
                      }));
                    }
                  }}
                >
                  <div>{template.title}</div>
                </IonLabel>
                <IonReorder slot="end" className={styles.reorderIcon} />
              </IonItem>
            );
          })}
      </IonReorderGroup>
    </IonContent>
  );
}
