import React from "react";
import { IonHeader, IonTitle, IonToolbar } from "@ionic/react";

import styles from "./LogoutHeader.module.scss";

export interface LogoutHeaderProps {
  title: string;
}

export const LogoutHeader = (props: LogoutHeaderProps) => {
  return (
    <IonHeader className={styles.header}>
      <IonToolbar className={styles.toolbar}>
        <IonTitle className={styles.title}>{props.title}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default LogoutHeader;
