import React from "react";
import { menuController } from "@ionic/core/components";
import { menu as menuIcon } from "ionicons/icons";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonIcon,
} from "@ionic/react";
import clsx from "clsx";

import { Context } from "../../../../store/teacher";

import styles from "./MenuHeader.module.scss";

export interface MenuHeaderProps {
  title: string;
  rightButton?: React.ReactNode;
  className?: string;
}

const MenuHeader = (props: MenuHeaderProps) => {
  const { contextState } = React.useContext(Context);
  return (
    <IonHeader className={clsx(styles.header, props.className ?? "")}>
      <IonToolbar className={styles.toolbar}>
        <IonTitle className={styles.title}>{props.title}</IonTitle>
        <IonButtons slot="start">
          <IonMenuButton
            autoHide={false}
            onClick={() => menuController.open("sideMenuContainer")}
          >
            {contextState.unread_notification_count > 0 && (
              <div className={styles.mark}>●</div>
            )}
            <IonIcon icon={menuIcon} className={styles.menuIcon} />
          </IonMenuButton>
        </IonButtons>
        {props.rightButton && (
          <IonButtons slot="end">{props.rightButton}</IonButtons>
        )}
      </IonToolbar>
    </IonHeader>
  );
};

export default MenuHeader;
