import React from "react";
import {
  IonAvatar,
  IonButton,
  IonCol,
  IonIcon,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { personCircle } from "ionicons/icons";
import clsx from "clsx";

import {
  IComment,
  IOnClickStudentAvatarParams,
  IRealization,
} from "../../../state";
import CommentList from "../../../organisms/CommentList";
import ImageDetailModal from "../../../molecules/ImageDetailModal";
import Toast from "../../../molecules/Toast";
import RealizationContent from "../../../molecules/RealizationContent";

import styles from "./SharedRealization.module.scss";

const TEXT_AREA_MIN_HEIGHT = 22;
export interface SharedRealizationProps {
  calling: boolean;
  realization: IRealization;
  comments: IComment[];
  current_id: number;
  hideReactions?: boolean;
  createComment: (realization_id: number, content: string) => void;
  updateComment: (comment_id: number, content: string) => void;
  deleteComment: (comment_id: number) => void;
  onAvatarClick?: (params: IOnClickStudentAvatarParams) => void;
  hasTab?: boolean;
}

const SharedRealization = (props: SharedRealizationProps) => {
  const listRef = React.useRef<HTMLDivElement>(null);
  const commentRef = React.useRef(null);
  const textRef = React.useRef<HTMLTextAreaElement>(null);

  const [values, updateValues] = React.useState({
    content: "",
    showToast: false,
    showImageDetail: false,
    editTargetID: 0,
  });

  const [commentHeight, setCommentHeight] =
    React.useState(TEXT_AREA_MIN_HEIGHT);

  const update = (args: { [key: string]: any }) => {
    updateValues({ ...values, ...args });
  };

  React.useEffect(() => {
    if (commentRef.current) {
      const dom: HTMLTextAreaElement = commentRef.current as any;
      if (dom) {
        dom.scrollTop = 9999;
        setCommentHeight(
          Math.min(
            Math.max(TEXT_AREA_MIN_HEIGHT, dom.scrollTop),
            window.innerHeight * 0.3,
          ),
        );
      }
    }
    return;
  }, [values.content]);

  const handleClickAvatar = React.useCallback(
    e => {
      if (!props.onAvatarClick || !props.realization.student) return;
      e.stopPropagation();

      const { id, photo, full_name, klass_id, klass_name } =
        props.realization.student;
      props.onAvatarClick({
        student_id: id,
        name: full_name,
        photo,
        class_id: klass_id,
        class_name: klass_name ?? "",
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onAvatarClick, props.realization.student],
  );

  return (
    <div className={styles.realizationContainer}>
      <Toast
        type="danger"
        showToast={values.showToast}
        onClose={() => updateValues({ ...values, showToast: false })}
        message="コメントが入力されていません。"
      />
      {props.realization.image && (
        <ImageDetailModal
          show={values.showImageDetail}
          imageUrl={props.realization.image.url}
          imageName={props.realization.image.name}
          onClose={() => updateValues(s => ({ ...s, showImageDetail: false }))}
        />
      )}
      <div className={styles.realizationItem}>
        <div
          className={clsx(
            styles.avatarArea,
            !!props.realization.student &&
              !!props.onAvatarClick &&
              styles.clicableAvatar,
          )}
          onClick={handleClickAvatar}
        >
          {props.realization.student?.photo?.url ? (
            <IonAvatar slot="start" color="light" className={styles.avatar}>
              <img
                alt="プロフィール画像"
                src={props.realization.student.photo.url}
              />
            </IonAvatar>
          ) : (
            <IonIcon
              className={styles.avatar}
              icon={personCircle}
              slot="start"
              color="light"
            />
          )}
        </div>
        <IonRow className={styles.row}>
          <IonCol size="12">
            <IonLabel color="light">
              <span className={styles.name}>
                {props.realization.is_other_school
                  ? "他校の生徒"
                  : props.realization.student?.full_name ?? ""}
              </span>
            </IonLabel>
          </IonCol>
          <IonCol size="12" className={styles.contentWrapper}>
            <RealizationContent
              realization={props.realization}
              commentCount={props.comments.length}
              hideReactions={props.hideReactions}
              isDetail
              color={"light"}
              onClickThumbnailImage={() => {
                updateValues(s => ({ ...s, showImageDetail: true }));
              }}
            />
          </IonCol>
        </IonRow>
      </div>
      {!props.hideReactions && (
        <div ref={listRef} className={styles.commentList}>
          <CommentList
            calling={props.calling}
            current_teacher_id={props.current_id}
            comments={props.comments}
            update={update}
            delete={props.deleteComment}
            onAvatarClick={props.onAvatarClick}
          />
        </div>
      )}
      {!props.hideReactions && (
        <div
          className={clsx(styles.container, props.hasTab && styles["has-tab"])}
        >
          <div className={styles.inputArea} slot="fixed">
            <textarea
              placeholder="コメントする"
              style={{
                height: commentHeight + 18,
              }}
              ref={textRef}
              className={styles.input}
              value={values.content}
              onChange={e => {
                updateValues({ ...values, content: e.target.value });
              }}
            />
            <textarea
              ref={commentRef}
              style={{
                position: "absolute",
                left: 9999,
                height: 0,
                zIndex: -1,
                width: "100%",
              }}
              className={styles.input}
              defaultValue={values.content}
              rows={1}
              tabIndex={-1}
            />
            {values.editTargetID > 0 && (
              <IonButton
                className={styles.cancelButton}
                onClick={() => {
                  updateValues({
                    ...values,
                    content: "",
                    editTargetID: 0,
                  });
                }}
              >
                キャンセル
              </IonButton>
            )}
            <IonButton
              className={styles.commentButton}
              onClick={() => {
                if (values.content === "") {
                  updateValues({ ...values, showToast: true });
                  return;
                }
                if (values.editTargetID > 0) {
                  props.updateComment(values.editTargetID, values.content);
                } else {
                  props.createComment(props.realization.id, values.content);
                }
                updateValues({ ...values, content: "", editTargetID: 0 });
                setTimeout(() => {
                  listRef.current?.scrollTo(0, 10000);
                }, 1000);
              }}
            >
              {values.editTargetID > 0 ? "更新" : "投稿"}
            </IonButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default SharedRealization;
