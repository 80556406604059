import React from "react";

import {
  clearAnalyzeStudent,
  clearAnalyzeRealizations,
  clearRealizationsAnalyzePagyInfo,
  Context,
  fetchAnalyzeRealizationsStudent,
  fetchCategories,
  fetchDetailStudent,
  fetchGearboxesByStudent,
  updateStudentDetailModal,
  analyzePath,
  prefixPath,
} from "../../../store/teacher";
import StudentDetail from "../../../templates/teacher/StudentDetail";
import { IFile, IStudentAnalyze } from "../../../state";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import { formatTimeToString } from "../../../libs/Util";

const StudentDetailPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const [studentAnalyze, setStudentAnalyze] =
    React.useState<IStudentAnalyze | null>(null);
  const [currentStudent, setCurrentStudent] = React.useState<{
    name: string;
    student_id: number;
    photo: IFile | null;
    class_name?: string;
  } | null>(null);
  const [percentRealizations, setPercentRealizations] = React.useState<{
    root: number;
    will: number;
    exp: number;
  }>({ root: 0, will: 0, exp: 0 });
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const {
    student_id: studentId,
    name,
    photo,
    class_id,
    class_name,
  } = contextState.student_detail_modal || {
    student_id: 0,
    class_id: 0,
  };
  const [classByID] = contextState.teacher.classes.filter(
    cls => cls.id === class_id,
  );

  const students = React.useMemo(
    () =>
      window.location.pathname.replace(prefixPath, "") === analyzePath
        ? contextState.class_students_summaries.map(student => {
            const { name, student_id, photo } = student;
            return {
              name,
              student_id,
              photo: photo ?? null,
            };
          })
        : undefined,
    [contextState.class_students_summaries],
  );

  React.useEffect(() => {
    if (!studentId) return;
    dispatch(peep(fetchGearboxesByStudent(studentId)));
    dispatch(peep(fetchCategories(studentId)));
  }, [studentId, dispatch, peep]);

  React.useEffect(() => {
    const { student_analyze } = contextState;
    if (student_analyze) {
      setStudentAnalyze(student_analyze);
      const { roots_count, will_count, exp_count } = student_analyze;
      const maxCount = Math.max(roots_count, will_count);
      setPercentRealizations({
        root: !maxCount ? 0 : (roots_count / maxCount) * 100,
        will: !maxCount ? 0 : (will_count / maxCount) * 100,
        exp: !maxCount ? 0 : (exp_count / maxCount) * 100,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextState.student_analyze]);

  React.useEffect(() => {
    onChangeIdStudent(studentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentId, students]);

  const onChangeIdStudent = React.useCallback(
    (id: number) => {
      if (students) {
        const student = students.find(st => st.student_id === id);
        setCurrentStudent(student ?? null);
      } else {
        setCurrentStudent({
          student_id: studentId,
          name: name ?? "",
          photo: photo ?? null,
        });
      }
    },
    [name, photo, studentId, students],
  );

  const onLoadRealizations = React.useCallback(
    (
      page: number,
      gearbox_id?: string | number | null,
      category_id?: string | number | null,
      start_date?: Date | null,
      end_date?: Date | null,
    ) => {
      if (!studentId) return;
      dispatch(
        peep(
          fetchAnalyzeRealizationsStudent(
            studentId,
            gearbox_id,
            category_id,
            formatTimeToString(start_date),
            formatTimeToString(end_date),
            page,
          ),
        ),
      );
    },
    [dispatch, peep, studentId],
  );

  React.useEffect(() => {
    return () => {
      dispatch(clearAnalyzeStudent());
      dispatch(clearAnalyzeRealizations());
      dispatch(clearRealizationsAnalyzePagyInfo());
      dispatch(updateStudentDetailModal(null));
    };
  }, [dispatch]);

  const onChangeStudentFunc = React.useCallback(
    id => {
      if (!class_id) return;
      dispatch(updateStudentDetailModal({ student_id: id, class_id }));
    },
    [class_id, dispatch],
  );

  const onChangeDate = React.useCallback(
    (sDate: Date | null, eDate: Date | null) => {
      setStartDate(sDate);
      setEndDate(eDate);
    },
    [setStartDate, setEndDate],
  );

  React.useEffect(() => {
    if (!studentId) return;
    if ((startDate && endDate) || (!startDate && !endDate)) {
      dispatch(
        peep(
          fetchDetailStudent(
            studentId,
            formatTimeToString(startDate),
            formatTimeToString(endDate),
          ),
        ),
      );
    }
  }, [startDate, endDate, studentId, dispatch, peep]);

  return studentAnalyze && currentStudent ? (
    <StudentDetail
      isLoading={calling}
      percentRealizations={percentRealizations}
      student_analyze={studentAnalyze}
      class_name={class_name ?? classByID?.full_name ?? "ー"}
      students={students}
      realizations={contextState.realizations_analyze}
      student_current={currentStudent}
      gearboxes={contextState.gearboxesByStudent}
      onLoadRealizations={onLoadRealizations}
      categories={contextState.categories}
      pagy_info_realizations={contextState.realizations_analyze_pagy_info}
      onChangeStudent={onChangeStudentFunc}
      rangeDate={{ startDate, endDate }}
      onChangeDate={onChangeDate}
    />
  ) : null;
};

export default StudentDetailPage;
