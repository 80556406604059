import React from "react";
import { personCircle as personIcon } from "ionicons/icons";
import { IonAvatar, IonIcon, IonLabel } from "@ionic/react";

import AcumulateChartBar from "../../atoms/AcumulateChartBar";
import { IFile } from "../../state";

import styles from "./AcumulateChart.module.scss";

interface AcumulateChartOption {
  height?: number;
  width?: number;
  firstAxisWidth?: number;
  lastAxisWidth?: number;
}

const initialData: Required<AcumulateChartOption> = {
  height: 30,
  width: window.innerWidth - 40,
  firstAxisWidth: 100,
  lastAxisWidth: 40,
};
interface categorySummary {
  color: string;
  value: number;
}
interface Summary {
  name: string;
  student_id: number;
  photo?: IFile;
  summaries: categorySummary[];
}

export interface AcumulateChartProps {
  dataSet: Summary[];
  classId: number;
  options?: AcumulateChartOption;
  students: { name: string; student_id: number; photo?: IFile }[];
  onClickStudent: (
    student_id: number,
    name: string,
    photo: IFile | null,
  ) => void;
}

const AcumulateChart = (props: AcumulateChartProps) => {
  if (props.dataSet.length === 0) {
    return null;
  }
  const options = { ...initialData, ...props.options };
  const max = Math.max.apply(
    null,
    props.dataSet.map(d =>
      d.summaries.reduce((total, cur) => (total += cur.value), 0),
    ),
  );
  const xGapValue =
    (options.width - (options.firstAxisWidth + options.lastAxisWidth)) / max;
  return (
    <div>
      {props.dataSet.map((d, idx) => (
        <div
          key={idx}
          className={styles.record}
          onClick={() =>
            props.onClickStudent(d.student_id, d.name, d.photo ?? null)
          }
        >
          <div>
            {d.photo?.url ? (
              <IonAvatar slot="start" color="light" className={styles.avatar}>
                <img alt="プロフィール画像" src={d.photo.url} />
              </IonAvatar>
            ) : (
              <IonIcon
                className={styles.icon}
                icon={personIcon}
                slot="start"
                color="light"
              />
            )}
          </div>
          <IonLabel color="light" className={styles.name}>
            {d.name.length >= 6 ? d.name.slice(0, 4) + "..." : d.name}
          </IonLabel>
          <svg
            version="1.1"
            height={options.height}
            xmlns="http://www.w3.org/2000/svg"
            className={styles.svg}
          >
            <AcumulateChartBar
              key={idx}
              xGapValue={xGapValue}
              height={options.height}
              dataSet={d.summaries}
            />
          </svg>
          <div className={styles.total}>
            {d.summaries.reduce((total, r) => (total += r.value), 0)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AcumulateChart;
