import { IonContent, IonPage } from "@ionic/react";
import * as React from "react";
import { useHistory } from "react-router-dom";

import LogoutHeader from "../../../organisms/teacher/Header/LogoutHeader";
import {
  Context,
  UPDATE_TEACHER_PASSWORD_SUCCESS_MESSAGE,
  clearSuccessMessage,
  updateTeacherPassword,
  loginPath,
} from "../../../store/teacher";
import UpdatePasswordForm from "../../../templates/teacher/UpdatePasswordForm";
import styles from "../common.module.scss";
import useWaitApiCall from "../../../hooks/useWaitApiCall";

const UpdateTeacherPassword = (props: {
  match: { params: { uuid: string } };
}) => {
  const { contextState, dispatch } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const history = useHistory();
  const uuid = props.match.params.uuid;

  React.useEffect(() => {
    if (
      contextState.success_message === UPDATE_TEACHER_PASSWORD_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      history.push(loginPath);
    }
  }, [history, dispatch, contextState.success_message]);

  const updateTeacherPasswordFunc = React.useCallback(
    (password: string) => {
      dispatch(peep(updateTeacherPassword(uuid, password)));
    },
    [dispatch, peep, uuid],
  );

  return (
    <IonPage>
      <LogoutHeader title="パスワード再設定" />
      <IonContent className={styles.updatePasswordWrapper}>
        <UpdatePasswordForm
          calling={calling}
          error={contextState.error}
          updatePassword={updateTeacherPasswordFunc}
        />
      </IonContent>
    </IonPage>
  );
};

export default UpdateTeacherPassword;
