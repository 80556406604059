import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  IonContent,
  IonPage,
  useIonViewWillLeave,
  useIonViewWillEnter,
} from "@ionic/react";

import BoxDetail from "../../../templates/teacher/BoxDetail";
import NavHeader from "../../../organisms/NavHeader";
import {
  Context,
  fetchGearboxRealizations,
  clearRealizations,
  clearSelectedGearbox,
  clearPagyInfo,
  sharedRealizationListPath,
  clearSuccessMessage,
  CREATE_FEEDBACK_SUCCESS_MESSAGE,
  UPDATE_FEEDBACK_SUCCESS_MESSAGE,
  DELETE_FEEDBACK_SUCCESS_MESSAGE,
  fetchFeedbacks,
  CREATE_COMMENT_SUCCESS_MESSAGE,
  UPDATE_COMMENT_SUCCESS_MESSAGE,
  DELETE_COMMENT_SUCCESS_MESSAGE,
  fetchComments,
  createComment,
  updateComment,
  deleteComment,
  clearComments,
  clearFeedbacks,
  updateStudentDetailModal,
} from "../../../store/teacher";
import styles from "../common.module.scss";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import TemplateModal from "../../../organisms/TemplateModal";
import SharedRealization from "../../../templates/teacher/SharedRealization";

const BoxDetailPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const [values, updateValues] = React.useState({
    realizationDetailId: 0,
    isDisplaying: false,
  });
  const params = useParams<{ id: string }>();
  const history = useHistory();

  const update = (args: { [key: string]: any }) => {
    updateValues(prevValues => ({ ...prevValues, ...args }));
  };

  const fetchGearboxRealizationsFunc = React.useCallback(
    (page?: number) => {
      dispatch(clearSuccessMessage());
      dispatch(peep(fetchGearboxRealizations(Number(params.id), page ?? 1)));
    },
    [dispatch, params.id, peep],
  );

  useIonViewWillEnter(() => {
    update({
      isDisplaying: true,
    });
    dispatch(peep(fetchGearboxRealizations(Number(params.id), 1)));
  }, [params.id]);

  useIonViewWillLeave(() => {
    update({
      isDisplaying: false,
    });
    dispatch(updateStudentDetailModal(null));
    dispatch(clearRealizations());
    dispatch(clearSelectedGearbox());
    dispatch(clearPagyInfo());
    clearRealizationFunc();
  });

  const realizationDetail = React.useMemo(
    () =>
      contextState.realizations.find(
        realization => realization.id === values.realizationDetailId,
      ),
    [contextState.realizations, values.realizationDetailId],
  );

  React.useEffect(() => {
    if (
      contextState.success_message === CREATE_FEEDBACK_SUCCESS_MESSAGE ||
      contextState.success_message === UPDATE_FEEDBACK_SUCCESS_MESSAGE ||
      contextState.success_message === DELETE_FEEDBACK_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      values.realizationDetailId &&
        dispatch(fetchFeedbacks(values.realizationDetailId));
    }
    if (
      contextState.success_message === CREATE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === UPDATE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === DELETE_COMMENT_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      values.realizationDetailId &&
        dispatch(fetchComments(values.realizationDetailId));
    }
  }, [dispatch, contextState.success_message, values.realizationDetailId]);

  const clearRealizationFunc = React.useCallback(() => {
    update({ realizationDetailId: 0 });
    dispatch(clearComments());
    dispatch(clearFeedbacks());
  }, [dispatch]);

  React.useEffect(() => {
    if (values.realizationDetailId !== 0 && values.isDisplaying) {
      history.location.pathname.includes(sharedRealizationListPath)
        ? dispatch(fetchComments(values.realizationDetailId))
        : dispatch(fetchFeedbacks(values.realizationDetailId));
    }
  }, [
    dispatch,
    values.realizationDetailId,
    history.location.pathname,
    values.isDisplaying,
  ]);

  const createCommentFunc = React.useCallback(
    (realization_id: number, content: string) => {
      dispatch(peep(createComment(realization_id, content)));
    },
    [dispatch, peep],
  );

  const updateCommentFunc = React.useCallback(
    (comment_id: number, content: string) => {
      dispatch(peep(updateComment(comment_id, content)));
    },
    [dispatch, peep],
  );

  const deleteCommentFunc = React.useCallback(
    (comment_id: number) => {
      dispatch(peep(deleteComment(comment_id)));
    },
    [dispatch, peep],
  );

  const showStudentDetailFunc = React.useCallback(
    args => {
      dispatch(updateStudentDetailModal(args));
    },
    [dispatch],
  );

  return (
    <IonPage>
      <NavHeader title="BOX詳細" />
      {realizationDetail && (
        <TemplateModal
          isOpen={!!values.realizationDetailId}
          onClose={clearRealizationFunc}
          headerTitle="詳細"
          theme="light"
        >
          <IonContent>
            <SharedRealization
              calling={calling}
              realization={realizationDetail}
              comments={contextState.comments}
              current_id={contextState.teacher.id}
              createComment={createCommentFunc}
              updateComment={updateCommentFunc}
              deleteComment={deleteCommentFunc}
              onAvatarClick={showStudentDetailFunc}
              hasTab
            />
          </IonContent>
        </TemplateModal>
      )}
      <IonContent className={`${styles.wrapper} ${styles.bgWhileBase}`}>
        <div className={styles.container}>
          <BoxDetail
            isDisplaying={values.isDisplaying}
            calling={calling}
            gearbox={contextState.selected_gearbox}
            realizations={contextState.realizations}
            pagy_info={contextState.pagy_info}
            fetchRealizations={fetchGearboxRealizationsFunc}
            onAction={realizationDetailId => {
              update({ realizationDetailId });
            }}
            showStudentDetail={showStudentDetailFunc}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default BoxDetailPage;
