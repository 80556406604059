import { IonPage, IonContent } from "@ionic/react";
import * as React from "react";
import { useParams } from "react-router";

import { clearErrorMessage, Context, verifyMFA } from "../../../store/teacher";
import MultiFactorAuthenticationForm from "../../../templates/teacher/MultiFactorAuthenticationForm";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import styles from "../common.module.scss";

const MultiFactorAuthentication = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const { ott } = useParams<{ ott: string }>();

  const verifyMFAFunc = React.useCallback(
    (mfa_code: string) => {
      dispatch(peep(verifyMFA(ott.trim(), mfa_code)));
    },
    [dispatch, ott, peep],
  );

  const clearErrorFunc = React.useCallback(
    () => dispatch(clearErrorMessage()),
    [dispatch],
  );

  return (
    <IonPage>
      <IonContent className={styles.subWrapper}>
        <MultiFactorAuthenticationForm
          calling={calling}
          onSubmit={verifyMFAFunc}
          error={contextState.error}
          clearError={clearErrorFunc}
        />
      </IonContent>
    </IonPage>
  );
};

export default MultiFactorAuthentication;
