import { IonPage, IonContent } from "@ionic/react";
import * as React from "react";
import { useHistory } from "react-router-dom";

import { parseQueryParams } from "../../../libs/Util";
import {
  authenticationPath,
  clearOTT,
  Context,
  login,
} from "../../../store/teacher";
import LoginForm from "../../../templates/teacher/LoginForm";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import styles from "../common.module.scss";

const Login = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const history = useHistory();
  const [calling, peep] = useWaitApiCall(contextState);
  const loginFunc = React.useCallback(
    (email: string, password: string, remember: boolean) => {
      dispatch(peep(login(email, password, remember)));
    },
    [dispatch, peep],
  );
  const [error, setError] = React.useState(
    parseQueryParams(window.location.href)["message"],
  );
  React.useEffect(() => {
    if (contextState.error) {
      setError(contextState.error);
    }
  }, [contextState.error]);

  React.useEffect(() => {
    if (contextState.ott) {
      history.push(`${authenticationPath}/${contextState.ott}`);
      dispatch(clearOTT());
    }
  }, [dispatch, history, contextState.ott]);

  return (
    <IonPage>
      <IonContent className={styles.subWrapper}>
        <LoginForm
          calling={calling}
          login={loginFunc}
          error={error}
          clearError={() => setError("")}
        />
      </IonContent>
    </IonPage>
  );
};

export default Login;
