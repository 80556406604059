export const REALIZATIONS_CSV_HEADERS = [
  "ID",
  "学年",
  "クラス",
  "ログインID",
  "生徒氏名",
  "種別",
  "内容",
  "作成日時",
  "EXP",
  "完了日時",
  "元ストック内容",
  "元ストック作成日時",
  "元ストック作成者",
  "画像有無",
  "活かし先",
  "提出したBOX",
  "提出した先生",
  "共有",
  "共有クラス／グループ",
  "いいね件数",
  "コメント件数",
  "コメント",
  "自ら動く",
  "最後までやりきる",
  "みんなのために",
  "社会のために",
  "成長のチャンス",
  "視野を拡げる",
  "目的を描く",
];
