import React, { useCallback, useMemo } from "react";
import { IonIcon } from "@ionic/react";
import { useHistory } from "react-router-dom";

import { IBoxDashboard, TeacherGearboxDirectory } from "../../../state";
import folderIcon from "../../../assets/icons/folder.svg";
import { manageBoxDirectoriesPath } from "../../../store/teacher";

import styles from "./BoxDirectoryList.module.scss";

type BoxDirectoryListProps = {
  boxList: IBoxDashboard[];
  onDirectorySelect: (directoryName: string, teacherId?: number) => void;
  teacherId: number;
  teacherGearboxDirectories: TeacherGearboxDirectory[];
};

const BoxDirectoryList: React.FC<BoxDirectoryListProps> = ({
  boxList,
  onDirectorySelect,
  teacherId,
  teacherGearboxDirectories,
}) => {
  const history = useHistory();

  const handleDirectorySelect = (directoryName: string, teacherId?: number) => {
    onDirectorySelect(directoryName, teacherId);
  };

  const isVisibleBox = (box: IBoxDashboard, teacherId: number) => {
    return box.teacher.id === teacherId && !box.is_hidden;
  };

  const getDirectoriesWithBoxCount = useCallback(() => {
    // 表示可能なボックスのみをフィルタリング
    const visibleBoxes = boxList.filter(box => isVisibleBox(box, teacherId));
    // ディレクトリ名の一覧を取得(Setは重複を削除するために使う)
    const directoryNames = Array.from(
      new Set([
        ...visibleBoxes.map(box => box.directory_name || "未分類"),
        ...(teacherGearboxDirectories || []).map(d => d.name),
      ]),
    );
    // 該当ディレクトリに属する表示可能なBOXをカウント
    const directories = directoryNames.map(directoryName => {
      const directoryBoxes = visibleBoxes.filter(
        box =>
          (box.directory_name || "未分類") === directoryName && !box.is_hidden,
      );
      return {
        name: directoryName,
        count: directoryBoxes.length,
      };
    });

    return directories;
  }, [boxList, teacherId, teacherGearboxDirectories]);

  const getBoxCountByOtherTeacher = useCallback(
    (boxes: IBoxDashboard[]) => {
      // 他の先生のIDを取得(Setは重複を削除するために使う)
      const teacherIds = Array.from(new Set(boxes.map(box => box.teacher.id)));
      // 自分のIDを取り除いた他の先生のIDを取得
      const otherTeacherIds = teacherIds.filter(id => id !== teacherId);
      // 他の先生のIDごとにBOXの数をカウントする
      return otherTeacherIds.map(id => {
        const teacherBoxes = boxes.filter(box => isVisibleBox(box, id));
        return {
          teacherId: id,
          teacherName: teacherBoxes[0]?.teacher.full_name || "",
          count: teacherBoxes.length,
        };
      });
    },
    [teacherId],
  );

  const totalVisibleBoxCount = getDirectoriesWithBoxCount().reduce(
    (total, directories) => total + directories.count,
    0,
  );

  const otherTeachersDirectories = useMemo(
    () =>
      getBoxCountByOtherTeacher(boxList).map(
        ({ teacherId, teacherName, count }) => ({
          teacherId,
          name: teacherName,
          count,
        }),
      ),
    [boxList, getBoxCountByOtherTeacher],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <div className={styles.title}>マイフォルダ</div>
        <ul className={styles.list}>
          <li
            className={styles.item}
            onClick={() => handleDirectorySelect("全て")}
          >
            <IonIcon className={styles.icon} icon={folderIcon} /> 全て (
            {totalVisibleBoxCount})
          </li>
          {getDirectoriesWithBoxCount().map((directory, index) => (
            <li
              key={index}
              className={styles.item}
              onClick={() => handleDirectorySelect(directory.name)}
            >
              <IonIcon className={styles.icon} icon={folderIcon} />
              {directory.name} ({directory.count})
            </li>
          ))}
          <li
            className={styles.addFolder}
            onClick={() => history.push(manageBoxDirectoriesPath)}
          >
            <span className={styles.plus}>+</span> フォルダを新規作成
          </li>
        </ul>
      </div>
      <div className={styles.block}>
        <div className={styles.title}>他の先生のフォルダ</div>
        <ul className={styles.list}>
          {otherTeachersDirectories.map((teacher, index) => (
            <li
              key={index}
              className={styles.item}
              onClick={() =>
                handleDirectorySelect(teacher.name, teacher.teacherId)
              }
            >
              <IonIcon className={styles.icon} icon={folderIcon} />
              {teacher.name} ({teacher.count})
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BoxDirectoryList;
