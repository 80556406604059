import React from "react";

interface AcumulateChartBarProps {
  xGapValue: number;
  height: number;
  dataSet: { value: number; color: string }[];
}

const AcumulateChartBar = (props: AcumulateChartBarProps) => {
  let x = 0;
  return (
    <>
      {props.dataSet
        .filter(d => d.value > 0)
        .map((d, idx) => {
          const width = props.xGapValue * d.value;
          const origX = x;
          x += width;
          return (
            <React.Fragment key={idx}>
              <rect
                x={origX}
                y={0}
                width={width}
                height={props.height}
                fill={d.color}
              />
              <text
                fill={"#fff"}
                x={origX + width / 2 - 5}
                y={12}
                fontSize={12}
              >
                {d.value}
              </text>
            </React.Fragment>
          );
        })}
    </>
  );
};
export default AcumulateChartBar;
