import { IonButton, IonInput, IonItem, IonLabel, IonNote } from "@ionic/react";
import * as React from "react";

import styles from "./EditPasswordField.module.scss";

export interface EditPasswordFieldProps {
  value: string;
  error: string;
  onChange: (e: any) => void;
  cancel: () => void;
}

export const EditPasswordField = (props: EditPasswordFieldProps) => {
  const [state, setState] = React.useState({ showPassword: false });
  return (
    <>
      <IonItem>
        <IonLabel color="light">パスワード</IonLabel>
        {state.showPassword && (
          <>
            <IonInput
              name="password"
              type="password"
              onIonChange={props.onChange}
              className={styles.input}
            />
            {state.showPassword && (
              <IonButton
                fill="outline"
                color="light"
                onClick={() => {
                  setState({ showPassword: false });
                  props.cancel();
                }}
              >
                キャンセル
              </IonButton>
            )}
          </>
        )}
        {!state.showPassword && (
          <>
            <IonNote color="light">
              <p>{"*****"}</p>
            </IonNote>
            {!state.showPassword && (
              <IonButton
                fill="outline"
                color="secondary"
                slot="end"
                onClick={() => {
                  setState({ showPassword: true });
                }}
              >
                変更する
              </IonButton>
            )}
          </>
        )}
      </IonItem>
      <div className="invalid-feedback">{props.error}</div>
    </>
  );
};

export default EditPasswordField;
