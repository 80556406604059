import {
  IonContent,
  IonPage,
  useIonViewWillLeave,
  useIonViewWillEnter,
} from "@ionic/react";
import React from "react";
import { useHistory } from "react-router-dom";

import {
  checkRealizationKlass,
  clearSuccessMessage,
  clearRealizationKlasses,
  clearPagyInfo,
  Context,
  fetchRealizationKlasses,
  FETCH_REALIZATION_KLASSES_SUCCESS_MESSAGE,
  boxesPath,
  sharedRealizationListPath,
  CREATE_COMMENT_SUCCESS_MESSAGE,
  UPDATE_COMMENT_SUCCESS_MESSAGE,
  DELETE_COMMENT_SUCCESS_MESSAGE,
  fetchComments,
  createComment,
  updateComment,
  deleteComment,
  clearComments,
  clearFeedbacks,
  updateStudentDetailModal,
} from "../../../store/teacher";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import ThemeHeader from "../../../molecules/ThemeHeader";
import MenuHeader from "../../../organisms/teacher/Header/MenuHeader";
import TemplateModal from "../../../organisms/TemplateModal";
import SharedRealization from "../../../templates/teacher/SharedRealization";
import SharedRealizationList from "../../../templates/teacher/SharedRealizationList";
import styles from "../common.module.scss";

const SharedRealizationListPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const history = useHistory();
  const [values, updateValues] = React.useState({
    selectedClassID: 0,
    selectedClassName: "すべてのグループ",
    isDisplaying: false,
    realizationDetailId: 0,
    entryId: 0,
  });
  const update = (args: { [key: string]: any }) => {
    updateValues(prevValues => ({ ...prevValues, ...args }));
  };

  const fetchRealizationKlassesFunc = React.useCallback(
    (class_id: number, page?: number) => {
      dispatch(peep(fetchRealizationKlasses(class_id, page ?? 1)));
    },
    [dispatch, peep],
  );

  const clearRealizationKlassesFunc = React.useCallback(() => {
    dispatch(clearRealizationKlasses());
  }, [dispatch]);

  React.useEffect(() => {
    if (
      contextState.success_message === FETCH_REALIZATION_KLASSES_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      const entry = contextState.realization_klasses[0];
      if (entry) {
        if (contextState.unread_shared_per_class[`${values.selectedClassID}`]) {
          dispatch(checkRealizationKlass(values.selectedClassID));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    contextState.success_message,
    contextState.unread_shared_per_class,
    contextState.realization_klasses,
  ]);

  useIonViewWillEnter(() => {
    update({
      isDisplaying: true,
    });
    if (values.selectedClassID === 0) {
      fetchRealizationKlassesFunc(values.selectedClassID);
    } else {
      update({ selectedClassID: 0, selectedClassName: "すべてのグループ" });
    }
  }, [values.selectedClassID]);

  useDidMountEffect(
    () => fetchRealizationKlassesFunc(values.selectedClassID),
    [values.selectedClassID, fetchRealizationKlassesFunc],
  );

  useIonViewWillLeave(() => {
    update({
      isDisplaying: false,
    });
    dispatch(updateStudentDetailModal(null));
    dispatch(clearRealizationKlasses());
    dispatch(clearPagyInfo());
    clearRealizationFunc();
  });

  const realizationDetail = React.useMemo(
    () =>
      contextState.realization_klasses.find(
        entry => entry.id === values.entryId,
      )?.realization,
    [contextState.realization_klasses, values.entryId],
  );

  React.useEffect(() => {
    if (
      contextState.success_message === CREATE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === UPDATE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === DELETE_COMMENT_SUCCESS_MESSAGE
    ) {
      if (values.realizationDetailId !== 0) {
        dispatch(clearSuccessMessage());
        dispatch(fetchComments(values.realizationDetailId));
      }
    }
  }, [dispatch, contextState.success_message, values.realizationDetailId]);

  React.useEffect(() => {
    if (values.realizationDetailId !== 0 && values.isDisplaying) {
      dispatch(fetchComments(values.realizationDetailId));
    }
  }, [dispatch, values.realizationDetailId, values.isDisplaying]);

  const createCommentFunc = React.useCallback(
    (realization_id: number, content: string) => {
      dispatch(peep(createComment(realization_id, content)));
    },
    [dispatch, peep],
  );

  const updateCommentFunc = React.useCallback(
    (comment_id: number, content: string) => {
      dispatch(peep(updateComment(comment_id, content)));
    },
    [dispatch, peep],
  );

  const deleteCommentFunc = React.useCallback(
    (comment_id: number) => {
      dispatch(peep(deleteComment(comment_id)));
    },
    [dispatch, peep],
  );

  const clearRealizationFunc = React.useCallback(() => {
    update({ realizationDetailId: 0, entryId: 0 });
    dispatch(clearComments());
    dispatch(clearFeedbacks());
  }, [dispatch]);

  const showStudentDetailFunc = React.useCallback(
    args => {
      dispatch(updateStudentDetailModal(args));
    },
    [dispatch],
  );

  return (
    <IonPage>
      <MenuHeader title="タイムライン" />
      {realizationDetail && (
        <TemplateModal
          isOpen={!!values.realizationDetailId}
          onClose={clearRealizationFunc}
          headerTitle="詳細"
          theme="light"
        >
          <IonContent>
            <SharedRealization
              calling={calling}
              realization={realizationDetail}
              comments={contextState.comments}
              current_id={contextState.teacher.id}
              createComment={createCommentFunc}
              updateComment={updateCommentFunc}
              deleteComment={deleteCommentFunc}
              onAvatarClick={showStudentDetailFunc}
              hasTab
            />
          </IonContent>
        </TemplateModal>
      )}
      <IonContent className={`${styles.wrapper} ${styles.bgWhileBase}`}>
        {contextState.teacher.classes.length > 0 && (
          <div className={styles.container}>
            {contextState.geartheme && (
              <ThemeHeader
                geartheme={contextState.geartheme}
                hideCreate
                is_teacher
              />
            )}
            <SharedRealizationList
              isDisplaying={values.isDisplaying}
              calling={calling}
              classes={contextState.teacher.classes}
              realization_klasses={contextState.realization_klasses}
              unread_shared_per_class={contextState.unread_shared_per_class}
              pagy_info={contextState.pagy_info}
              fetchRealizationKlasses={fetchRealizationKlassesFunc}
              clearRealizationKlasses={clearRealizationKlassesFunc}
              values={values}
              update={update}
              redirectToBox={box_id =>
                history.push(
                  `${sharedRealizationListPath}${boxesPath}/${box_id}`,
                )
              }
              onAction={(realizationDetailId, entryId) => {
                update({ realizationDetailId, entryId });
              }}
              showStudentDetail={showStudentDetailFunc}
            />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default SharedRealizationListPage;
