import { IonButton, IonInput, IonModal } from "@ionic/react";
import React from "react";

import Toast from "../Toast";

import styles from "./TeacherBoxDirectoryForm.module.scss";

type TeacherBoxDirectoryFormProps = {
  calling: boolean;
  isOpen: boolean;
  defaultName: string;
  id: number;
  close: () => void;
  ok: (teacherGearBoxDirectory: { id: number; name: string }) => void;
};

const TeacherBoxDirectoryForm: React.FC<TeacherBoxDirectoryFormProps> = ({
  calling,
  isOpen,
  defaultName,
  id,
  close,
  ok,
}) => {
  const [state, setState] = React.useState<{
    showToast: boolean;
    message: string;
    name: string;
  }>({
    showToast: false,
    message: "",
    name: "",
  });

  const update = React.useCallback((args: { [key: string]: any }) => {
    setState(prevState => ({ ...prevState, ...args }));
  }, []);

  React.useEffect(() => {
    update({
      name: id ? defaultName : "",
    });
  }, [isOpen, defaultName, id, update]);

  const onSubmit = React.useCallback(() => {
    if (id && !state.name) {
      setState(s => ({
        ...s,
        showToast: true,
        message: "フォルダ名を入力してください。",
      }));
      return;
    }
    ok({
      id: id || 0,
      name: state.name,
    });
  }, [id, state.name, ok]);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={close} className={styles.wrapper}>
      <Toast
        type="danger"
        showToast={state.showToast}
        onClose={() => setState(s => ({ ...s, showToast: false, message: "" }))}
        message={state.message}
      />
      <div className={styles.form}>
        <div className={styles.label}>
          {id ? "フォルダ名を編集" : "フォルダを新規作成"}
        </div>
        <div className={styles.inputArea}>
          <IonInput
            className={styles.input}
            name="gearboxDirectoryName"
            value={state.name}
            placeholder="フォルダ名を記入"
            required
            onIonChange={e => update({ name: e.detail.value })}
          />
        </div>
        <div className={styles.buttonArea}>
          <IonButton
            className={styles.cancelButton}
            strong
            fill="outline"
            onClick={close}
          >
            キャンセル
          </IonButton>
          <IonButton
            className={styles.postButton}
            disabled={calling}
            strong
            fill="outline"
            onClick={onSubmit}
          >
            保存
          </IonButton>
        </div>
      </div>
    </IonModal>
  );
};

export default TeacherBoxDirectoryForm;
