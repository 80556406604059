import { IonPage, IonContent } from "@ionic/react";
import * as React from "react";
import { useHistory } from "react-router-dom";

import {
  clearSuccessMessage,
  Context,
  resetTeacherPassword,
  RESET_TEACHER_PASSWORD_SUCCESS_MESSAGE,
  sendEmailCompletePath,
} from "../../../store/teacher";
import ResetPasswordForm from "../../../templates/teacher/ResetPasswordForm";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import styles from "../common.module.scss";

const ResetPassword = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const history = useHistory();

  React.useEffect(() => {
    if (
      contextState.success_message === RESET_TEACHER_PASSWORD_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      history.push(sendEmailCompletePath);
    }
  }, [dispatch, contextState.success_message, history]);

  const resetTeacherPasswordFunc = React.useCallback(
    (email: string) => {
      dispatch(peep(resetTeacherPassword(email)));
    },
    [dispatch, peep],
  );
  return (
    <IonPage>
      <IonContent className={styles.subWrapper}>
        <ResetPasswordForm
          calling={calling}
          error={contextState.error}
          resetTeacherPassword={resetTeacherPasswordFunc}
        />
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
