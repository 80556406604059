import React from "react";

import ReportEntry from "../../../organisms/ReportEntry";
import { IReportEntry } from "../../../state";

import styles from "./ReportList.module.scss";
export interface ReportListProps {
  report_entries: IReportEntry[];
}

const ReportList = (props: ReportListProps) => {
  return (
    <div className={styles.wrapper}>
      {props.report_entries.map(entry => (
        <ReportEntry key={entry.id} report_entry={entry} />
      ))}
    </div>
  );
};

export default ReportList;
