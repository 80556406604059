import { IonContent, IonPage, useIonViewWillEnter } from "@ionic/react";
import React from "react";

import MenuHeader from "../../../organisms/teacher/Header/MenuHeader";
import ReportList from "../../../templates/teacher/ReportList";
import { Context, fetchReports } from "../../../store/teacher";
import styles from "../common.module.scss";

export const Reports = () => {
  const { dispatch, contextState } = React.useContext(Context);

  useIonViewWillEnter(() => {
    dispatch(fetchReports());
  });

  return (
    <IonPage>
      <MenuHeader title="報告された内容" />
      <IonContent className={`${styles.wrapper} ${styles.bgWhileBase}`}>
        <div className={styles.container}>
          <ReportList report_entries={contextState.report_entries} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Reports;
