import * as React from "react";
import { IonContent, IonPage } from "@ionic/react";

import LoginHeader from "../../../organisms/LoginHeader";
import styles from "../common.module.scss";
import MypageMenu from "../../../templates/teacher/MypageMenu";
import {
  clearSuccessMessage,
  Context,
  mailPasswordList,
  MAIL_PASSWORD_LIST_SUCCESS_MESSAGE,
} from "../../../store/teacher";
import useWaitApiCall from "../../../hooks/useWaitApiCall";

const MyPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const mailPasswordListFunc = React.useCallback(
    (class_id: number) => {
      dispatch(peep(mailPasswordList(class_id)));
    },
    [dispatch, peep],
  );
  React.useEffect(() => {
    if (contextState.success_message === MAIL_PASSWORD_LIST_SUCCESS_MESSAGE) {
      dispatch(clearSuccessMessage());
    }
  }, [dispatch, contextState.success_message]);

  return (
    <IonPage>
      <LoginHeader
        title="マイページ"
        unreadNotiCount={contextState.unread_notification_count}
      />
      <IonContent className={styles.wrapper}>
        <div className={styles.container}>
          <MypageMenu
            calling={calling}
            success_message={contextState.success_message}
            teacher={contextState.teacher}
            mailPasswordList={mailPasswordListFunc}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MyPage;
