import React, { ChangeEvent } from "react";
import clsx from "clsx";

import styles from "./SeachField.module.scss";

export interface SearchFieldProps {
  searchText: string;
  className?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const SearchField: React.FC<SearchFieldProps> = ({
  searchText = "",
  className = "",
  onChange,
}) => {
  return (
    <div
      className={clsx(
        styles.searchInputWrapper,
        className,
        searchText && styles.active,
      )}
    >
      <input
        type="text"
        placeholder="キーワード"
        value={searchText}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchField;
