import * as React from "react";
import {
  IonButton,
  IonContent,
  IonPage,
  useIonViewWillLeave,
} from "@ionic/react";

import MenuHeader from "../../../organisms/teacher/Header/MenuHeader";
import ManageBoxDirectoriesMenu from "../../../templates/teacher/ManageBoxDirectoriesMenu";
import {
  Context,
  fetchTeacherGearboxDirectories,
  createTeacherGearboxDirectory,
  updateTeacherGearboxDirectory,
  deleteTeacherGearboxDirectory,
  clearSuccessMessage,
  CREATE_TEACHER_GEARBOX_DIRECTORY_SUCCESS_MESSAGE,
  UPDATE_TEACHER_GEARBOX_DIRECTORY_SUCCESS_MESSAGE,
  DELETE_TEACHER_GEARBOX_DIRECTORY_SUCCESS_MESSAGE,
  clearErrorMessage,
  reorderTeacherGearboxDirectories,
} from "../../../store/teacher";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import styles from "../common.module.scss";
import {
  CreateTeacherGearboxDirectoryParams,
  TeacherGearboxDirectory,
  UpdateTeacherGearboxDirectoryParams,
} from "../../../state";
import Toast from "../../../molecules/Toast";

const ManageBoxDirectoriesPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const [state, setState] = React.useState({
    editable: false,
    showToast: false,
    message: "",
    toastType: "" as "" | "success" | "danger",
    directories: [],
  });

  React.useEffect(() => {
    dispatch(fetchTeacherGearboxDirectories());
  }, [dispatch]);

  React.useEffect(() => {
    if (
      contextState.success_message ===
        CREATE_TEACHER_GEARBOX_DIRECTORY_SUCCESS_MESSAGE ||
      contextState.success_message ===
        UPDATE_TEACHER_GEARBOX_DIRECTORY_SUCCESS_MESSAGE ||
      contextState.success_message ===
        DELETE_TEACHER_GEARBOX_DIRECTORY_SUCCESS_MESSAGE
    ) {
      setState(s => ({
        ...s,
        showToast: true,
        message: contextState.success_message,
        toastType: "success",
      }));
      dispatch(clearSuccessMessage());
      dispatch(fetchTeacherGearboxDirectories());
    }
  }, [dispatch, contextState.success_message]);

  React.useEffect(() => {
    if (contextState.error) {
      setState(s => ({
        ...s,
        showToast: true,
        message: contextState.error,
        toastType: "danger",
      }));
    }
    return () => dispatch(clearErrorMessage());
  }, [dispatch, contextState.error]);

  const update = React.useCallback(
    (args: { [key: string]: any }) =>
      setState(v => ({
        ...v,
        ...args,
      })),
    [],
  );

  const createTeacherGearboxDirectoryFunc = React.useCallback(
    (args: CreateTeacherGearboxDirectoryParams) => {
      dispatch(peep(createTeacherGearboxDirectory(args)));
    },
    [dispatch, peep],
  );

  const updateTeacherGearboxDirectoryFunc = React.useCallback(
    (args: UpdateTeacherGearboxDirectoryParams) => {
      dispatch(peep(updateTeacherGearboxDirectory(args)));
    },
    [dispatch, peep],
  );

  const reorderTeacherGearboxDirectoriesFunc = React.useCallback(
    (teacherGearboxDirectories: TeacherGearboxDirectory[]) => {
      dispatch(
        peep(
          reorderTeacherGearboxDirectories(
            teacherGearboxDirectories.map(({ id }) => id),
          ),
        ),
      );
    },
    [dispatch, peep],
  );

  const deleteTeacherGearboxDirectoryFunc = React.useCallback(
    (id: number) => {
      dispatch(peep(deleteTeacherGearboxDirectory(id)));
    },
    [dispatch, peep],
  );

  useIonViewWillLeave(() => {
    setState(s => ({ ...s, editable: false, teacherGearboxDirectories: [] }));
    dispatch(fetchTeacherGearboxDirectories());
  });

  return (
    <IonPage>
      <MenuHeader
        title="フォルダをつくる"
        rightButton={
          <IonButton
            className={styles.editButton}
            onClick={() => {
              setState(s => ({ ...s, editable: !s.editable }));
              state.directories.length &&
                reorderTeacherGearboxDirectoriesFunc(state.directories);
            }}
          >
            {state.editable ? "完了" : "編集"}
          </IonButton>
        }
      />
      <IonContent className={styles.wrapper}>
        <div className={styles.container}>
          <ManageBoxDirectoriesMenu
            calling={calling}
            editable={state.editable}
            teacherGearboxDirectories={contextState.teacherGearboxDirectories}
            createTeacherGearboxDirectory={createTeacherGearboxDirectoryFunc}
            deleteTeacherGearboxDirectory={deleteTeacherGearboxDirectoryFunc}
            updateTeacherGearboxDirectory={updateTeacherGearboxDirectoryFunc}
            update={update}
          />
        </div>
      </IonContent>
      <Toast
        type={state.toastType}
        showToast={state.showToast}
        onClose={() =>
          setState(s => ({
            ...s,
            showToast: false,
            message: "",
            toastType: "",
          }))
        }
        message={state.message}
      />
    </IonPage>
  );
};

export default ManageBoxDirectoriesPage;
