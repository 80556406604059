import React from "react";
import clsx from "clsx";
import { personCircle as personIcon } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { IonSegment, IonSegmentButton, IonLabel } from "@ionic/react";

import Dropdown from "../../../atoms/Dropdown";
import AnalyzeLineStudentChart from "../../../organisms/AnalyzeLineStudentChart";
import Tabs from "../../../atoms/Tabs";
import TimelineTabContent from "../TimelineTabContent";
import {
  ICategory,
  IFile,
  IPagyInfo,
  IRealizationAnalyze,
  IStudentAnalyze,
  ITeacherGearbox,
} from "../../../state";
import DatePicker from "../../../atoms/DatePicker";
import { convertDateToDateString } from "../../../libs/Util";

import styles from "./StudentDetail.module.scss";

export interface StudentDetailProps {
  isLoading: boolean;
  student_analyze: IStudentAnalyze;
  realizations: IRealizationAnalyze[];
  gearboxes: ITeacherGearbox[];
  pagy_info_realizations: IPagyInfo;
  class_name: string;
  categories: ICategory[];
  student_current: {
    name: string;
    student_id: number;
    photo: IFile | null;
  };
  students?: {
    name: string;
    student_id: number;
    photo: IFile | null;
  }[];
  percentRealizations: {
    root: number;
    will: number;
    exp: number;
  };
  rangeDate: {
    startDate: Date | null;
    endDate: Date | null;
  };
  onLoadRealizations: (
    page: number,
    gearbox_id?: string | number | null,
    category_id?: string | number | null,
    start_date?: Date | null,
    end_date?: Date | null,
  ) => void;
  onChangeStudent: (student_id: number) => void;
  onChangeDate: (startDate: Date | null, endDate: Date | null) => void;
}

const StudentDetail: React.FC<StudentDetailProps> = ({
  student_analyze,
  class_name,
  percentRealizations,
  students,
  student_current,
  realizations,
  gearboxes,
  onLoadRealizations,
  onChangeStudent,
  categories,
  pagy_info_realizations,
  isLoading,
  rangeDate,
  onChangeDate,
}) => {
  const [studentIDSelected, setStudentIDSelected] = React.useState<number>(0);
  const [widthChart, setWidthChart] = React.useState<number>(460);
  const [selectedSegment, setSelectedSegment] = React.useState<
    string | undefined
  >("default");
  const chart = React.useRef<HTMLDivElement>(null);

  const listStudents = React.useMemo(
    () => students?.map(st => ({ label: st.name, value: st.student_id })),
    [students],
  );

  const realizationHistories = student_analyze.realizations.map(r => {
    const { created_at, id, is_executed, kind } = r;
    const createdAt: Date = new Date(created_at);
    const data: any = {
      created_at: createdAt,
      id,
      kind,
    };
    if (is_executed) {
      data.executed_at = createdAt;
    }
    return data;
  });

  const onDatePickerChange = React.useCallback(
    ([start, end]) => {
      onChangeDate(start, end);
    },
    [onChangeDate],
  );

  const setRangeDate = (range: string) => {
    const date = new Date();

    switch (range) {
      case "thisYear":
        const isBeforeApril = date.getMonth() < 3;
        const startYear = date.getFullYear() - (isBeforeApril ? 1 : 0);

        onChangeDate(new Date(startYear, 3, 1), new Date(startYear + 1, 2, 31));
        break;
      case "last30Days":
        onChangeDate(
          new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30),
          date,
        );
        break;
      case "last7Days":
        onChangeDate(
          new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7),
          date,
        );
        break;
      case "today":
        onChangeDate(date, date);
        break;
    }
  };

  const onDropdownSelectStudent = React.useCallback(
    ({ value }) => {
      setStudentIDSelected(value);
      if (value) {
        onChangeStudent(value);
      }
    },
    [onChangeStudent],
  );

  React.useEffect(() => {
    if (chart.current) {
      const chartRef = chart.current;
      const onResize = () => {
        const elmRect = chartRef.getBoundingClientRect();
        elmRect.width && setWidthChart(elmRect.width + 5);
      };
      setTimeout(() => {
        onResize();
      });
      window.addEventListener("resize", onResize);
    }
  }, []);

  return (
    <div className={styles.container}>
      {listStudents && (
        <Dropdown
          name="selectedStudentId"
          className={clsx(styles.dropdown, isLoading ? styles.disabled : "")}
          data={listStudents}
          defaultLabel="生徒から探す"
          defaultValue={0}
          selectedValue={studentIDSelected}
          onSelect={onDropdownSelectStudent}
        />
      )}
      <div className={styles.headerContent}>
        <div className={styles.wrapperTopInfo}>
          <div className={styles.infoStudent}>
            {student_current.photo?.url ? (
              <img
                className={styles.avatar}
                src={student_current.photo.url}
                alt="avatar"
              />
            ) : (
              <IonIcon
                className={styles.avatar}
                icon={personIcon}
                slot="start"
                color="light"
              />
            )}
            <span>{class_name}</span>
            <span>{student_current.name}</span>
          </div>
          <div className={styles.filter}>
            <DatePicker
              disabled={isLoading}
              className={styles.selectorDate}
              label={
                rangeDate.startDate || rangeDate.endDate
                  ? `${
                      rangeDate.startDate
                        ? convertDateToDateString(rangeDate.startDate)
                        : ""
                    }→${
                      rangeDate.endDate
                        ? convertDateToDateString(rangeDate.endDate)
                        : ""
                    }`
                  : "期間を指定"
              }
              dateFormat="yyyy/MM/dd"
              locale="ja"
              selected={rangeDate.startDate}
              onChange={date => {
                onDatePickerChange(date);
                setSelectedSegment("default");
              }}
              startDate={rangeDate.startDate}
              endDate={rangeDate.endDate}
              selectsRange
            />
            <IonSegment
              className={styles.segment}
              mode="ios"
              scrollable
              value={selectedSegment}
              onIonChange={e => setSelectedSegment(e.detail.value)}
            >
              <IonSegmentButton
                className={styles.segmentButton}
                onClick={() => {
                  setRangeDate("thisYear");
                }}
                value="thisYear"
              >
                <IonLabel>今年度</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton
                className={styles.segmentButton}
                onClick={() => {
                  setRangeDate("last30Days");
                }}
                value="last30Days"
              >
                <IonLabel>過去30日</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton
                className={styles.segmentButton}
                onClick={() => {
                  setRangeDate("last7Days");
                }}
                value="last7Days"
              >
                <IonLabel>過去7日</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton
                className={styles.segmentButton}
                onClick={() => {
                  setRangeDate("today");
                }}
                value="today"
              >
                <IonLabel>今日</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </div>
        </div>
        <div className={styles.wrapperParameter}>
          <div className={styles.blockLeft}>
            <div className={styles.wrapperChart}>
              <div className={styles.realizationsCount}>
                <p>総Stock数</p>
                <p>{student_analyze.realizations_count}</p>
              </div>
              <div className={styles.realizationAnalysis}>
                <div className={clsx(styles.analysisItem, styles.root)}>
                  <div
                    style={{
                      width: `${percentRealizations.root}%`,
                    }}
                  />
                  <span
                    style={{
                      left: `max(${percentRealizations.root}%, ${50}px)`,
                    }}
                  >
                    {student_analyze.roots_count}
                  </span>
                </div>
                <div className={clsx(styles.analysisItem, styles.will)}>
                  <div
                    style={{
                      width: `${percentRealizations.will}%`,
                    }}
                  />
                  <span
                    style={{
                      left: `max(${percentRealizations.will}%, ${50}px)`,
                    }}
                  >
                    {student_analyze.will_count}
                  </span>
                </div>
                <div className={clsx(styles.analysisItem, styles.exp)}>
                  <div
                    style={{
                      width: `${percentRealizations.exp}%`,
                    }}
                  />
                  <span
                    style={{
                      left: `max(${percentRealizations.exp}%, ${50}px)`,
                    }}
                  >
                    {student_analyze.exp_count}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.actionCountGroup}>
              <div className={styles.actionCount}>
                <p>
                  いいね
                  <wbr />
                  された数
                </p>
                <div>{student_analyze.favorites_count}</div>
              </div>
              <div className={styles.actionCount}>
                <p>
                  コメント
                  <wbr />
                  された数
                </p>
                <div>{student_analyze.comments_count}</div>
              </div>
              <div className={styles.actionCount}>
                <p>
                  引用
                  <wbr />
                  された数
                </p>
                <div>{student_analyze?.derived_count ?? "ー"}</div>
              </div>
            </div>
          </div>
          <div className={styles.chartContainer} ref={chart}>
            <AnalyzeLineStudentChart
              rangeDate={rangeDate}
              realization_histories={realizationHistories}
              options={{ backgroundColor: "transparent" }}
              widthContainer={widthChart}
            />
          </div>
        </div>
      </div>
      <Tabs
        listTabsTitle={[
          {
            title: "タイムライン",
            id: "timeline",
          },
        ]}
      >
        <div className="tab-content" id="timeline">
          <TimelineTabContent
            key={student_current.student_id}
            isLoading={isLoading}
            realizations={realizations}
            gearboxes={gearboxes}
            onLoadRealizations={onLoadRealizations}
            categories={categories}
            pagy_info_realizations={pagy_info_realizations}
          />
        </div>
      </Tabs>
    </div>
  );
};

export default StudentDetail;
