import React from "react";
import {
  IonIcon,
  IonLabel,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import clsx from "clsx";

import {
  analyzePath,
  Context,
  fetchStatusRenewaled,
  sharedRealizationListPath,
  submittedRealizationListPath,
} from "../../../store/teacher";

import peopleIcon from "./../../../assets/icons/people.svg";
import realizationIcon from "./../../../assets/icons/realization.svg";
import timelineIcon from "./../../../assets/icons/timeline.svg";
import styles from "./Tab.module.scss";

const Tab = (props: any) => {
  const { dispatch, contextState } = React.useContext(Context);
  const children = props.children as React.ReactElement;
  const tabsRef = React.useRef<any>(null);

  const showBadeForSubmitted = React.useMemo(() => {
    return (
      !!contextState.my_box?.has_unread_realization ||
      contextState.boxesDashboard.some(b => b.has_unread_realization)
    );
  }, [contextState.my_box, contextState.boxesDashboard]);

  const showBadeForShared = React.useMemo(() => {
    return (
      Object.values(contextState.unread_shared_per_class).reduce(
        (accum, current) => accum + current,
        0,
      ) > 0
    );
  }, [contextState.unread_shared_per_class]);

  React.useEffect(() => {
    let timer: any = null;
    if (contextState.teacher.id) {
      timer = setInterval(() => dispatch(fetchStatusRenewaled()), 60000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [dispatch, contextState.teacher.id]);

  React.useEffect(() => {
    if (tabsRef.current) {
      const { tabBarRef } = tabsRef.current;
      if (tabBarRef.current) {
        tabBarRef.current.state.tabs.realizations.currentHref =
          submittedRealizationListPath;
        tabBarRef.current.state.tabs.shared.currentHref =
          sharedRealizationListPath;
        tabBarRef.current.state.tabs.analyze.currentHref = analyzePath;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsRef.current?.ionTabContextState.activeTab]);

  return (
    <IonTabs ref={tabsRef} className={styles.tabs}>
      {children}
      <IonTabBar slot="bottom" className={styles.tabBar} mode="ios">
        <IonTabButton
          tab="realizations"
          href={submittedRealizationListPath}
          className={styles.button}
        >
          {showBadeForSubmitted && (
            <span className={clsx(styles.badge, styles.submitted)} />
          )}
          <IonIcon icon={realizationIcon} />
          <IonLabel>
            <div className={styles.tabLabel}>提出されたストック</div>
          </IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="shared"
          href={sharedRealizationListPath}
          className={styles.button}
        >
          {showBadeForShared && (
            <span className={clsx(styles.badge, styles.shared)} />
          )}
          <IonIcon icon={timelineIcon} />
          <IonLabel>
            <div className={styles.tabLabel}>タイムライン</div>
          </IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="analyze"
          href={analyzePath}
          className={styles.button}
        >
          <IonIcon icon={peopleIcon} />
          <IonLabel>
            <div className={styles.tabLabel}>生徒一覧</div>
          </IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default Tab;
