import {
  IonContent,
  IonPage,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import React from "react";
import clsx from "clsx";

import { fetchIDFromPath } from "../../../libs/Util";
import NavHeader from "../../../organisms/NavHeader";
import {
  clearComments,
  clearFeedbacks,
  clearRealization,
  clearSuccessMessage,
  Context,
  createComment,
  createFeedback,
  CREATE_COMMENT_SUCCESS_MESSAGE,
  CREATE_FEEDBACK_SUCCESS_MESSAGE,
  deleteComment,
  deleteFeedback,
  DELETE_COMMENT_SUCCESS_MESSAGE,
  DELETE_FEEDBACK_SUCCESS_MESSAGE,
  fetchComments,
  fetchFeedbacks,
  fetchRealization,
  updateComment,
  updateFeedback,
  updateStudentDetailModal,
  UPDATE_COMMENT_SUCCESS_MESSAGE,
  UPDATE_FEEDBACK_SUCCESS_MESSAGE,
  checkedTeacher,
  uncheckedTeacher,
} from "../../../store/teacher";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import styles from "../common.module.scss";
import DashboardSharedRealization from "../../../templates/teacher/DashboardSharedRealization";

export const SharedRealizationPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);

  useIonViewWillEnter(() => {
    dispatch(fetchRealization(fetchIDFromPath()));
  });

  React.useEffect(() => {
    if (
      contextState.success_message === CREATE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === UPDATE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === DELETE_COMMENT_SUCCESS_MESSAGE
    ) {
      if (contextState.realization.id !== 0) {
        dispatch(clearSuccessMessage());
        dispatch(fetchComments(contextState.realization.id));
      }
    }
    if (
      contextState.success_message === CREATE_FEEDBACK_SUCCESS_MESSAGE ||
      contextState.success_message === UPDATE_FEEDBACK_SUCCESS_MESSAGE ||
      contextState.success_message === DELETE_FEEDBACK_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      dispatch(fetchFeedbacks(contextState.realization.id));
    }
  }, [dispatch, contextState.success_message, contextState.realization.id]);

  React.useEffect(() => {
    if (contextState.realization.id !== 0) {
      dispatch(fetchComments(contextState.realization.id));
      dispatch(fetchFeedbacks(contextState.realization.id));
    }
  }, [dispatch, contextState.realization.id]);

  const createCommentFunc = React.useCallback(
    (realization_id: number, content: string) => {
      dispatch(peep(createComment(realization_id, content)));
    },
    [dispatch, peep],
  );

  const updateCommentFunc = React.useCallback(
    (comment_id: number, content: string) => {
      dispatch(peep(updateComment(comment_id, content)));
    },
    [dispatch, peep],
  );

  const deleteCommentFunc = React.useCallback(
    (comment_id: number) => {
      dispatch(peep(deleteComment(comment_id)));
    },
    [dispatch, peep],
  );

  const createFeedbackFunc = React.useCallback(
    (realization_id: number, content: string) => {
      dispatch(peep(createFeedback(realization_id, content)));
    },
    [dispatch, peep],
  );

  const updateFeedbackFunc = React.useCallback(
    (feedback_id: number, content: string) => {
      dispatch(peep(updateFeedback(feedback_id, content)));
    },
    [dispatch, peep],
  );

  const deleteFeedbackFunc = React.useCallback(
    (feedback_id: number) => {
      dispatch(peep(deleteFeedback(feedback_id)));
    },
    [dispatch, peep],
  );

  useIonViewWillLeave(() => {
    dispatch(updateStudentDetailModal(null));
    clearRealizationFunc();
  });

  const clearRealizationFunc = React.useCallback(() => {
    dispatch(clearRealization());
    dispatch(clearComments());
    dispatch(clearFeedbacks());
  }, [dispatch]);

  const showStudentDetailFunc = React.useCallback(
    args => {
      dispatch(updateStudentDetailModal(args));
    },
    [dispatch],
  );

  const checkedTeacherFunc = React.useCallback(
    (id: number) => {
      if (calling) return;
      dispatch(peep(checkedTeacher(id)));
    },
    [calling, dispatch, peep],
  );
  const uncheckedTeacherFunc = React.useCallback(
    (id: number) => {
      if (calling) return;
      dispatch(peep(uncheckedTeacher(id)));
    },
    [calling, dispatch, peep],
  );

  return (
    <IonPage>
      <NavHeader title="詳細" />
      <IonContent className={clsx(styles.wrapper, styles.bgWhileBase)}>
        {contextState.realization &&
          contextState.realization.id === fetchIDFromPath() && (
            <div className={styles.container}>
              <DashboardSharedRealization
                calling={calling}
                realization={contextState.realization}
                comments={contextState.comments}
                feedbacks={contextState.feedbacks}
                current_id={contextState.teacher.id}
                createComment={createCommentFunc}
                updateComment={updateCommentFunc}
                deleteComment={deleteCommentFunc}
                createFeedback={createFeedbackFunc}
                updateFeedback={updateFeedbackFunc}
                deleteFeedback={deleteFeedbackFunc}
                onAvatarClick={showStudentDetailFunc}
                onChecked={checkedTeacherFunc}
                onUnchecked={uncheckedTeacherFunc}
              />
            </div>
          )}
      </IonContent>
    </IonPage>
  );
};

export default SharedRealizationPage;
