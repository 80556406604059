import {
  IonContent,
  IonPage,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import React from "react";
import clsx from "clsx";
import { formatISO9075 } from "date-fns";
import { CSVLink } from "react-csv";

import { getDateString } from "../../../libs/Util";
import MenuHeader from "../../../organisms/teacher/Header/MenuHeader";
import {
  Context,
  fetchSummaryPerClassStudents,
  fetchRealizationsCSVData,
  updateStudentDetailModal,
} from "../../../store/teacher";
import AnalyzeClassDetail from "../../../templates/teacher/AnalyzeClassDetail";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import { REALIZATIONS_CSV_HEADERS } from "../../../constants/analyzeConstant";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import styles from "../common.module.scss";

export enum ESpan {
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}

export enum ECond {
  TOTAL = "total",
  REALIZATION_COUNT = "realization_count",
  CONVERTED_REALIZATION_COUNT = "converted_realization_count",
  EXECUTE_REALIZATION_COUNT = "executed_realization_count",
  FREQUENCY_DATE_TIME_UNIX = "frequency_date_time_unix",
}

const AnalyzePage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const initialState = React.useMemo(
    () => ({
      selectedClassName: contextState.teacher.classes[0]?.full_name,
      selectedClassID: contextState.teacher.classes[0]?.id,
      span: ESpan.MONTH,
      cond: ECond.TOTAL,
      prevCond: ECond.TOTAL,
      isDesc: true,
    }),
    [contextState.teacher.classes],
  );
  const [values, updateValues] = React.useState<{
    selectedClassName: string;
    selectedClassID: number;
    span: ESpan;
    cond: ECond;
    prevCond: ECond;
    isDesc: boolean;
  }>({
    ...initialState,
  });
  const csvLinkRef = React.useRef<
    CSVLink & HTMLAnchorElement & { link?: HTMLAnchorElement }
  >(null);

  const update = (args: { [key: string]: any }) => {
    updateValues(prevValues => ({ ...prevValues, ...args }));
  };

  const fetchSummaryPerClassStudentsFunc = React.useCallback(
    (class_id: number, span: string) => {
      dispatch(fetchSummaryPerClassStudents(class_id, span));
    },
    [dispatch],
  );

  useIonViewWillEnter(() => {
    update({
      ...initialState,
    });
  }, [initialState]);

  React.useEffect(() => {
    update({
      selectedClassName: contextState.teacher.classes[0]?.full_name,
      selectedClassID: contextState.teacher.classes[0]?.id,
    });
  }, [contextState.teacher.classes]);

  React.useEffect(() => {
    if (values.selectedClassID)
      fetchSummaryPerClassStudentsFunc(values.selectedClassID, values.span);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.selectedClassID, values.span]);

  const getRealizationsCSVDataFunc = React.useCallback(
    () => dispatch(peep(fetchRealizationsCSVData(values.selectedClassID))),
    [dispatch, peep, values.selectedClassID],
  );

  const csvData = React.useMemo(() => {
    if (!contextState.realizations_csv_data) {
      return [];
    }
    const { realizations } = contextState.realizations_csv_data;
    return realizations.map(realization =>
      [
        realization.id,
        realization.student?.current_grade ?? "",
        realization.student?.klass_name ?? "",
        realization.student?.identity ?? "",
        realization.student?.full_name ?? "",
        realization.kind.toUpperCase(),
        realization.content ?? "",
        realization.roots_created_at
          ? formatISO9075(realization.roots_created_at)
          : "",
        realization.is_executed ? "✔" : "",
        realization.executed_at ? formatISO9075(realization.executed_at) : "",
        realization.origin_realization?.content ?? "",
        realization.origin_realization?.created_at
          ? formatISO9075(realization.origin_realization.created_at)
          : "",
        realization.origin_realization?.student?.full_name ?? "",
        realization.has_image ? "あり" : "なし",
        realization.categories.map(category => category.name)?.join("\r\n") ??
          "",
        realization.gearbox_title ?? "",
        realization.submitted_teacher_names?.join("\r\n") ?? "",
        realization.shared ? "✔" : "",
        realization.shared_classes.join("\r\n"),
        realization.favorites_count,
        realization.comments_count,
        realization.comment_details?.join("\r\n") ?? "",
        realization.experience_nos?.includes(1) ? "✔" : "-",
        realization.experience_nos?.includes(2) ? "✔" : "-",
        realization.experience_nos?.includes(3) ? "✔" : "-",
        realization.experience_nos?.includes(4) ? "✔" : "-",
        realization.experience_nos?.includes(5) ? "✔" : "-",
        realization.experience_nos?.includes(6) ? "✔" : "-",
        realization.experience_nos?.includes(7) ? "✔" : "-",
      ].map(value =>
        typeof value === "string" ? value.replaceAll('"', '""') : value,
      ),
    );
  }, [contextState.realizations_csv_data]);

  useDidMountEffect(() => {
    const timeout = setTimeout(() => {
      csvData.length &&
        csvLinkRef.current?.link &&
        csvLinkRef.current.props.data.length &&
        csvLinkRef.current.link.click();
    });
    return () => clearTimeout(timeout);
  }, [csvLinkRef, csvData]);

  useIonViewWillLeave(() => {
    dispatch(updateStudentDetailModal(null));
  });

  const onClickStudentFunc = React.useCallback(
    (student_id, name, photo) => {
      dispatch(
        updateStudentDetailModal({
          student_id,
          name,
          photo,
          class_id: values.selectedClassID,
          class_name: values.selectedClassName,
        }),
      );
    },
    [dispatch, values.selectedClassID, values.selectedClassName],
  );

  return (
    <IonPage>
      <MenuHeader title="生徒一覧" />
      <CSVLink
        ref={csvLinkRef}
        filename={`gear_download_${
          contextState.realizations_csv_data?.school.name
        }_${
          contextState.teacher.classes.find(
            cl => cl.id === values.selectedClassID,
          )?.full_name
        }_${getDateString()}.csv`}
        headers={REALIZATIONS_CSV_HEADERS}
        data={csvData}
        uFEFF
        hidden
      />
      <IonContent className={clsx(styles.wrapper, styles.bgWhileBase)}>
        {contextState.teacher.classes.length > 0 && (
          <div className={styles.container}>
            <AnalyzeClassDetail
              calling={calling}
              values={values}
              classes={contextState.teacher.classes}
              class_students_summaries={contextState.class_students_summaries}
              update={update}
              getRealizations4CSV={getRealizationsCSVDataFunc}
              onClickStudent={onClickStudentFunc}
            />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default AnalyzePage;
