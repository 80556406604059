import React from "react";
import { IonButton, IonInput, IonLabel } from "@ionic/react";
import { Link } from "react-router-dom";

import { loginPath } from "../../../store/teacher";
import Toast from "../../../molecules/Toast";

import styles from "./MultiFactorAuthenticationForm.module.scss";

export interface MultiFactorAuthenticationFormProps {
  calling: boolean;
  error: string;
  onSubmit: (mfa_code: string) => void;
  clearError: () => void;
}

const validates: { [key: string]: (value: any) => string } = {};

validates["mfa_code"] = val => {
  return val.mfa_code.trim() ? "" : "この項目は入力が必須です。";
};

export const MultiFactorAuthenticationForm = (
  props: MultiFactorAuthenticationFormProps,
) => {
  const [state, setState] = React.useState({
    mfa_code: "",
    errorMessages: {} as { [key: string]: string },
  });

  const onSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const errorMessages = {} as { [key: string]: string };
      Object.keys(validates).forEach(key => {
        const ret = validates[key](state);
        if (ret) {
          errorMessages[key] = ret;
        }
      });
      if (Object.keys(errorMessages).length > 0) {
        setState(s => ({ ...s, errorMessages }));
        return;
      }
      props.onSubmit(state.mfa_code.trim());
    },
    [state, props],
  );

  const onChange = React.useCallback((ev: any) => {
    const e = ev as React.SyntheticEvent<HTMLInputElement>;
    e.preventDefault();
    const val = e.currentTarget.value;
    const key = e.currentTarget.name;
    const args = { [key]: val };
    const ret = validates[key](args);
    setState(prevState => ({
      ...prevState,
      [key]: val,
      errorMessages: { ...prevState.errorMessages, [key]: ret },
    }));
  }, []);

  return (
    <div className={styles.wrapper}>
      <Toast
        type="danger"
        showToast={!!props.error}
        onClose={props.clearError}
        message={props.error}
      />
      <form className="ion-padding" onSubmit={onSubmit}>
        <div className={styles.gearIconArea}>
          <div className={styles.title}>GEAR for School</div>
          <div className={styles.title}>Teacher</div>
        </div>
        <div className={styles.inputArea}>
          <IonLabel color="light">認証コード</IonLabel>
          <IonInput
            className={styles.input}
            name="mfa_code"
            type="text"
            onIonChange={onChange}
          />
        </div>
        <div className={styles.invalidFeedback}>
          {state.errorMessages["mfa_code"]}
        </div>
        <IonButton
          className={styles.submitButton}
          expand="block"
          onClick={onSubmit}
          disabled={
            Object.keys(state.errorMessages).filter(k => state.errorMessages[k])
              .length > 0 || props.calling
          }
        >
          ログイン
        </IonButton>
      </form>
      <div className={styles.backToLogin}>
        <Link to={loginPath}>ログイン画面に戻る</Link>
      </div>
    </div>
  );
};

export default MultiFactorAuthenticationForm;
