import { IonButton } from "@ionic/react";
import React from "react";

import styles from "./SecondaryButton.module.scss";

type Props = React.ComponentProps<typeof IonButton>;

export default function SecondaryButton(props: Props) {
  return (
    <IonButton {...props} fill="outline" className={styles.button}>
      {props.children}
    </IonButton>
  );
}
