import React from "react";
import {
  IonAvatar,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { checkmarkCircle, personCircle } from "ionicons/icons";
import { chevronForwardOutline as rightArrowIcon } from "ionicons/icons";
import clsx from "clsx";

import { IOnClickStudentAvatarParams, IRealization } from "../../../state";
import ImageDetailModal from "../../ImageDetailModal";
import RealizationContent from "../../RealizationContent";

import styles from "./RealizationItem.module.scss";

export interface RealizationItemProps {
  realization: IRealization;
  hideReactions?: boolean;
  hideCheckedStatus?: boolean;
  onClick?: () => void;
  onAvatarClick?: (params: IOnClickStudentAvatarParams) => void;
}

const RealizationItem: React.FC<RealizationItemProps> = ({
  realization,
  hideReactions,
  hideCheckedStatus,
  onClick,
  onAvatarClick,
}) => {
  const [state, setState] = React.useState({
    showImageDetail: false,
  });

  const handleClickAvatar = React.useCallback(
    e => {
      if (!onAvatarClick || !realization.student) return;
      e.stopPropagation();

      const { id, full_name, photo, klass_id, klass_name } =
        realization.student;
      onAvatarClick({
        student_id: id,
        name: full_name,
        photo,
        class_id: klass_id,
        class_name: klass_name,
      });
    },
    [onAvatarClick, realization.student],
  );

  return (
    <>
      {realization.image && (
        <ImageDetailModal
          show={state.showImageDetail}
          imageUrl={realization.image.url}
          imageName={realization.image.name}
          onClose={() => setState(s => ({ ...s, showImageDetail: false }))}
        />
      )}
      <IonItem
        lines="full"
        className={styles.realizationItem}
        onClick={onClick}
        button
        detail={false}
      >
        <div
          className={clsx(
            styles.avatarArea,
            !!realization.student && !!onAvatarClick && styles.clicableAvatar,
          )}
          onClick={handleClickAvatar}
        >
          {realization.student?.photo?.url ? (
            <IonAvatar slot="start" color="light" className={styles.avatar}>
              <img alt="プロフィール画像" src={realization.student.photo.url} />
            </IonAvatar>
          ) : (
            <IonIcon
              className={styles.avatar}
              icon={personCircle}
              slot="start"
              color="light"
            />
          )}
        </div>
        <IonRow className={styles.row}>
          <IonCol size="12" className={styles.wrapperContent}>
            <div className={styles.container}>
              <div className={styles.topWrapper}>
                <div className={styles.nameArea}>
                  <IonLabel color="dark">
                    <span className={styles.name}>
                      {realization.is_other_school
                        ? "他校の生徒"
                        : realization.student?.full_name ?? ""}
                    </span>
                  </IonLabel>
                  {realization.is_checked && !hideCheckedStatus && (
                    <div className={styles.checkedStatus}>
                      <IonIcon
                        icon={checkmarkCircle}
                        className={styles.checkIcon}
                      />
                      <span>先生確認済</span>
                    </div>
                  )}
                </div>
              </div>
              <RealizationContent
                realization={realization}
                hideReactions={hideReactions}
                color={"light"}
                onClickThumbnailImage={() => {
                  setState(s => ({ ...s, showImageDetail: true }));
                }}
              />
            </div>
            <IonIcon
              color="medium"
              icon={rightArrowIcon}
              className={styles.iconCaretRight}
            />
          </IonCol>
        </IonRow>
      </IonItem>
    </>
  );
};

export default RealizationItem;
