import {
  IonAvatar,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
} from "@ionic/react";
import { personCircle as personIcon } from "ionicons/icons";
import React from "react";

import EditPasswordField from "../../atoms/EditPasswordField";
import { IClass, IFile } from "../../state";

import styles from "./TeacherProfileForm.module.scss";

export interface TeacherProfileFormProps {
  calling: boolean;
  error: string;
  school_name: string;
  classes: IClass[];
  first_name: string;
  last_name: string;
  photo: IFile | null;
  isUpdating: boolean;
  update: (password: string, photo: IFile | null) => void;
  cancel: () => void;
}

export const TeacherProfileFormForm = (props: TeacherProfileFormProps) => {
  const [state, setState] = React.useState({
    photo: props.photo,
    password: "",
    isSubmitted: false,
    errorMessages: {} as { [key: string]: string },
    error: props.error,
  });
  const photoInput = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    setState(s => ({ ...s, error: props.error }));
  }, [props.error]);

  const onChange = React.useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      const key = e.currentTarget.name;
      const val = e.currentTarget.value;
      const ret =
        val.length < 6 || val.length > 32
          ? "パスワードは6文字以上32文字以内で入力してください。"
          : "";
      setState(prevState => ({
        ...prevState,
        [key]: val,
        errorMessages: { ...prevState.errorMessages, [key]: ret },
      }));
    },
    [],
  );

  const onSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (
        state.password ||
        state.photo?.blob ||
        (state.photo?.id && state.photo?.deleted)
      ) {
        props.update(state.password, state.photo);
      } else {
        props.cancel();
      }
    },
    [state, props],
  );

  return (
    <div className={styles.wrapper}>
      <IonList className={styles.profileList}>
        <IonItem>
          <input
            ref={photoInput}
            name="photo"
            type="file"
            style={{ display: "none" }}
            accept=".png,.jpg,.jpeg"
            onChange={(e: any) => {
              const file = e.currentTarget.files[0];
              if (!file) {
                return;
              }
              if (!file.type.match(/^image\//)) {
                alert("画像以外のファイルはアップロードできません");
                return;
              }
              if (
                file.type.toLowerCase() !== "image/jpg" &&
                file.type.toLowerCase() !== "image/jpeg" &&
                file.type.toLowerCase() !== "image/png"
              ) {
                alert("jpg/png以外の画像はアップロードできません");
                return;
              }
              setState({
                ...state,
                photo: {
                  ...(props.photo as IFile),
                  name: file.name,
                  url: URL.createObjectURL(file),
                  blob: file,
                },
              });
            }}
          />
          <IonLabel>
            {state.photo?.url ? (
              <IonAvatar>
                <img alt="プロフィール画像" src={state.photo.url} />
              </IonAvatar>
            ) : (
              <IonIcon
                icon={personIcon}
                slot="start"
                size="large"
                color="light"
              />
            )}
          </IonLabel>
          <IonButton
            fill="outline"
            color="secondary"
            onClick={() => {
              photoInput.current && photoInput.current.click();
            }}
          >
            変更する
          </IonButton>
          {state.photo && state.photo.url && (
            <IonButton
              fill="outline"
              color="warning"
              onClick={() => {
                (photoInput.current as HTMLInputElement).value = "";
                setState({
                  ...state,
                  photo: {
                    ...(state.photo as IFile),
                    url: "",
                    blob: undefined,
                    deleted: true,
                  },
                });
              }}
            >
              削除する
            </IonButton>
          )}
        </IonItem>
        <EditPasswordField
          value={state.password}
          error={state.errorMessages["password"]}
          onChange={onChange}
          cancel={() => setState({ ...state, password: "" })}
        />
        <IonItem>
          <IonLabel color="light">学校名</IonLabel>
          <IonNote color="light">
            <h2>{props.school_name}</h2>
          </IonNote>
        </IonItem>
        <IonItem>
          <IonLabel color="light">クラス</IonLabel>
          <IonNote color="light">
            <h2>
              {props.classes.map(cls => (
                <span key={cls.id}>{cls.full_name} </span>
              ))}
            </h2>
          </IonNote>
        </IonItem>
        <IonItem>
          <IonLabel color="light">名前</IonLabel>
          <IonNote color="light">
            <h2>{props.last_name + " " + props.first_name}</h2>
          </IonNote>
        </IonItem>
      </IonList>
      <IonButton
        className={styles.postButton}
        strong
        expand="block"
        fill="outline"
        disabled={props.isUpdating || props.calling}
        onClick={onSubmit}
      >
        更新
      </IonButton>
      <IonButton
        className={styles.cancelButton}
        strong
        expand="block"
        fill="outline"
        onClick={() => props.cancel()}
      >
        キャンセル
      </IonButton>
    </div>
  );
};

export default TeacherProfileFormForm;
