import { IonIcon, IonButton } from "@ionic/react";
import React, { useRef } from "react";
import { chevronForwardOutline as rightArrowIcon } from "ionicons/icons";

import {
  IBoxDashboard,
  IRealization,
  IStudentForGearbox,
  IPagyInfo,
  IOnClickStudentAvatarParams,
} from "../../state";
import boxIcon from "../../assets/icons/box.svg";
import closeIcon from "../../assets/icons/close_circle.svg";
import BoxTitle from "../../atoms/BoxTitle";
import RealizationItem from "../teacher/RealizationItem";
import useIntersection from "../../hooks/useIntersection";

import styles from "./BoxDetailModal.module.scss";

export interface BoxDetailModalProps {
  isMyBox?: boolean;
  isDisplaying: boolean;
  calling: boolean;
  box: IBoxDashboard | null;
  realizations_count_of_left_students: number;
  gearboxStudents: IStudentForGearbox[];
  realizations: IRealization[];
  pagy_info: IPagyInfo;
  fetchRealizations: (box_id: number, page?: number) => void;
  fetchMyBoxRealizations: (page?: number) => void;
  onClose: () => void;
  toRealizationDetail: (realization_id: number) => void;
  showStudentDetail: (params: IOnClickStudentAvatarParams) => void;
}

const BoxDetailModal: React.FC<BoxDetailModalProps> = ({
  isMyBox = false,
  isDisplaying,
  calling,
  box,
  realizations_count_of_left_students,
  gearboxStudents,
  realizations,
  pagy_info,
  fetchRealizations,
  fetchMyBoxRealizations,
  onClose,
  toRealizationDetail,
  showStudentDetail,
}) => {
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const [state, setState] = React.useState({
    showSubmitterListForSP: false,
  });

  useIntersection({
    target: loadMoreRef,
    enabled: isDisplaying && !calling && pagy_info.page < pagy_info.pages,
    onIntersect: () =>
      box &&
      (isMyBox
        ? fetchMyBoxRealizations(pagy_info.page + 1)
        : fetchRealizations(box.id, pagy_info.page + 1)),
  });

  return box ? (
    <div className={styles.modal}>
      <div className={styles.backdrop} onClick={onClose} />
      <div className={styles.content}>
        <div className={styles.header}>
          <div>
            <IonIcon
              src={boxIcon}
              slot="start"
              color="light"
              className={styles.icon}
            />
            {isMyBox ? (
              <BoxTitle title="提出されたストック" />
            ) : (
              <BoxTitle
                isArchived={box.is_archived}
                nameStatus={box.name_status}
                title={box.title ?? "ー"}
              />
            )}
          </div>
          <button
            className={styles.close}
            onClick={() => {
              onClose();
              setState(prevState => ({
                ...prevState,
                showSubmitterListForSP: false,
              }));
            }}
          >
            <IonIcon icon={closeIcon} color="light" />
          </button>
        </div>
        <div className={styles.main}>
          <div className={styles.switch}>
            {!isMyBox && (
              <IonButton
                className={styles.switchButton}
                size="small"
                onClick={() =>
                  setState(prevState => ({
                    ...prevState,
                    showSubmitterListForSP: !prevState.showSubmitterListForSP,
                  }))
                }
              >
                <span>
                  {state.showSubmitterListForSP
                    ? "BOXタイムラインを見る"
                    : "提出者一覧を見る"}
                </span>
                <IonIcon
                  color="light"
                  icon={rightArrowIcon}
                  slot="end"
                  className={styles.arrowIcon}
                />
              </IonButton>
            )}
            <div className={styles.switchContentTitle}>
              {!isMyBox && state.showSubmitterListForSP
                ? "提出者一覧"
                : " BOXタイムライン"}
            </div>
          </div>
          <div className={styles.group}>
            {!isMyBox && (
              <div
                className={styles.gearboxStudents}
                style={{
                  display: state.showSubmitterListForSP ? "flex" : "none",
                }}
              >
                <div>提出者一覧</div>
                <p>
                  <span>名前</span>
                  <span>総Stock数 {box.realizations_count}</span>
                </p>
                {gearboxStudents.length ? (
                  <ul>
                    {gearboxStudents.map(student => (
                      <li key={student.id}>
                        <span>{student.full_name}</span>
                        <span>{student.realizations_count ?? "-"}</span>
                      </li>
                    ))}
                    {!!realizations_count_of_left_students && (
                      <li>
                        <span>その他</span>
                        <span>{realizations_count_of_left_students}</span>
                      </li>
                    )}
                  </ul>
                ) : (
                  <div className={styles.emptyData}>空のデータ。</div>
                )}
              </div>
            )}
            <div
              className={styles.realizationList}
              style={{
                display: state.showSubmitterListForSP ? "none" : "block",
              }}
            >
              {realizations.length ? (
                <>
                  {realizations.map(r => (
                    <RealizationItem
                      key={r.id}
                      realization={r}
                      onClick={() => toRealizationDetail(r.id)}
                      onAvatarClick={showStudentDetail}
                    />
                  ))}
                  <div className={styles.loadMore} ref={loadMoreRef}>
                    {calling ? "読み込み中" : ""}
                  </div>
                </>
              ) : (
                !calling && (
                  <div className={styles.emptyData}>実現データなし</div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default BoxDetailModal;
