import React from "react";
import { personCircle } from "ionicons/icons";
import { IonAvatar, IonIcon, IonItem, IonLabel, IonText } from "@ionic/react";
import { NavContext } from "@ionic/react";
import { chevronForwardOutline as rightArrowIcon } from "ionicons/icons";

import { sharedRealizationPath } from "../../store/teacher";
import caution from "../../assets/icons/caution.svg";
import { IReportEntry } from "../../state";
import { simpleDateTimeStr } from "../../libs/Util";

import styles from "./ReportEntry.module.scss";

export interface ReportEntryProps {
  report_entry: IReportEntry;
}

export const ReportEntry = (props: ReportEntryProps) => {
  const { navigate } = React.useContext(NavContext);
  return (
    <>
      <div className={styles.caution}>
        <div className={styles.from}>
          <img src={caution} alt="caution" />
          {props.report_entry.reporters.length > 1
            ? `${props.report_entry.reporters[0]}さん、他${
                props.report_entry.reporters.length - 1
              }名から報告されました。`
            : `${props.report_entry.reporters[0]}さんから報告されました。`}
        </div>
      </div>
      <IonItem
        className={styles.realizationItem}
        onClick={() => {
          navigate(
            `${sharedRealizationPath}${props.report_entry.realization_id}`,
          );
        }}
        lines="full"
      >
        <div className={styles.avatarArea}>
          {props.report_entry.photo?.url ? (
            <IonAvatar slot="start" color="dark" className={styles.avatar}>
              <img alt="プロフィール画像" src={props.report_entry.photo.url} />
            </IonAvatar>
          ) : (
            <IonIcon
              className={styles.avatar}
              icon={personCircle}
              slot="start"
              color="dark"
            />
          )}
        </div>
        <IonLabel>
          <div>
            <IonLabel color="dark">
              <span className={styles.name}>
                {props.report_entry.class_name}{" "}
                {props.report_entry.student_name}の
                {props.report_entry.comment_id ? "コメント" : "ストック"}
              </span>
            </IonLabel>
          </div>
          <div>
            <IonLabel color="dark">{props.report_entry.content}</IonLabel>
          </div>
          <div>
            <IonText color="dark" className={styles.date}>
              {simpleDateTimeStr(props.report_entry.created_at)}
            </IonText>
          </div>
        </IonLabel>
        <IonIcon
          color="dark"
          icon={rightArrowIcon}
          slot="end"
          className={styles.arrowIcon}
        />
      </IonItem>
    </>
  );
};

export default ReportEntry;
