import React from "react";
import { IonButton, IonInput, IonModal, IonTextarea } from "@ionic/react";

import {
  CreateGearboxTemplateParams,
  UpdateGearboxTemplateParams,
} from "../../../state";
import Toast from "../../Toast";

import styles from "./TemplateForm.module.scss";

type Props = {
  calling: boolean;
  isOpen: boolean;
  id: number | null;
  defaultTitle: string;
  defaultDescription: string;
  defaultContent: string;
  close: () => void;
  ok: (
    template: CreateGearboxTemplateParams | UpdateGearboxTemplateParams,
  ) => void;
};

export default function TemplateForm({
  calling,
  isOpen,
  defaultTitle,
  defaultDescription,
  defaultContent,
  id,
  ok,
  close,
}: Props) {
  const [state, setState] = React.useState<{
    showShareForm: boolean;
    showToast: boolean;
    message: string;
    title: string;
    description: string;
    content: string;
  }>({
    showShareForm: false,
    showToast: false,
    message: "",
    title: "",
    description: "",
    content: "",
  });

  const onTitleChange = (title: string) => {
    setState(prevState => ({ ...prevState, title }));
  };

  const onDescriptionChange = (description: string) => {
    setState(prevState => ({ ...prevState, description }));
  };

  const onContentChange = (content: string) => {
    setState(prevState => ({ ...prevState, content }));
  };

  const isFormValid = (
    form: CreateGearboxTemplateParams | UpdateGearboxTemplateParams,
  ) => {
    if (!form.title || !form.description || !form.content) {
      setState(s => ({
        ...s,
        showToast: true,
        message: "テンプレート名、説明、本文を入力してください。",
      }));
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    const params = id
      ? {
          id,
          title: state.title,
          description: state.description,
          content: state.content,
        }
      : {
          title: state.title,
          description: state.description,
          content: state.content,
        };
    if (!isFormValid(params)) {
      return;
    }
    ok(params);
    update({ title: "", description: "", content: "" });
  };

  const update = (args: { [key: string]: any }) => {
    setState(prevState => ({ ...prevState, ...args }));
  };

  React.useEffect(() => {
    if (id) {
      update({
        title: defaultTitle,
        description: defaultDescription,
        content: defaultContent,
      });
    } else {
      update({
        title: "",
        description: "",
        content: "",
      });
    }
  }, [id, defaultTitle, defaultDescription, defaultContent]);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={close} className={styles.wrapper}>
      <Toast
        type="danger"
        showToast={state.showToast}
        onClose={() => setState(s => ({ ...s, showToast: false, message: "" }))}
        message={state.message}
      />
      <div className={styles.form}>
        <div className={styles.label}>
          {id
            ? "オリジナルテンプレートを編集"
            : "オリジナルテンプレートを新規作成"}
        </div>
        <div className={styles.inputArea}>
          <IonInput
            className={styles.input}
            name="title"
            value={state.title}
            placeholder="オリジナルテンプレート名を記入"
            required
            onIonChange={e => onTitleChange(e.detail.value ?? "")}
          />
        </div>
        <div className={styles.inputArea}>
          <IonTextarea
            className={styles.input}
            name="description"
            value={state.description}
            rows={4}
            placeholder="このオリジナルテンプレートの説明を記入　※生徒へは表示されません"
            required
            onIonChange={e => onDescriptionChange(e.detail.value ?? "")}
          />
        </div>
        <p className={styles.note}>
          生徒側の画面には、入力のサポートとして以下が表示されるようになります。
        </p>
        <div className={styles.inputArea}>
          <IonTextarea
            className={styles.input}
            name="content"
            value={state.content}
            rows={8}
            placeholder="例）一日を振り返って感じたことを、ありのままに自分の言葉で書きましょう。(驚いたこと/楽しいこと/ハッとしたこと/心がざわざわしたこと/ドキドキしたこと…)"
            required
            onIonChange={e => onContentChange(e.detail.value ?? "")}
          />
        </div>
        <div className={styles.buttonArea}>
          <IonButton
            className={styles.cancelButton}
            strong
            fill="outline"
            onClick={close}
          >
            キャンセル
          </IonButton>
          <IonButton
            className={styles.postButton}
            disabled={calling}
            strong
            fill="outline"
            onClick={onSubmit}
          >
            保存
          </IonButton>
        </div>
      </div>
    </IonModal>
  );
}
