import { IonContent, IonPage } from "@ionic/react";
import React from "react";

import styles from "../common.module.scss";
import { IFile } from "../../../state";
import {
  clearSuccessMessage,
  Context,
  fetchInitialData,
  homePath,
  updateTeacher,
  UPDATE_TEACHER_SUCCESS_MESSAGE,
  UPDATE_TEACHER_FAILURE_MESSAGE,
  clearErrorMessage,
} from "../../../store/teacher";
import TeacherProfileForm from "../../../templates/TeacherProfileForm";
import { realizationListPath } from "../../../store/student";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import LoginHeader from "../../../organisms/LoginHeader";

const EditProfile = (prop: { history: any }) => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const [isUpdating, setIsUpdating] = React.useState(false);

  const updateFunc = React.useCallback(
    (password: string, photo: IFile | null) => {
      dispatch(peep(updateTeacher(contextState.teacher.id, password, photo)));
      setIsUpdating(true);
    },
    [dispatch, peep, contextState.teacher.id],
  );

  React.useEffect(() => {
    if (contextState.success_message === UPDATE_TEACHER_SUCCESS_MESSAGE) {
      setIsUpdating(false);
      dispatch(clearSuccessMessage());
      dispatch(fetchInitialData());
      prop.history.push(homePath);
    }
    if (contextState.error === UPDATE_TEACHER_FAILURE_MESSAGE) {
      setIsUpdating(false);
      dispatch(clearErrorMessage());
    }
  }, [
    prop.history,
    dispatch,
    contextState.success_message,
    contextState.error,
  ]);

  const cancelFunc = React.useCallback(
    () => prop.history.push(realizationListPath),
    [prop.history],
  );

  return (
    <IonPage>
      <LoginHeader
        title="プロフィール編集"
        unreadNotiCount={contextState.unread_notification_count}
      />
      <IonContent className={styles.wrapper}>
        {contextState.teacher.id ? (
          <div className={styles.container}>
            <TeacherProfileForm
              {...contextState.teacher}
              calling={calling}
              cancel={cancelFunc}
              school_name={contextState.school_name}
              classes={contextState.teacher.classes}
              isUpdating={isUpdating}
              update={updateFunc}
              error={contextState.error}
            />
          </div>
        ) : null}
      </IonContent>
    </IonPage>
  );
};

export default EditProfile;
