import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import React from "react";
import {
  chevronDownOutline as downIcon,
  chevronUpOutline as upIcon,
  checkmark,
} from "ionicons/icons";
import clsx from "clsx";

import folderIcon from "../../assets/icons/folder.svg";
import { TeacherGearboxDirectory } from "../../state";

import styles from "./BoxDirectorySelectForm.module.scss";

type BoxDirectorySelectFormProps = {
  isOpen: boolean;
  label?: string;
  teacherGearboxDirectories: TeacherGearboxDirectory[];
  selectedTeacherGearboxDirectoryID: number;
  directoryName?: string;
  className?: string;
  onClick: () => void;
  update: (args: { [key: string]: any }) => void;
};

const BoxDirectorySelectForm = ({
  isOpen,
  label,
  teacherGearboxDirectories,
  selectedTeacherGearboxDirectoryID,
  directoryName,
  className,
  onClick,
  update,
}: BoxDirectorySelectFormProps) => {
  const displayNames =
    selectedTeacherGearboxDirectoryID === 0
      ? "未選択"
      : teacherGearboxDirectories.find(
          c => c.id === selectedTeacherGearboxDirectoryID,
        )?.name || "未選択";

  return (
    <>
      <IonItem
        className={clsx(
          styles.directoryItemToggle,
          directoryName || "",
          className || "",
        )}
        onClick={onClick}
      >
        {label && (
          <IonIcon
            icon={folderIcon}
            color="light"
            slot="start"
            className={styles.folderIcon}
          />
        )}
        <IonLabel color="light" className={styles.title}>
          {label}
        </IonLabel>
        <div className={styles.selectedNames}>{displayNames}</div>

        {isOpen ? (
          <IonIcon icon={upIcon} slot="end" className={styles.arrowIcon} />
        ) : (
          <IonIcon icon={downIcon} slot="end" className={styles.arrowIcon} />
        )}
      </IonItem>
      <div className={isOpen ? styles.directoryArea : styles.close}>
        {teacherGearboxDirectories.length === 0 ? (
          <div className={styles.noDirectories}>フォルダが見つかりません</div>
        ) : (
          <ul className={styles.directorySelector}>
            {teacherGearboxDirectories.map(teacherGearboxDirectory => (
              <li
                key={teacherGearboxDirectory.id}
                className={styles.directoryItem}
                onClick={() => {
                  update({
                    selectedTeacherGearboxDirectoryID:
                      selectedTeacherGearboxDirectoryID ===
                      teacherGearboxDirectory.id
                        ? 0
                        : teacherGearboxDirectory.id,
                  });
                }}
              >
                <div className={styles.directoryName}>
                  {teacherGearboxDirectory.name}
                  {selectedTeacherGearboxDirectoryID ===
                    teacherGearboxDirectory.id && (
                    <IonIcon
                      slot="end"
                      icon={checkmark}
                      className={styles.checkIcon}
                    />
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default BoxDirectorySelectForm;
