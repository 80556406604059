import React from "react";
import { useHistory } from "react-router-dom";

import { homePath } from "../../../store/teacher";

import styles from "./SendEmailComplete.module.scss";

const SendEmailComplete = () => {
  const history = useHistory();
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>メール送信完了</div>
      <div className={styles.description}>
        ご入力いただいたメールアドレス宛にパスワードリセット用のメールを送信しました。
      </div>
      <div className={styles.description}>
        メール本文のURLからパスワードリセットの再設定を行ってください。
      </div>
      <div className={styles.description}>
        パスワードリセットの有効期限は30分間です。
      </div>
      <div className={styles.backToLogin}>
        <span
          className={styles.text}
          onClick={() => {
            history.push(`${homePath}`);
          }}
        >
          ログイン画面へ戻る
        </span>
      </div>
    </div>
  );
};

export default SendEmailComplete;
