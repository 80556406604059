import React, { useRef } from "react";

import ClassSelector from "../../../molecules/ClassSelector";
import RealizationItem from "../../../molecules/teacher/RealizationItem";
import BoxItem from "../../../atoms/BoxItem";
import {
  IClass,
  IRealizationKlass,
  IPagyInfo,
  IOnClickStudentAvatarParams,
} from "../../../state";
import useIntersection from "../../../hooks/useIntersection";

import styles from "./SharedRealizationList.module.scss";

export interface SharedRealizationListProps {
  isDisplaying: boolean;
  calling: boolean;
  classes: IClass[];
  values: { [key: string]: any };
  realization_klasses: IRealizationKlass[];
  unread_shared_per_class: { [class_id: string]: number };
  pagy_info: IPagyInfo;
  fetchRealizationKlasses: (class_id: number, page?: number) => void;
  clearRealizationKlasses: () => void;
  update: (args: { [key: string]: any }) => void;
  redirectToBox: (box_id: number) => void;
  onAction: (realizationId: number, entryId: number) => void;
  showStudentDetail: (params: IOnClickStudentAvatarParams) => void;
}

const SharedRealizationList = ({
  isDisplaying,
  calling,
  unread_shared_per_class,
  classes,
  values,
  realization_klasses,
  pagy_info,
  update,
  fetchRealizationKlasses,
  clearRealizationKlasses,
  redirectToBox,
  onAction,
  showStudentDetail,
}: SharedRealizationListProps) => {
  const loadMoreRef = useRef<HTMLDivElement>(null);

  useIntersection({
    target: loadMoreRef,
    enabled: isDisplaying && !calling && pagy_info.page < pagy_info.pages,
    onIntersect: () =>
      fetchRealizationKlasses(values.selectedClassID, pagy_info.page + 1),
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.text}>担当クラスを選択</div>
      <ClassSelector
        classes={classes}
        unread_realization_per_class={unread_shared_per_class}
        selectedClassID={values.selectedClassID}
        selectedClassName={values.selectedClassName}
        update={update}
        clear={clearRealizationKlasses}
        isTeacher={true}
      />
      {realization_klasses.map(realization_klass => {
        const {
          realization: { gearbox },
        } = realization_klass;
        return gearbox ? (
          <BoxItem
            key={realization_klass.id}
            title={gearbox.title}
            realizationsCount={gearbox.realizations_count ?? 0}
            isArchived={gearbox.is_archived}
            nameStatus={gearbox.name_status}
            boxCreatorName={gearbox.teacher?.full_name ?? "ー"}
            newestRealization={{
              id: realization_klass.realization.id,
              content: realization_klass.realization.content,
              student: realization_klass.realization.student,
            }}
            redirectTo={() => redirectToBox(gearbox.id)}
            onAvatarClick={showStudentDetail}
          />
        ) : (
          <RealizationItem
            key={realization_klass.id}
            realization={{
              ...realization_klass.realization,
              photo: realization_klass.photo,
              has_comment: realization_klass.has_comment,
              comment_count: realization_klass.comment_count,
            }}
            onClick={() =>
              onAction(realization_klass.realization.id, realization_klass.id)
            }
            onAvatarClick={showStudentDetail}
          />
        );
      })}
      {realization_klasses.length > 0 && (
        <div className={styles.loadMore} ref={loadMoreRef}>
          {calling ? "読み込み中" : ""}
        </div>
      )}
    </div>
  );
};

export default SharedRealizationList;
