import { IonButton, IonInput, IonLabel } from "@ionic/react";
import React from "react";

import Toast from "../../../molecules/Toast";

import styles from "./UpdatePasswordForm.module.scss";

export interface UpdatePasswordFormProps {
  calling: boolean;
  error: string;
  updatePassword: (password: string) => void;
}

const validates: { [key: string]: (value: any) => string } = {};

validates["password"] = val => {
  return val.password.length < 6 || val.password.length > 32
    ? "パスワードは6文字以上32文字以内で入力してください。"
    : "";
};
validates["passwordConfirm"] = val => {
  return val.password !== val.passwordConfirm
    ? "パスワードが一致しません。"
    : "";
};

export const UpdatePasswordForm = (props: UpdatePasswordFormProps) => {
  const [state, setState] = React.useState({
    password: "",
    passwordConfirm: "",
    error: props.error,
    isSubmitted: false,
    errorMessages: {} as { [key: string]: string },
  });

  React.useEffect(() => {
    setState(s => ({ ...s, error: props.error }));
  }, [props.error]);

  const onSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const errorMessages = {} as { [key: string]: string };
      Object.keys(validates).forEach(key => {
        const ret = validates[key](state);
        if (ret) {
          errorMessages[key] = ret;
        }
      });
      if (Object.keys(errorMessages).length > 0) {
        setState(s => ({ ...s, errorMessages }));
        return;
      }
      props.updatePassword(state.password);
    },
    [state, props],
  );

  const onChange = React.useCallback((ev: any) => {
    const e = ev as React.SyntheticEvent<HTMLInputElement>;
    e.preventDefault();
    const val = e.currentTarget.value;
    const key = e.currentTarget.name;
    setState(prevState => {
      const errorMessages = {} as { [key: string]: string };
      const arg = { ...prevState, [key]: val };
      Object.keys(validates).forEach(k => {
        errorMessages[k] = validates[k](arg);
      });
      return {
        ...arg,
        errorMessages,
      };
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <Toast
        type="danger"
        showToast={!!state.error}
        onClose={() => setState({ ...state, error: "" })}
        message={state.error}
      />
      <form className="ion-padding" onSubmit={onSubmit}>
        <div className={styles.inputArea}>
          <IonLabel color="light">新しいパスワード</IonLabel>
          <IonInput
            className={styles.input}
            name="password"
            type="password"
            onIonChange={onChange}
          />
        </div>
        <div className={styles.invalidFeedback}>
          {state.errorMessages["password"]}
        </div>
        <div className={styles.inputArea}>
          <IonLabel color="light">パスワード再入力</IonLabel>
          <IonInput
            className={styles.input}
            name="passwordConfirm"
            type="password"
            onIonChange={onChange}
          />
        </div>
        <div className={styles.invalidFeedback}>
          {state.errorMessages["passwordConfirm"]}
        </div>
        <IonButton
          className={styles.updateButton}
          expand="block"
          onClick={onSubmit}
          disabled={
            Object.keys(state.errorMessages).filter(k => state.errorMessages[k])
              .length > 0 || props.calling
          }
        >
          設定する
        </IonButton>
      </form>
    </div>
  );
};

export default UpdatePasswordForm;
