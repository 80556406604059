import React from "react";
import {
  IonMenu,
  IonContent,
  IonIcon,
  IonLabel,
  IonItem,
  IonItemGroup,
  IonAvatar,
  IonBadge,
  IonText,
} from "@ionic/react";
import { menuController } from "@ionic/core/components";
import {
  exitOutline as exitIcon,
  settingsOutline as settingIcon,
  personCircle as personIcon,
  helpCircleOutline as helpIcon,
  notificationsOutline as bellIcon,
  chevronForwardOutline as rightArrowIcon,
  close as closeIcon,
  homeOutline as homeIcon,
} from "ionicons/icons";
import { useHistory } from "react-router-dom";

import gotoIcon from "../../../assets/icons/goto.svg";
import {
  Context,
  logout,
  editProfilePath,
  mypagePath,
  notificationListPath,
  masqueradeStudent,
  MASQUERADE_STUDENT_SUCCESS_MESSAGE,
  clearSuccessMessage,
  clearErrorMessage,
  reportListPath,
} from "../../../store/teacher";
import ModalConfirm from "../../ModalConfirm";
import Toast from "../../Toast";

import cautionIcon from "./../../../assets/icons/caution.svg";
import styles from "./SideMenu.module.scss";

const SideMenu = () => {
  const { contextState, dispatch } = React.useContext(Context);
  const history = useHistory();
  const [isShowConfirm, setIsShowConfirm] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState({
    showToast: false,
    message: "",
  });

  const logoutFunc = React.useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const gearStudentLoginFunc = React.useCallback(() => {
    dispatch(masqueradeStudent());
  }, [dispatch]);

  React.useEffect(() => {
    if (!!contextState.error) {
      setErrorMessage({
        showToast: true,
        message: contextState.error,
      });
      dispatch(clearErrorMessage());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextState.error]);

  React.useEffect(() => {
    if (contextState.success_message === MASQUERADE_STUDENT_SUCCESS_MESSAGE) {
      dispatch(clearSuccessMessage());
      const studentAppUrl = `${process.env.REACT_APP_STUDENT_APP_URL}?session_id=${contextState.temporary_session_id}`;
      if (!window.open(studentAppUrl, "_blank")) {
        window.location.href = studentAppUrl;
      } else {
        window.open(studentAppUrl, "_blank");
      }
    }
  }, [
    dispatch,
    contextState.success_message,
    contextState.temporary_session_id,
  ]);

  return (
    <IonMenu contentId="sideMenuContainer">
      <Toast
        type="danger"
        showToast={errorMessage.showToast}
        onClose={() => setErrorMessage({ showToast: false, message: "" })}
        message={errorMessage.message}
      />
      <ModalConfirm
        isOpen={isShowConfirm}
        message="ログアウトしますか？"
        buttons={[
          {
            title: "キャンセル",
            type: "None",
            action: () => setIsShowConfirm(false),
          },
          {
            title: "ログアウト",
            type: "Success",
            action: logoutFunc,
          },
        ]}
      />
      <IonContent className={styles.wrapper}>
        <IonItemGroup>
          <IonItem className={styles.sideMenuItemTop} lines="none">
            <IonIcon
              icon={closeIcon}
              slot="end"
              color="light"
              className={styles.icon}
              onClick={() => menuController.close()}
            />
          </IonItem>
          <div>
            {contextState.teacher.photo?.url ? (
              <IonAvatar
                slot="start"
                color="light"
                className={`${styles.avatar} ${styles.photo}`}
              >
                <img
                  alt="プロフィール画像"
                  src={contextState.teacher.photo.url}
                />
              </IonAvatar>
            ) : (
              <IonIcon
                className={styles.avatar}
                icon={personIcon}
                slot="start"
                color="light"
              />
            )}
            <IonText color="light">
              {contextState.teacher.classes.map((cls, idx) => (
                <div
                  key={idx}
                  className={styles.className}
                >{`${cls.full_name}`}</div>
              ))}
            </IonText>
            <IonText color="light">
              <div
                className={styles.name}
              >{`${contextState.teacher.last_name} ${contextState.teacher.first_name}`}</div>
            </IonText>
          </div>
          <IonItem
            className={styles.sideMenuItem}
            lines="none"
            onClick={() => {
              history.push(mypagePath);
              menuController.close();
            }}
          >
            <IonIcon
              icon={homeIcon}
              slot="start"
              color="light"
              className={styles.icon}
            />
            <IonLabel color="light">マイページ</IonLabel>
            <IonIcon
              icon={rightArrowIcon}
              slot="end"
              className={styles.arrowIcon}
            />
          </IonItem>
          <IonItem
            className={styles.sideMenuItem}
            lines="none"
            onClick={() => {
              history.push(notificationListPath);
              menuController.close();
            }}
          >
            <IonIcon
              icon={bellIcon}
              slot="start"
              color="light"
              className={styles.icon}
            />
            <IonLabel color="light" className={styles.menuLabel}>
              お知らせ
            </IonLabel>
            {contextState.unread_notification_count > 0 && (
              <IonBadge color="danger">
                {contextState.unread_notification_count}
              </IonBadge>
            )}
            <IonIcon
              icon={rightArrowIcon}
              slot="end"
              className={styles.arrowIcon}
            />
          </IonItem>
          <IonItem
            className={styles.sideMenuItem}
            lines="none"
            onClick={() => {
              history.push(editProfilePath);
              menuController.close();
            }}
          >
            <IonIcon
              icon={settingIcon}
              slot="start"
              color="light"
              className={styles.icon}
            />

            <IonLabel color="light" className={styles.menuLabel}>
              プロフィール編集
            </IonLabel>
            <IonIcon
              icon={rightArrowIcon}
              slot="end"
              className={styles.arrowIcon}
            />
          </IonItem>
          <IonItem
            className={styles.sideMenuItem}
            lines="none"
            onClick={() => {
              window.open(process.env.REACT_APP_TEACHER_HELP_URL, "_blank");
            }}
          >
            <IonIcon
              icon={helpIcon}
              slot="start"
              color="light"
              className={styles.icon}
            />
            <IonLabel color="light" className={styles.menuLabel}>
              ヘルプ
            </IonLabel>
            <IonIcon
              icon={rightArrowIcon}
              slot="end"
              className={styles.arrowIcon}
            />
          </IonItem>
          <IonItem
            className={styles.sideMenuItem}
            lines="none"
            onClick={() => {
              history.push(reportListPath);
              menuController.close();
            }}
          >
            <IonIcon
              icon={cautionIcon}
              slot="start"
              color="light"
              className={styles.icon}
            />
            <IonLabel color="light">報告された内容</IonLabel>
            <IonIcon
              icon={rightArrowIcon}
              slot="end"
              className={styles.arrowIcon}
            />
          </IonItem>
          {contextState.has_student_account && (
            <IonItem
              className={styles.sideMenuItem}
              lines="none"
              onClick={gearStudentLoginFunc}
            >
              <IonIcon
                icon={gotoIcon}
                slot="start"
                color="light"
                className={styles.icon}
              />
              <IonLabel color="light" className={styles.menuLabel}>
                生徒画面を開く
              </IonLabel>
              <IonIcon
                icon={rightArrowIcon}
                slot="end"
                className={styles.arrowIcon}
              />
            </IonItem>
          )}
          <IonItem
            className={styles.sideMenuItem}
            lines="none"
            onClick={() => setIsShowConfirm(true)}
          >
            <IonIcon
              icon={exitIcon}
              slot="start"
              color="light"
              className={styles.icon}
            />
            <IonLabel color="light" className={styles.menuLabel}>
              ログアウト
            </IonLabel>
          </IonItem>
        </IonItemGroup>
      </IonContent>
    </IonMenu>
  );
};

export default SideMenu;
