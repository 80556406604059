import React, { useEffect } from "react";
import clsx from "clsx";

import useDebounceCallback from "../../hooks/useDebounceCallback";

import styles from "./ToggleSwitch.module.scss";

export type Props = {
  id: string;
  name?: string;
  className?: string;
  defaultCheck: boolean;
  label: string;
  disabled?: boolean;
  onSwitch?: (check: boolean) => void;
};

const ToggleSwitch: React.FC<Props> = ({
  id,
  name,
  className,
  defaultCheck = false,
  label,
  disabled,
  onSwitch,
}) => {
  const [checked, setChecked] = React.useState(defaultCheck);
  const [debounced] = useDebounceCallback(
    checked => onSwitch && onSwitch(checked),
  );

  useEffect(() => {
    setChecked(!disabled);
  }, [disabled]);

  useEffect(() => {
    setChecked(defaultCheck);
  }, [defaultCheck]);

  return (
    <div
      className={clsx(styles.switch, disabled && styles.disabled, className)}
    >
      <input
        type="checkbox"
        id={`checkbox-${id}`}
        name={name}
        checked={checked}
        onChange={e => {
          const value = e.currentTarget.checked;
          setChecked(value);
          debounced(value);
        }}
        disabled={disabled}
      />
      <label
        htmlFor={`checkbox-${id}`}
        data-checked={label}
        data-uncheck={label}
      />
    </div>
  );
};

export default ToggleSwitch;
