import { IonButton, IonInput, IonItem, IonModal } from "@ionic/react";
import React from "react";

import {
  IClass,
  ITeacherGearbox,
  TNameStatus,
  TeacherGearboxDirectory,
  GearboxTemplate,
} from "../../state";
import ClassSelectForm from "../ClassSelectForm";
import BoxTemplateSelector from "../BoxTemplateSelector";
import BoxDirectorySelectForm from "../BoxDirectorySelectForm";
import ToggleSwitch from "../../atoms/ToggleSwitch";
import Toast from "../Toast";
import SecondaryButton from "../../atoms/button/SecondaryButton";

import styles from "./BoxForm.module.scss";

const switchNameStatusKeyValue = (status: TNameStatus | boolean) => {
  const nameStatusKeyValue: { [key: string]: string | boolean } = {
    publish: true,
    unpublish: false,
    true: "publish",
    false: "unpublish",
  };
  return nameStatusKeyValue[`${status}`];
};

export interface BoxFormProps {
  calling: boolean;
  isOpen: boolean;
  classes: IClass[];
  teacherGearboxDirectories: TeacherGearboxDirectory[];
  defaultTitle: string;
  defaultTemplateId: number | null;
  id: number;
  classID: number;
  teacherGearboxDirectoryID: number;
  nameStatus: TNameStatus;
  templates: GearboxTemplate[];
  is_archived?: boolean;
  gearboxes: ITeacherGearbox[];
  createTemplate: () => void;
  close: () => void;
  ok: (box: {
    id: number;
    title: string;
    class_id: number;
    teacher_gearbox_directory_id: number;
    name_status: TNameStatus;
    is_archived: boolean;
    is_hidden: boolean;
    gearbox_template_id: number | null;
  }) => void;
}

const BoxForm: React.FC<BoxFormProps> = ({
  calling,
  isOpen,
  classes,
  teacherGearboxDirectories,
  defaultTitle,
  defaultTemplateId,
  id,
  classID,
  teacherGearboxDirectoryID,
  nameStatus,
  gearboxes,
  templates,
  is_archived = false,
  createTemplate,
  close,
  ok,
}) => {
  const [state, setState] = React.useState<{
    showShareForm: { class: boolean; directory: boolean };
    showToast: boolean;
    message: string;
    title: string;
    selectedClassID: number;
    selectedTeacherGearboxDirectoryID: number;
    nameStatus: TNameStatus;
    is_archived: boolean;
    is_hidden: boolean;
    selected_template_id: number | null;
  }>({
    showShareForm: { class: false, directory: false },
    showToast: false,
    message: "",
    title: "",
    selectedClassID: 0,
    selectedTeacherGearboxDirectoryID: 0,
    nameStatus: "publish",
    is_archived: false,
    is_hidden: false,
    selected_template_id: defaultTemplateId,
  });

  const selected_template = templates?.find(
    (template: GearboxTemplate) => template.id === state.selected_template_id,
  );

  const update = React.useCallback((args: { [key: string]: any }) => {
    setState(prevState => ({ ...prevState, ...args }));
  }, []);

  const handleCreateTemplateButtonCLick = () => {
    createTemplate();
  };

  React.useEffect(() => {
    if (id) {
      update({
        title: defaultTitle,
        selectedClassID: classID,
        selectedTeacherGearboxDirectoryID: teacherGearboxDirectoryID,
        nameStatus,
        is_archived,
        selected_template_id: defaultTemplateId,
      });
    } else {
      update({
        title: "",
        selectedClassID: 0,
        selectedTeacherGearboxDirectoryID: 0,
        nameStatus: "publish",
        is_archived: false,
        selected_template_id: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  React.useEffect(() => {
    if (!id) return;

    // 現在のBOXに対応するディレクトリIDを取得
    const gearBox = gearboxes.find(gearbox => gearbox.id === id);
    const defaultDirectoryID = gearBox?.teacher_gearbox_directory_id ?? 0;

    // ディレクトリIDを更新
    update({ selectedTeacherGearboxDirectoryID: defaultDirectoryID });
  }, [id, gearboxes, update, isOpen]);

  const onSubmit = React.useCallback(() => {
    if (id && !state.title) {
      setState(s => ({
        ...s,
        showToast: true,
        message: "BOX名を入力してください。",
      }));
      return;
    }
    if (!id && !(state.title && state.selectedClassID)) {
      setState(s => ({
        ...s,
        showToast: true,
        message: "BOX名とクラスを入力してください。",
      }));
      return;
    }
    ok({
      id: id || 0,
      title: state.title,
      class_id: state.selectedClassID,
      teacher_gearbox_directory_id: state.selectedTeacherGearboxDirectoryID,
      name_status: state.nameStatus,
      is_archived: state.is_archived,
      is_hidden: state.is_hidden,
      gearbox_template_id: state.selected_template_id,
    });
  }, [
    id,
    state.title,
    state.selectedClassID,
    state.selectedTeacherGearboxDirectoryID,
    state.nameStatus,
    state.is_archived,
    state.is_hidden,
    state.selected_template_id,
    ok,
  ]);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={close} className={styles.wrapper}>
      <Toast
        type="danger"
        showToast={state.showToast}
        onClose={() => setState(s => ({ ...s, showToast: false, message: "" }))}
        message={state.message}
      />
      <div className={styles.form}>
        <div className={styles.label}>
          {id ? "BOX名を編集" : "BOXを新規作成"}
        </div>
        <div className={styles.inputArea}>
          <IonInput
            className={styles.input}
            name="gearboxTitle"
            value={state.title}
            placeholder="BOX名を記入"
            required
            onIonChange={e => update({ title: e.detail.value })}
          />
        </div>
        <div className={styles.template}>
          <BoxTemplateSelector
            defaultTemplateId={state.selected_template_id}
            templates={templates}
            handleChangeTemplate={(id: number | null) =>
              update({ selected_template_id: id })
            }
          />
          <IonItem className={styles.createTemplateButtonArea}>
            <SecondaryButton
              slot="end"
              disabled={calling}
              onClick={handleCreateTemplateButtonCLick}
            >
              オリジナルテンプレートをつくる
            </SecondaryButton>
          </IonItem>
          {selected_template && (
            <div className={styles.explanation}>
              <p className={styles.read}>
                生徒側の画面には、入力のサポートとして以下が表示されるようになります。
              </p>
              <p className={styles.text}>{selected_template.content}</p>
            </div>
          )}
        </div>
        {(!id || !!classID) && (
          <ClassSelectForm
            isOpen={state.showShareForm.class}
            label="クラスを選択"
            classes={classes}
            selected_class_ids={
              state.selectedClassID ? [state.selectedClassID] : []
            }
            className={styles.classSelectForm}
            onClick={() => {
              setState({
                ...state,
                showShareForm: {
                  ...state.showShareForm,
                  class: !state.showShareForm.class,
                },
              });
            }}
            update={update}
          />
        )}
        <BoxDirectorySelectForm
          isOpen={state.showShareForm.directory}
          label="フォルダを選択"
          teacherGearboxDirectories={teacherGearboxDirectories}
          selectedTeacherGearboxDirectoryID={
            state.selectedTeacherGearboxDirectoryID
          }
          className={styles.boxDirectorySelectForm}
          onClick={() => {
            setState({
              ...state,
              showShareForm: {
                ...state.showShareForm,
                directory: !state.showShareForm.directory,
              },
            });
          }}
          update={update}
        />
        <div className={styles.switchWrapper}>
          <ToggleSwitch
            id="name_status"
            label="生徒名を公開"
            defaultCheck={!!switchNameStatusKeyValue(state.nameStatus)}
            onSwitch={check =>
              update({ nameStatus: switchNameStatusKeyValue(check) })
            }
          />
        </div>
        <div className={styles.switchWrapper}>
          <ToggleSwitch
            id="is_archived"
            label="提出を停止"
            defaultCheck={state.is_archived}
            onSwitch={check => update({ is_archived: check })}
          />
        </div>
        <div className={styles.buttonArea}>
          <IonButton
            className={styles.cancelButton}
            strong
            fill="outline"
            onClick={close}
          >
            キャンセル
          </IonButton>
          <IonButton
            className={styles.postButton}
            disabled={calling}
            strong
            fill="outline"
            onClick={onSubmit}
          >
            保存
          </IonButton>
        </div>
      </div>
    </IonModal>
  );
};

export default BoxForm;
