import React from "react";
import { IonIcon } from "@ionic/react";
import clsx from "clsx";
import { format } from "date-fns";

import archiveIcon from "../../assets/icons/archive.svg";
import { IBoxDashboard } from "../../state";
import ModalConfirm from "../../molecules/ModalConfirm";

import styles from "./BoxAnalysisItem.module.scss";

export interface BoxAnalysisItemProps {
  className?: string;
  isMyBox?: boolean;
  isSingle?: boolean;
  boxData: IBoxDashboard;
  onClick?: () => void;
  gearboxHiddenFunc: (id: number) => void;
  teacherId: number;
  calling: boolean;
}

const BoxAnalysisItem: React.FC<BoxAnalysisItemProps> = ({
  className = "",
  isMyBox = false,
  isSingle = false,
  teacherId,
  calling,
  boxData: {
    id,
    title,
    students_count,
    name_status,
    teacher,
    realizations_count = 0,
    favorites_count = 0,
    comments_count = 0,
    checked_count,
    latest_realization_created_at,
    is_archived,
    has_unread_realization = false,
  },
  gearboxHiddenFunc,
  onClick,
}) => {
  const isTeacher = teacher.id === teacherId;
  const [state, setState] = React.useState({ showHiddenConfirm: false });

  const counts = [realizations_count, favorites_count, comments_count];

  // BOX詳細のクリックを防ぎ、モーダルを表示する
  const handleHiddenClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setState({ showHiddenConfirm: true });
  };

  const handleConfirm = () => {
    gearboxHiddenFunc(id);
    setState({ showHiddenConfirm: false });
  };

  const boxLabels = ["ストック", "いいね", "コメント"];

  return (
    <>
      <div className={styles.wrapper}>
        <div className={clsx(styles.boxItem, className)} onClick={onClick}>
          <div className={styles.boxInfo}>
            {!isSingle && (
              <>
                <div className={styles.label}>
                  <div className={styles.boxName}>
                    <p className={styles.title}>
                      {title}
                      {has_unread_realization && (
                        <span className={styles.badge} />
                      )}
                    </p>
                  </div>
                  {!isSingle && latest_realization_created_at && (
                    <span className={styles.date}>
                      {"" + format(latest_realization_created_at, "yyyy.MM.dd")}
                      <span className={styles.slash}>/</span>
                      {name_status === "unpublish" && "匿名共有"}
                      {`(${students_count ?? "ー"}名)`}
                    </span>
                  )}
                </div>
                {checked_count !== null && (
                  <div className={styles.checkedCount}>
                    <span className={styles.num}>{checked_count}</span>
                  </div>
                )}
              </>
            )}
          </div>
          <div className={styles.count}>
            <div className={clsx(styles.allRealizationsCount)}>
              {counts.map((count, index) => (
                <div key={index} className={styles.actionCount}>
                  <span className={styles.label}>{boxLabels[index]}</span>
                  <span className={styles.value}>{count}</span>
                </div>
              ))}
            </div>
            <div className={styles.actionCountGroup}>
              {!isMyBox && (
                <>
                  <div
                    className={clsx(
                      styles.action,
                      styles.status,
                      is_archived && styles.archived,
                    )}
                  >
                    <span className={styles.label}>ステータス</span>
                    <span
                      className={clsx(
                        styles.value,
                        is_archived && styles.closed,
                      )}
                    >
                      {is_archived ? "募集終了" : "募集中"}
                    </span>
                  </div>
                  <p className={clsx(styles.action, styles.teacherName)}>
                    <span className={styles.label}>作成者</span>
                    <span className={styles.value}>{teacher.full_name}</span>
                  </p>
                </>
              )}
              {isTeacher && (
                <IonIcon
                  className={styles.archiveIcon}
                  icon={archiveIcon}
                  onClick={handleHiddenClick}
                />
              )}
            </div>
          </div>
        </div>
        <ModalConfirm
          loading={calling}
          isOpen={state.showHiddenConfirm}
          message={
            <>
              このBOXを非表示にしますか？
              <br />
              非表示にしたBOXは、『BOXをつくる』ページの『編集』から再表示できます。
            </>
          }
          buttons={[
            {
              title: "キャンセル",
              type: "None",
              action: () => setState({ showHiddenConfirm: false }),
            },
            {
              title: "はい",
              type: "Success",
              action: handleConfirm,
            },
          ]}
        />
      </div>
    </>
  );
};

export default BoxAnalysisItem;
