import { IonPage, IonContent } from "@ionic/react";
import * as React from "react";

import SendEmailComplete from "../../../templates/teacher/SendEmailComplete";
import styles from "../common.module.scss";

const SendEmailCompletePage = () => {
  return (
    <IonPage>
      <IonContent className={styles.subWrapper}>
        <div className={styles.container}>
          <SendEmailComplete />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SendEmailCompletePage;
