import * as React from "react";
import {
  IonButton,
  IonContent,
  IonPage,
  useIonViewWillLeave,
  useIonViewDidLeave,
} from "@ionic/react";

import MenuHeader from "../../../organisms/teacher/Header/MenuHeader";
import ManageBoxesMenu from "../../../templates/teacher/ManageBoxesMenu";
import {
  Context,
  fetchGearboxes,
  createGearbox,
  updateGearbox,
  deleteGearbox,
  fetchGearboxTemplates,
  fetchMyGearboxTemplates,
  createGearboxTemplate,
  updateGearboxTemplate,
  reorderGearboxTemplates,
  deleteGearboxTemplate,
  clearSuccessMessage,
  CREATE_GEARBOX_SUCCESS_MESSAGE,
  UPDATE_GEARBOX_SUCCESS_MESSAGE,
  DELETE_GEARBOX_SUCCESS_MESSAGE,
  CREATE_GEARBOX_TEMPLATE_SUCCESS_MESSAGE,
  UPDATE_GEARBOX_TEMPLATE_SUCCESS_MESSAGE,
  DELETE_GEARBOX_TEMPLATE_SUCCESS_MESSAGE,
  clearErrorMessage,
  reorderBoxes,
  fetchTeacherGearboxDirectories,
  gearboxHidden,
} from "../../../store/teacher";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import styles from "../common.module.scss";
import {
  CreateGearboxParams,
  ITeacherGearbox,
  UpdateGearboxParams,
  CreateGearboxTemplateParams,
  UpdateGearboxTemplateParams,
  GearboxTemplate,
} from "../../../state";
import Toast from "../../../molecules/Toast";
import ManageTemplatesMenu from "../../../templates/teacher/ManageTemplatesMenu";

export type Menu = "box" | "template";

const ManageBoxesPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const [state, setState] = React.useState({
    editable: false,
    showToast: false,
    message: "",
    toastType: "" as "" | "success" | "danger",
    boxes: [],
    templates: [],
  });
  const [menu, setMenu] = React.useState<Menu>("box");
  const title =
    menu === "box" ? "BOXをつくる" : "オリジナルテンプレートをつくる";

  const handleChangeMenu = (menu: Menu) => {
    setMenu(menu);
    setState(prev => ({ ...prev, editable: false }));
  };

  useIonViewDidLeave(() => {
    setMenu("box");
  });

  React.useEffect(() => {
    dispatch(fetchGearboxes());
    dispatch(fetchTeacherGearboxDirectories());
    dispatch(fetchMyGearboxTemplates());
    dispatch(fetchGearboxTemplates());
  }, [dispatch]);

  React.useEffect(() => {
    if (
      contextState.success_message === CREATE_GEARBOX_SUCCESS_MESSAGE ||
      contextState.success_message === UPDATE_GEARBOX_SUCCESS_MESSAGE ||
      contextState.success_message === DELETE_GEARBOX_SUCCESS_MESSAGE ||
      contextState.success_message ===
        CREATE_GEARBOX_TEMPLATE_SUCCESS_MESSAGE ||
      contextState.success_message ===
        UPDATE_GEARBOX_TEMPLATE_SUCCESS_MESSAGE ||
      contextState.success_message === DELETE_GEARBOX_TEMPLATE_SUCCESS_MESSAGE
    ) {
      setState(s => ({
        ...s,
        showToast: true,
        message: contextState.success_message,
        toastType: "success",
      }));
      dispatch(clearSuccessMessage());
      dispatch(fetchGearboxes());
      dispatch(fetchGearboxTemplates());
      dispatch(fetchMyGearboxTemplates());
    }
  }, [dispatch, contextState.success_message]);

  React.useEffect(() => {
    if (contextState.error) {
      setState(s => ({
        ...s,
        showToast: true,
        message: contextState.error,
        toastType: "danger",
      }));
    }
    return () => dispatch(clearErrorMessage());
  }, [dispatch, contextState.error]);

  const update = React.useCallback(
    (args: { [key: string]: any }) =>
      setState(v => ({
        ...v,
        ...args,
      })),
    [],
  );

  const createGearboxFunc = React.useCallback(
    (args: CreateGearboxParams) => {
      dispatch(peep(createGearbox(args)));
    },
    [dispatch, peep],
  );

  const updateGearboxFunc = React.useCallback(
    (args: UpdateGearboxParams) => {
      dispatch(peep(updateGearbox(args)));
    },
    [dispatch, peep],
  );

  const reorderBoxesFunc = React.useCallback(
    (boxes: ITeacherGearbox[]) => {
      dispatch(peep(reorderBoxes(boxes.map(({ id }) => id))));
    },
    [dispatch, peep],
  );

  const deleteGearboxFunc = React.useCallback(
    (id: number) => {
      dispatch(peep(deleteGearbox(id)));
    },
    [dispatch, peep],
  );

  const gearboxHiddenFunc = React.useCallback(
    (id: number) => {
      dispatch(peep(gearboxHidden(id)));
    },
    [dispatch, peep],
  );

  const createTemplateFunc = (args: CreateGearboxTemplateParams) => {
    dispatch(peep(createGearboxTemplate(args)));
  };

  const updateTemplateFunc = (args: UpdateGearboxTemplateParams) => {
    dispatch(peep(updateGearboxTemplate(args)));
  };

  const reorderTemplatesFunc = (templates: GearboxTemplate[]) => {
    dispatch(peep(reorderGearboxTemplates(templates.map(({ id }) => id))));
  };

  const deleteTemplateFunc = (id: number) => {
    dispatch(peep(deleteGearboxTemplate(id)));
  };

  useIonViewWillLeave(() => {
    setState(s => ({ ...s, editable: false, boxes: [] }));
    dispatch(fetchGearboxes());
  });

  return (
    <IonPage>
      <MenuHeader
        title={title}
        rightButton={
          <IonButton
            className={styles.editButton}
            onClick={() => {
              setState(s => ({ ...s, editable: !s.editable }));
              if (menu === "box" && state.boxes.length) {
                reorderBoxesFunc(state.boxes);
                return;
              }
              if (menu === "template" && state.templates.length) {
                reorderTemplatesFunc(state.templates);
              }
            }}
          >
            {state.editable ? "完了" : "編集"}
          </IonButton>
        }
      />
      <IonContent className={styles.wrapper}>
        <div className={styles.container}>
          {menu === "box" && (
            <ManageBoxesMenu
              calling={calling}
              editable={state.editable}
              teacher={contextState.teacher}
              teacherGearboxDirectories={contextState.teacherGearboxDirectories}
              gearboxes={contextState.gearboxes}
              gearboxHidden={gearboxHiddenFunc}
              templates={[
                ...contextState.my_gearbox_templates,
                ...contextState.shared_gearbox_templates,
              ]}
              createTemplate={() => {
                handleChangeMenu("template");
              }}
              createGearbox={createGearboxFunc}
              deleteGearbox={deleteGearboxFunc}
              updateGearbox={updateGearboxFunc}
              update={update}
            />
          )}
          {menu === "template" && (
            <ManageTemplatesMenu
              calling={calling}
              editable={state.editable}
              teacher={contextState.teacher}
              templates={contextState.my_gearbox_templates}
              createTemplate={createTemplateFunc}
              updateTemplate={updateTemplateFunc}
              deleteTemplate={deleteTemplateFunc}
              changeMenu={menu => handleChangeMenu(menu)}
              update={update}
            />
          )}
        </div>
      </IonContent>
      <Toast
        type={state.toastType}
        showToast={state.showToast}
        onClose={() =>
          setState(s => ({
            ...s,
            showToast: false,
            message: "",
            toastType: "",
          }))
        }
        message={state.message}
      />
    </IonPage>
  );
};

export default ManageBoxesPage;
